/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-08-24 17:31:19
 * @LastEditors: songone
 * @LastEditTime: 2022-08-24 17:32:33
 * @FilePath: \qts-ui\src\hooks\useStores.ts
 */
import { MyContext } from '@/store';
import { useContext } from 'react';

const useStores = () => useContext(MyContext);

export default useStores;
