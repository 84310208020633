/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-23 10:32:53
 * @LastEditors: songone
 * @LastEditTime: 2022-12-26 18:02:37
 * @FilePath: \tide-user-center-web\src\api\menus.ts
 */
import request from '@/utils/request';

// 获取菜单树
export const getMenusTree = () => request({
  url: '/base/user-identity-management/api/v1/menu/tree',
  method: 'get',
});
// 更新菜单顺序
export const updateMenuOrder = (draggedId: string, data: any) => request({
  url: `/base/user-identity-management/api/v1/menu/${draggedId}/order`,
  method: 'put',
  data
});
// 获取菜单详情
export const getMenuDetail = (id: string) => request({
  url: `/base/user-identity-management/api/v1/menu/${id}`,
  method: 'get',
});
// 创建
export const createMenu = (data: any) => request({
  url: '/base/user-identity-management/api/v1/menu/save',
  method: 'post',
  data
});
// 创建
export const deleteMenu = (menuId: string) => request({
  url: `/base/user-identity-management/api/v1/menu/${menuId}`,
  method: 'delete',
});
// 更新菜单信息
export const updateMenuInfo = (menuId: string, data: any) => request({
  url: `/base/user-identity-management/api/v1/menu/${menuId}`,
  method: 'put',
  data
});

// 获取菜单详情
export const getPolicyById = (id: string) => request({
  url: `/base/user-identity-management/api/v1/policies/${id}`,
  method: 'get',
});
