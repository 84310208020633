import axios from 'axios';
import { message } from 'antd';
// import { useHistory } from 'react-router-dom';
import { PushLoginPage, setCurrentUserInfo, getAppInfo } from './index';
import { getToken, getRefreshToken } from './auth';
// import qs from 'qs'

// 是否正在刷新的标记
let isRefreshing = false;
// 重试队列，每一项将是一个待执行的函数形式
let requests: any[] = [];

const method = ['post', 'put', 'patch'];

const refreshToken = () => {
  const token = getRefreshToken();
  return axios({
    url: '/base/user-identity-management/auth/token-refresh',
    method: 'post',
    timeout: 100000,
    data: {
      refresh_token: token
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
// 创建axios实例
const service:any = axios.create({
  timeout: 60 * 1000 // 请求超时时间
});
// 添加请求拦截器)
service.interceptors.request.use(
  (config:any) => {
    config.headers['Content-Type'] = 'application/json';
    // 给请求加上请求头
    const appInfo = getAppInfo();
    const token = getToken();
    if (!config.noToken && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (appInfo.appId) {
      config.headers['RC-APP-TYPE-ID'] = appInfo.appId;
    }
    if (appInfo.tenantId) {
      config.headers['RC-TENANT-ID'] = appInfo.tenantId;
    }
    if (appInfo.lang) {
      config.params = config.params ? { ...config.params, locale: appInfo.lang } : { locale: appInfo.lang };
    }
    // if (config.method === 'get') {
    //   const query = assembleQuery(config.parmas);
    //   config.url = `${config.url}${query ? `?${query}` : ''}`;
    // }
    // 在发送请求之前做某件事，譬如这里可以把参数序列化
    if (method.includes(config.method) && config.body) {
      config.data = config.body;
    }
    return config;
  },
  (error:any) => {
    message.error('错误的传参');
    return Promise.reject(error);
  }
);
// respone拦截器，返回状态判断(添加响应拦截器)
service.interceptors.response.use(
  (response:any) => {
    const data = response.data?.payload ? response.data?.payload : response.data;
    return data;
  },
  (error:any) => {
    const { config } = error.response;
    const data = error.response?.data;
    if (error.response.status === 401 || (data && ['TIDE-1012', 'TIDE-1007', 'TIDESOL-91008'].includes(data.code))) {
      if (!isRefreshing) {
        isRefreshing = true;
        return refreshToken().then((res) => {
          const userData = res.data.payload;
          setCurrentUserInfo(userData);

          config.baseURL = '';
          // 已经刷新了token，将所有队列中的请求进行重试
          requests.forEach(cb => cb());
          requests = [];
          return service(config);
        }).catch(() => {
          PushLoginPage();
        }).finally(() => {
          isRefreshing = false;
        });
      }
      // 正在刷新token，将返回一个未执行resolve的promise
      return new Promise((resolve) => {
        // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
        requests.push(() => {
          resolve(service(config));
        });
      });
    }
    if (data) {
      message.error({ content: data.message, key: 'errorKey' });
      return Promise.reject(error);
    }
    if (error.response.status === 504 || error.response.status === 404) {
      message.error('服务器失去响应！');
    } else if (error.response.status === 401) {
      message.error('登录信息已经失效！');
    } else if (error.response.status === 500) {
      message.error('服务器不可用！');
    }
    return Promise.reject(error);
  }
);
export default service;
