/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-21 15:39:58
 * @LastEditors: songone
 * @LastEditTime: 2022-12-21 16:12:57
 * @FilePath: \tide-user-center-web\src\pages\orgs-management\components\UserTable\index.tsx
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { useState, useEffect } from 'react';
import { Row, Col, Table, message, Skeleton, PaginationProps } from 'antd';
import SearchInput from '@/components/common/SearchInput';
import { getOrgUserList } from '@/api/org';

type PropsType = {
  orgId: string | undefined;
}
const UserTable = (props: PropsType) => {
  const { orgId } = props;
  const [searchForm, setSearchForm] = useState({
    orgId,
    sort: 'createdTime',
    limit: 10,
    page: 1,
    userName: ''
  });
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState({
    results: [],
    total: 0
  });

  const getUserList = async () => {
    setLoading(true);

    try {
      const result = await getOrgUserList({
        ...searchForm,
        orgId
      });

      if (result && result.results) {
        setListData(result);
      } else {
        setListData({
          results: [],
          total: 0
        });
        message.error(result?.message || '获取用户列表失败');
      }
    } catch (error) {
      setListData({
        results: [],
        total: 0
      });
      message.error(t('当前网络不稳定，请稍后重试'));
    }
    setLoading(false);
  };
  const handleSearchUser = (value: string) => {
    setSearchForm({
      ...searchForm,
      userName: value
    });
  };
  useEffect(() => {
    if (orgId) {
      getUserList();
    }
  }, [orgId, searchForm]);
  const columns = [
    {
      title: t('用户名'),
      dataIndex: 'userName',
      key: 'userName',
      render: (text: string) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {text && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('姓名'),
      dataIndex: 'displayName',
      key: 'displayName',
      render: (text: string, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record.userId && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('邮箱'),
      dataIndex: 'email',
      key: 'email',
      render: (text: string, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record.userId && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('手机'),
      dataIndex: 'mobile',
      key: 'mobile',
      render: (text: string, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record.userId && <span>{text}</span>}
        </Skeleton>
      ),
    },
  ];
  const pagenation: PaginationProps = {
    total: listData.total,
    current: searchForm.page,
    pageSize: searchForm.limit,
    onChange: (page: number, pageSize: number) => {
      setSearchForm({
        ...searchForm,
        page: page || 1,
        limit: pageSize || 20
      });
    },
  };
  return (
    <>
      <Row
        style={{
          marginBottom: 16,
        }}
      >
        <Col>
          <SearchInput placeholder={t('请输入用户名')} onSearch={handleSearchUser} />
        </Col>
      </Row>
      <Table columns={columns} dataSource={listData.results} pagination={pagenation} rowKey={row => row.userId} />
    </>
  );
};


export default UserTable;
