import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useEffect, useState } from 'react';
import { Tag, Dropdown, Menu, Checkbox, List, Skeleton, Input, message } from 'antd';
import RCIcon from '@/common/Icon';
import { getRoleData, getRoleByUser, bindUserRole, relieveUserRole } from '@/api/permission';

import './index.less';

type PropsType = {
  userId: string;
  orgId: string | undefined;
}
const RoleSelectWithSearch = (props: PropsType) => {
  const { userId, orgId } = props;
  
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [listData, setListData] = useState({
    results: [],
    total: 0
  });
  const [searchForm, setSearchForm] = useState({
    page: 1,
    limit: 999,
    scope: 'INTERNAL',
    name: ''
  });
  const [selectRoleList, setSelectRoleList] = useState<any[]>([]);

  const checkList = selectRoleList.map(item => item.id);
  const getUserRoles = async () => {
    const params = {
      page: 1,
      limit: 99,
      userId,
      orgId
    };
    try {
      const result = await getRoleByUser(params);
      if (result && !result.code && !result.error) {
        setSelectRoleList(result.results);
      } else {
        setSelectRoleList([]);
        message.error(result.message || t('查询当前用户角色信息失败'));
      }
    } catch (error) {
      setSelectRoleList([]);
      message.error(t('当前网络不稳定，请稍后重试'));
    }
  };
  const getRoleList = async () => {
    setLoading(true);

    try {
      const result = await getRoleData(searchForm);

      if (result && !result.code && !result.error) {
        setListData(result);
      } else {
        setListData({
          results: [],
          total: 0
        });
      }
    } catch (error) {
      message.error(t('当前网络不稳定，请稍后重试'));
    }
    setLoading(false);
  };
  const handleSearch = (value: string) => {
    setSearchForm({
      ...searchForm,
      name: value
    });
  };

  const handleChecked = async (e: any, item: any) => {
    const params = {
      userId,
      roleIds: [item.id],
      orgId
    };
    let result: any = {};
    if (e.target.checked) {
      try {
        result = await bindUserRole(params);

        if (!result.code && !result.error) {
          message.success(t('更新用户角色成功'));
          setSelectRoleList([...selectRoleList, item]);
        } else {
          message.error(result.message || t('更新用户角色失败'));
        }
      } catch (err) {
        message.error(t('更新用户角色失败'));
      }
    } else {
      try {
        result = await relieveUserRole(params);

        if (!result.code && !result.error) {
          message.success(t('更新用户角色成功'));
          const resultChecks = selectRoleList.filter(role => role.id !== item.id);
          setSelectRoleList([...resultChecks]);
        } else {
          message.error(result.message || t('更新用户角色失败'));
        }
      } catch (err) {
        message.error(t('更新用户角色失败'));
      }
    }
  };

  // 用户自主切换显示下拉
  const handleChangeVisible = (show: boolean) => {
    setVisible(show);
  };

  useEffect(() => {
    getRoleList();
  }, [searchForm]);
  useEffect(() => {
    getUserRoles();
  }, [props]);

  const menu = (
    <Menu>
      <div className="role_list">
        <Input.Search
          placeholder={t('请输入角色名称')}
          onSearch={handleSearch}
          loading={loading}
          autoComplete="off"
        />
      </div>
      <div className="role_list_select">
        <List
          size="small"
          header={<div>{t('全部角色')}</div>}
          dataSource={listData.results}
          renderItem={(item: any) => (
            <List.Item>
              <Skeleton
                loading={loading}
                active
                paragraph={{
                  rows: 1,
                }}
                title={false}
              >
                <Checkbox
                  key={item.id}
                  checked={checkList.includes(item.id)}
                  onChange={(e) => {
                    handleChecked(e, item);
                  }}
                  disabled={loading}
                >
                  {item.name}
                </Checkbox>
              </Skeleton>
            </List.Item>
          )}
        />
      </div>
    </Menu>
  );
  return (
    <div className="role_select" id="tree">
      <span>
        <span>
          {selectRoleList.map(
        (
          item: any // 自定义角色
        ) => (
          <Tag
            key={item.id}
            closable
            onClose={() => {
              // props.handleCloseTags(item)
            }}
          >
            {item.name}
          </Tag>
        )
      )}
        </span>
      </span>
      {checkList.length < 5 && (
      <Dropdown
        overlay={menu}
        trigger={['click']}
        visible={visible}
        placement="topLeft"
        onVisibleChange={handleChangeVisible}
        overlayClassName="role-overlay-box"
      >
        <a onClick={e => e.preventDefault()}>
          <RCIcon type="icon-addoutline" className="add_auth_role" />
        </a>
      </Dropdown>
  )}
    </div>
  );
};

export default RoleSelectWithSearch;

