/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-22 18:03:25
 * @LastEditors: songone
 * @LastEditTime: 2022-12-22 18:11:34
 * @FilePath: \tide-user-center-web\src\utils\constants.ts
 */

// 模拟接口数据实现国际化
export const API_I18N_JSON: any = {
  en: {
    '工业区块链（BaaS）': 'Industrial Blockchain(BaaS)',
    概览: 'Overview',
    联盟管理: 'Consortium Management',
    联盟列表: 'Consortium List',
    联盟邀请: 'Consortium Invitation',
    回收站: 'Recycle Bin',
    节点管理: 'Node Management',
    区块链浏览器: 'Blockchain Browser',
    平台应用: 'Platform Application',
    合约管理: 'Contract Management',
    应用列表: 'App List',
    应用市场: 'App Market',
    应用模版管理: 'App Template Management',
    系统日志: 'System Log',
    权限管理: 'Authority Management',
    用户管理: 'User Management',
    角色管理: 'Role Management',
    策略管理: 'Policy Management',
    机构管理: 'Organization Management',
    资源管理: 'Resource Management',
    菜单管理: 'Menu Management',
    节点配置: 'Node Configuration',
    节点列表: 'Node List',
    成员管理: 'Member Management',
    消息管理: 'Message Management',
    消息模版: 'Message Template',
    消息列表: 'Message List',
    用量管理: 'Consumption Management',
    认证源管理: 'Authentication Source Management',
    // 秘钥菜单
    返回: 'Back',
    访问密钥: 'Secret Key',
    AccessKey: 'AccessKey',
    API密钥: 'API Key',
    // 个人中心
    个人中心: 'Personal Center',
    个人信息: 'Personal Information',
    修改密码: 'Modify Password',
    我的机构: 'My Organization',
    // 消息中心
    消息中心: 'Message Center',
    消息通知: 'Message Notify'
  },
  'zh-CN': {
    '工业区块链（BaaS）': '工业区块链（BaaS）',
    概览: '概览',
    联盟管理: '联盟管理',
    联盟列表: '联盟列表',
    联盟邀请: '联盟邀请',
    回收站: '回收站',
    节点管理: '节点管理',
    区块链浏览器: '区块链浏览器',
    平台应用: '平台应用',
    合约管理: '合约管理',
    应用列表: '应用列表',
    应用市场: '应用市场',
    应用模版管理: '应用模版管理',
    系统日志: '系统日志',
    权限管理: '权限管理',
    用户管理: '用户管理',
    角色管理: '角色管理',
    策略管理: '策略管理',
    机构管理: '机构管理',
    资源管理: '资源管理',
    菜单管理: '菜单管理',
    节点配置: '节点配置',
    节点列表: '节点列表',
    成员管理: '成员管理',
    消息管理: '消息管理',
    消息模版: '消息模版',
    消息列表: '消息列表',
    用量管理: '用量管理',
    认证源管理: '认证源管理',
    // 秘钥菜单
    返回: '返回',
    访问密钥: '访问密钥',
    AccessKey: 'AccessKey',
    API密钥: 'API密钥',
    个人中心: '个人中心',
    个人信息: '个人信息',
    修改密码: '修改密码',
    我的机构: '我的机构',
    // 消息中心
    消息中心: '消息中心',
    消息通知: '消息通知'
  }
};
