/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-23 14:52:48
 * @LastEditors: songone
 * @LastEditTime: 2023-01-04 16:23:41
 * @FilePath: \tide-user-center-web\src\pages\menus-management\details.tsx
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { message, Button } from 'antd';
import BreadCrumb from '@/components/BreadCrumb';
import RCDescriptions from '@/components/RCDescriptions';
import { getMenuDetail, getPolicyById } from '@/api/menus';

import EditForm from './components/EditForm';

const MenusDetailsPage = () => {
  const { menuId } = useParams();
  const [details, setDetails] = useState<any>({});
  const [editVisible, setEditVisible] = useState(false);

  const getPolicyByIds = async (ids: string[]) => {
    const items = [];
    for (let i = 0; i < ids.length; i += 1) {
      const id = ids[i];
      const result = await getPolicyById(id);
      items.push(result);
    }

    return items;
  };

  const getDetails = async () => {
    if (!menuId) {
      return;
    }
    try {
      const result = await getMenuDetail(menuId);

      if (result && result.id && !result.error) {
        const arr = await getPolicyByIds(result?.policies || []);
        const resultDetails = {
          sort: result?.sort,
          name: result?.name,
          code: result?.code,
          type: result?.type === 'MENU' ? t('菜单') : t('按钮'),
          icon: result?.icon,
          path: result?.path,
          parentId: result?.parentId,
          describe: result?.describe,
          policies: arr.map(k => k.name).toString(),
          policyList: arr
        };
        setDetails(resultDetails);
      }
    } catch (error) {
      message.error(t('当前网络不稳定，请稍后重试'));
    }
  };
  const handleEdit = () => {
    setEditVisible(true);
  };
  const handleSuccess = () => {
    getDetails();
    setEditVisible(false);
  };
  useEffect(() => {
    getDetails();
  }, []);
  return (
    <div className="page-body">
      <BreadCrumb
        name={t('详情：') + (details?.name || '')}
        actionRender={
          <>
            <Button onClick={handleEdit}>修改</Button>
          </>
        }
        showBack
      />
      <div className="page-container" >
        <div id="MenuDetails" className="menu_details">
          <RCDescriptions
            title={t('基本信息')}
            column={1}
            data={details}
            items={[
              {
                name: t('排序'),
                key: 'sort',
              },
              {
                name: t('菜单名称'),
                key: 'name',
              },
              {
                name: t('资源编码'),
                key: 'code',
              },
              {
                name: t('类型'),
                key: 'type',
              },
              {
                name: t('图标'),
                key: 'icon',
              },
              {
                name: t('路由'),
                key: 'path',
              },
              {
                name: t('策略'),
                key: 'policies',
              },
            ]}
          />
          {details?.describe && (
          <div className="desc">
            <div className="desc_title">{t('菜单描述')}</div>
            <div className="desc_content">{details?.describe}</div>
          </div>
          )}
          <EditForm
            visible={editVisible}
            onCancel={() => {
              setEditVisible(false);
            }}
            onClose={() => {
              setEditVisible(false);
            }}
            onSuccess={handleSuccess}
            detail={details}
            menuId={menuId || ''}
          />
          {/* <Drawer
            className="edit_drawer"
            width={478}
            title={
              <span className="title">{t('修改基本信息')}</span>
            }
            placement="right"
            closable
            maskClosable={false}
            onClose={this.close}
            visible={this.visible}
            getContainer={false}
            style={{
              marginTop: 100,
            }}
          >
            <div className={drawerStyles.drawer_body_test}>
              <Form
                onSubmit={this.handleBaseSubmit}
                className={drawerStyles.from_set}
              >
                <Form.Item
                  label={<div className={drawerStyles.label}>{t('菜单名称')}</div>}
                >
                  {getFieldDecorator('name', {
                    initialValue: details.name || null,
                    rules: [
                      {
                        required: true,
                        max: 60,
                        min: 4,
                        validator: async (rule, value, callback) => {
                          if (!value || !value.trim()) {
                            const messageBody = (
                              <div className={drawerStyles.err_tips}>
                                <RCIcon
                                  type="icon-Mainwarningsfilled"
                                  style={{ marginRight: 8 }}
                                />
                                {t('请输入菜单名称')}
                              </div>
                            );
                            callback(messageBody);
                          } else if (rule.max < getStrLen(value)) {
                            const messageBody = (
                              <div className={drawerStyles.err_tips}>
                                <RCIcon
                                  type="icon-Mainwarningsfilled"
                                  style={{ marginRight: 8 }}
                                />
                                {t('菜单名称不能超过{slot}个字符', { slot: rule.max })}
                              </div>
                            );
                            callback(messageBody);
                          } else if (rule.min > getStrLen(value)) {
                            const messageBody = (
                              <div className={drawerStyles.err_tips}>
                                <RCIcon
                                  type="icon-Mainwarningsfilled"
                                  style={{ marginRight: 8 }}
                                />
                                {t('菜单名称不能少于{slot}个字符', { slot: rule.min })}
                              </div>
                            );
                            callback(messageBody);
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                  })(
                    <Input
                      placeholder={this.placeholder['请输入菜单名称']}
                      autoComplete="off"
                      onChange={this.changeName}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label={<div className={drawerStyles.label}>{t('资源编码')}</div>}
                >
                  {getFieldDecorator('code', {
                    initialValue: details.code || null,
                    rules: [
                      {
                        required: true,
                        validator: async (rule, value, callback) => {
                          if (!value || !value.trim()) {
                            const messageBody = (
                              <div className={drawerStyles.err_tips}>
                                <RCIcon
                                  type="icon-Mainwarningsfilled"
                                  style={{ marginRight: 8 }}
                                />
                                {t('请输入资源编码')}
                              </div>
                            );
                            callback(messageBody);
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                  })(
                    <Input
                      disabled
                      placeholder={this.placeholder['请输入资源编码']}
                      autoComplete="off"
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label={<div className={drawerStyles.label}>{t('类型')}</div>}
                >
                  {getFieldDecorator('type', {
                    initialValue: details.type || null,
                    rules: [
                      {
                        required: true,
                        validator: async (rule, value, callback) => {
                          if (!value || !value.trim()) {
                            const messageBody = (
                              <div className={drawerStyles.err_tips}>
                                <RCIcon
                                  type="icon-Mainwarningsfilled"
                                  style={{
                                    marginRight: 8,
                                  }}
                                />
                                {t('请输入类型')}
                              </div>
                            );
                            callback(messageBody);
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                  })(
                    <Input
                      disabled
                      placeholder={this.placeholder['请输入类型']}
                      autoComplete="off"
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label={
                    <div className={drawerStyles.label}>
                      {t('图标')}
                      <span
                        className={drawerStyles.tag_tips}
                        style={{
                          marginLeft: 8,
                        }}
                      >
                        {t('选填')}
                      </span>
                    </div>
                  }
                >
                  {getFieldDecorator('icon', {
                    initialValue: details.icon || null,
                  })(
                    <Input placeholder={this.placeholder['请输入图标']} autoComplete="off" />
                  )}
                </Form.Item>
                <Form.Item
                  label={
                    <div className={drawerStyles.label}>
                      {t('路由')}
                      <span
                        className={drawerStyles.tag_tips}
                        style={{ marginRight: 8 }}
                      >
                        {t('选填')}
                      </span>
                    </div>
                  }
                >
                  {getFieldDecorator('path', {
                    initialValue: details.path || null,
                  })(
                    <Input placeholder={this.placeholder['请输入路由']} autoComplete="off" />
                  )}
                </Form.Item>
                <Form.Item
                  label={<div className={drawerStyles.label}>{t('排序')}</div>}
                >
                  {getFieldDecorator('sort', {
                    initialValue: details.sort ? `${details.sort}` : null,
                    rules: [
                      {
                        required: true,
                        validator: async (rule, value, callback) => {
                          if (!value || !value.trim()) {
                            const messageBody = (
                              <div className={drawerStyles.err_tips}>
                                <RCIcon
                                  type="icon-Mainwarningsfilled"
                                  style={{ marginRight: 8 }}
                                />
                                {t('请输入序号')}
                              </div>
                            );
                            callback(messageBody);
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                  })(
                    <Input
                      disabled
                      placeholder={this.placeholder['请输入序号']}
                      autoComplete="off"
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label={
                    <div className={drawerStyles.label}>
                      {t('策略')}
                      <span
                        className={drawerStyles.tag_tips}
                        style={{ marginRight: 8 }}
                      >
                        {t('选填')}
                      </span>
                    </div>
                  }
                >
                  {getFieldDecorator('policies', {
                    initialValue: this.policies || [],
                  })(
                    <RCSearchSelect
                      keyValue="name"
                      dataId="id"
                      restSelectText=""
                      placeholder={t('请输入策略名称')}
                      dataSource={this.data}
                      displayQuantity={5}
                      loading={loading}
                      hasMore={this.hasMore}
                      handleClickVisible={this.handleClickVisible}
                      handleSearchRole={this.handleSearch}
                      handleClearSearch={this.handleClearSearch}
                      handleInfiniteOnLoad={this.handleInfiniteOnLoad}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label={
                    <div className={drawerStyles.label}>
                      {t('描述')}
                      <span
                        className={drawerStyles.tag_tips}
                        style={{ marginRight: 8 }}
                      >
                        {t('选填')}
                      </span>
                    </div>
                  }
                >
                  {getFieldDecorator('describe', {
                    initialValue: details.describe || null,
                    rules: [
                      {
                        max: 200,
                        validator: async (rule, value, callback) => {
                          if (rule.max < getStrLen(value)) {
                            const messageBody = (
                              <div className={drawerStyles.err_tips}>
                                <RCIcon
                                  type="icon-Mainwarningsfilled"
                                  style={{
                                    marginRight: 8,
                                  }}
                                />
                                {t('资源描述不能超过{slot}个字符', { slot: rule.max })}
                              </div>
                            );
                            callback(messageBody);
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                  })(
                    <TextArea
                      placeholder={this.placeholder['请填写菜单描述信息，限200字符。']}
                      autoComplete="off"
                      style={{ height: 90 }}
                    />
                  )}
                </Form.Item>

                <Form.Item className={drawerStyles.button_box}>
                  <Button
                    type="secondary"
                    style={{ marginRight: 15 }}
                    onClick={this.close}
                  >
                    {t('取消')}
                  </Button>
                  <ButtonWithLoading
                    type="primary"
                    htmlType="submit"
                    loading={this.loadingSubmit}
                  >
                    {t('确认修改')}
                  </ButtonWithLoading>
                </Form.Item>
              </Form>
            </div>
          </Drawer> */}
        </div>
      </div>
    </div>
  );
};

export default MenusDetailsPage;
