import { Input } from 'antd';
import RCIcon from '@/common/Icon';
import './index.less';

const RCSearchInput = (props: any) => {
  const {
    value, noSearch = false, handleSearch, ...obj
  } = props;
  const renderSuffix = () => {
    if (value) {
      return (
        <>
          <RCIcon type="icon-close-filled" className="closeButtonIcon" onClick={props.handleClearSearch} />
          {!noSearch && (
          <RCIcon className="searchIcon" type="icon-search" onClick={props.handleSearch} />)}
        </>
      );
    }
    return (
      <>
        {!noSearch && (<RCIcon className="searchIcon" type="icon-search" onClick={handleSearch} />)}
      </>
    );
  };
  return (
    <Input id="searchInput" className="searchInput" {...obj} suffix={renderSuffix()} />
  );
};

export default RCSearchInput;
