import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useState, useEffect } from 'react';
import { Button, Input, Skeleton, Table, Dropdown, Menu, Modal, Row, Col, Tooltip, message } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import request from '@/utils/request';
import RCIcon from '@/common/Icon';
import BreadCrumb from '@/components/BreadCrumb';
import RCNoDataDisplay from '@/components/RCNoDataDisplay';
import RCButtonWithLoading from '@/components/RCButtonWithLoading';
import { getBLen, getCurrentUserInfo } from '@/utils';
import AddMembers from './add-members';
import './index.less';

function MemberManagement() {
  const navigate = useNavigate();
  const userInfo = getCurrentUserInfo();
  // 初始化
  const PAGEOBJ = {
    total: 0,
    current: 1,
    pageSize: 10
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState([] as any);
  const [pageObj, setPageObj] = useState(PAGEOBJ);
  const [tableList, setTableList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState('');
  const [addModal, setAddModal] = useState(false);
  // 移除用户
  const [removeModal, setRemoveModal] = useState(false);
  const [removeRecord, setRemoveRecord] = useState({} as any);

  // 请求用户列表
  const getUserList = () => {
    const params:any = {
      // orgId: userInfo.org?.id,
      limit: pageObj.pageSize,
      page: pageObj.current,
      sort: 'joinOrg.joinAt,desc'
    };
    if (inputText) {
      params.userName = inputText.trim();
    }
    const obj = {
      url: '/base/user-identity-management/api/v1/users',
      method: 'get',
      params
    };
    setLoading(true);
    request(obj).then((res:any) => {
      const { results, total } = res;
      const o = { ...pageObj };
      o.total = total;
      setTableList(results);
      setPageObj(o);
      setLoading(false);
    }).catch(() => {
      setTableList([]);
      setPageObj(PAGEOBJ);
      setLoading(false);
    });
  };
  
  useEffect(() => {
    getUserList();
  }, [pageObj.current, pageObj.pageSize]);

  // 选择用户
  const handleSelectChange = (keys: React.Key[], rows: any[]) => {
    setSelectedRowKeys(keys);
    setSelectedRows(rows);
  };

  // 分页
  const changePageSize = (page: any, pageSize: any) => {
    const o = { ...pageObj };
    o.current = 1;
    o.pageSize = pageSize;
    setPageObj(o);
  };

  const changePageCurrent = (page: any, pageSize: any) => {
    const o = { ...pageObj };
    o.current = page;
    o.pageSize = pageSize;
    setPageObj(o);
  };

  // 过滤
  const handleChangeInput = (e:any) => {
    setInputText(e.target.value);
  };

  // 搜索
  const handleSearch = () => {
    if (pageObj.current !== 1 || pageObj.pageSize !== 10) {
      setPageObj(PAGEOBJ);
    } else {
      getUserList();
    }
  };

  // 增加成员
  const handleAddMember = () => {
    setAddModal(true);
  };

  const handleCancel = (success: boolean) => {
    if (success) {
      getUserList();
    }
    setAddModal(false);
  };

  // 移除用户
  const removeUser = (record:any) => {
    setRemoveModal(true);
    setRemoveRecord(record);
  };

  const confirmRemove = () => {
    try {
      let userIds = [];
      if (!selectedRows.length) {
        userIds = [removeRecord.userId];
      } else {
        userIds = selectedRows.map((item: { userId: any; }) => item.userId);
      }
      setLoading(true);
      request({
        url: '/base/user-identity-management/api/v1/users',
        method: 'delete',
        data: JSON.stringify({ userIds }),
      }).then((res:any) => {
        const { deleteFailUsers } = res;
        if (deleteFailUsers.length > 0) {
          message.error(t('以下用户移除失败,请重试或联系管理员'));
          const arr = selectedRows.filter((user: { userId: any; }) => deleteFailUsers.findIndex((item: { userId: any; }) => item.userId === user.userId) > -1);
          setSelectedRows(arr);
          getUserList();
        } else {
          message.success(t('操作成功'));
          setRemoveModal(false);
          getUserList();
        }
        setPageObj({ ...PAGEOBJ, ...{ current: 1 } });
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClearItemCheck = (userItem: any) => {
    const arr = selectedRows.concat();
    const index = arr.findIndex((item: { userId: any; }) => item.userId === userItem.userId);
    arr.splice(index, 1);
    setSelectedRows(arr);
    if (arr.length < 1) {
      setRemoveModal(false);
      setSelectedRowKeys([]);
      setSelectedRows([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
  };

  const columns = [
    {
      title: t('用户名'),
      dataIndex: 'userName',
      key: 'userName',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {text && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('姓名'),
      dataIndex: 'displayName',
      key: 'displayName',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { userId: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.userId && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('邮箱'),
      dataIndex: 'email',
      key: 'email',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { userId: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.userId && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('手机'),
      dataIndex: 'mobile',
      key: 'mobile',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { userId: any; }) => (
        <Skeleton active loading={false} paragraph={{ rows: 1 }} title={false}>
          {record.userId && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('加入时间'),
      dataIndex: 'joinOrgAt',
      key: 'joinOrgAt',
      render: (text: string, record: { userId: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.userId && <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('操作'),
      dataIndex: 'action',
      key: 'aciton',
      width: 140,
      render: (text: any, record: { userId: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.userId && (<a onClick={() => { navigate(`../permission/member-management/${record.userId}`); }}>{ t('查看') }</a>)}
          <Dropdown
            overlay={
              <Menu onClick={() => { removeUser(record); }}>
                <Menu.Item key="1" style={{ minWidth: 90, textAlign: 'center' }}>
                  <span style={{ color: '#E60000' }}>{ t('移除用户') }</span>
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
          >
            <RCIcon type="icon-overflow" style={{ marginLeft: 20 }} />
          </Dropdown>
        </Skeleton>
      ),
    },
  ];

  return (
    <div className="page-body">
      <BreadCrumb name={t('用户管理')} />
      <div className="page-container">
      
        <div className="mermber">
          <div className="left-side">
            <div className="input-search">
              <Input
                placeholder={t('请输入关键字')}
                suffix={<RCIcon type="icon-search" />}
                disabled
              />
            </div>
            <div>
              <div title={userInfo.org?.name} className="item item-org">{userInfo.org?.name}</div>
              <div className="item item-active">{ t('全部成员') }</div>
            </div>
          </div>
          <div className="right-side">
            <div className="member-title">{ t('成员列表') }</div>
            <div className="member-list">
              <Input className="member-list-search" placeholder={t('请输入用户名')} allowClear onChange={handleChangeInput} suffix={<RCIcon type="icon-search" style={{ cursor: 'pointer' }} onClick={handleSearch} />} onPressEnter={handleSearch} />
              <Button type="primary" onClick={handleAddMember}>{ t('新增成员') }</Button>
            </div>
            {selectedRows.length > 0 && (
            <div className="check_total_wrap">
              {t('已选')} <span>{ selectedRows.length }</span> / {pageObj.total} {t('项')}
              <span className="handle_link" onClick={() => { setSelectedRowKeys([]); setSelectedRows([]); }}>{ t('清空所有') }</span>
              <span className="handle_remove_link" onClick={() => { setRemoveModal(true); }}>{ t('移除') }</span>
            </div>
          )}
            <Table
              rowKey={record => record.userId}
              rowSelection={rowSelection}
              columns={columns}
              locale={{ emptyText: <RCNoDataDisplay /> }}
              dataSource={tableList}
              pagination={
              pageObj.total > 10
                  ? {
                      pageSizeOptions: ['10', '20', '50'],
                      showQuickJumper: true,
                      showSizeChanger: true,
                      total: pageObj.total,
                      current: pageObj.current,
                      pageSize: pageObj.pageSize,
                      onShowSizeChange: changePageSize,
                      onChange: changePageCurrent,
                    }
                  : false
              }
            />
          </div>
        </div>
        <AddMembers showModal={addModal} handleCancel={handleCancel} />
        <Modal
          visible={removeModal}
          maskClosable={false}
          width={selectedRows.length ? 527 : 428}
          closeIcon={<RCIcon type="icon-cancel" />}
          className="rc-modal"
          onCancel={() => { setRemoveModal(false); setSelectedRowKeys([]); setSelectedRows([]); }}
          footer={null}
        >
          <Row>
            <Col span={24} className="rc-modal-icon"><RCIcon type="icon-Mainwarningsfilled" style={{ color: '#e60000' }} /></Col>
            <Col span={24} className="rc-modal-title">
              {selectedRows.length === 0 && <span>{ t('您确定要移除“{{userName}}”吗？', { ...removeRecord }) }</span>}
              {selectedRows.length !== 0 && <span>{ t('您确定要移除以下所有成员吗？') }</span>}
            </Col>
            <Col span={24} className="rc-modal-tip"><span>{ t('移除后，该成员数据会丢失，请谨慎操作') }</span></Col>
            {selectedRows.length !== 0 && (
            <Col span={24} className="remove_user_list">
              {selectedRows.map((userItem: any) => (
                <div key={userItem.userId} className="remove_user_item">
                  <div className="remove_user_item_name">{userItem.userName && (
                  <Tooltip title={userItem.userName}>
                    <span>{getBLen(userItem.userName, 20)}</span>
                  </Tooltip>
                  )}
                  </div>
                  <div className="remove_user_item_displayname">
                    {userItem.displayName && (
                      <Tooltip title={userItem.displayName}>
                        <span>{getBLen(userItem.displayName, 14)}</span>
                      </Tooltip>
                    )}
                  </div>
                  <RCIcon type="icon-close-filled" onClick={() => handleClearItemCheck(userItem)} />
                </div>
              ))}
            </Col>
          )}
            <Col span={24} className="rc-modal-btn">
              <Button type="default" style={{ marginRight: 16 }} onClick={() => { setRemoveModal(false); setSelectedRowKeys([]); setSelectedRows([]); }}>{ t('取消') }</Button>
              <RCButtonWithLoading danger type="primary" onClick={confirmRemove} loading={loading}>{ t('移除') }</RCButtonWithLoading>
            </Col>
          </Row>
        </Modal>
      </div>
    </div>
  );
}

export default MemberManagement;
