import { useState } from 'react';
import { Input, InputProps } from 'antd';
import RCIcon from '@/common/Icon';
import './index.less';

type PropsType = InputProps & {
  onSearch?: any;
  noSearch?: boolean;
}
const SearchInput = (props: PropsType) => {
  const { onSearch, noSearch, ...inputProps } = props;
  const [value, setValue] = useState('');
  const handleSearch = () => {
    if (onSearch) {
      const val = value ? value.trim() : '';
      onSearch(val);
    }
  };
  const handleClearSearch = () => {
    setValue('');
    if (onSearch) {
      onSearch('');
    }
  };
  const renderSuffix = () => {
    if (value) {
      return (
        <>
          <RCIcon type="icon-close-filled" className="closeButtonIcon" onClick={handleClearSearch} />
          {!noSearch && (
          <RCIcon className="searchIcon" type="icon-search" onClick={handleSearch} />)}
        </>
      );
    }
    return (
      <>
        {!noSearch && (<RCIcon className="searchIcon" type="icon-search" onClick={handleSearch} />)}
      </>
    );
  };
  return (
    <Input
      id="searchInput"
      className="searchInput"
      {...inputProps}
      value={value}
      onChange={(e) => { setValue(e.target.value || ''); }}
      onPressEnter={handleSearch}
      suffix={renderSuffix()}
      autoComplete="off"
    />
  );
};

SearchInput.defaultProps = {
  onSearch: undefined,
  noSearch: false
};

export default SearchInput;
