/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-07 18:10:04
 * @LastEditors: songone
 * @LastEditTime: 2022-12-08 20:09:36
 * @FilePath: \tide-user-center-web\src\pages\message-center\components\detail.tsx
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { Drawer, DrawerProps, Descriptions } from 'antd';
import { useEffect, useState } from 'react';
import { getMessageDetail } from '@/api/message';
import { handleError } from '@/utils';
import moment from 'moment';

type PropsType = DrawerProps & {
  item?: any
}
const MessageDetail = (props: PropsType) => {
  const { item, ...drawerProps } = props;
  const [detail, setDetail] = useState<any>({
    createName: '', // 发件人
    createAt: '', // 时间
    title: '',
    content: '',
    route: ''
  });
  const getData = async () => {
    try {
      const result = await getMessageDetail(item?.id);
      setDetail(result);
    } catch (error) {
      handleError(error);
    }
  };
  const handleRoute = () => {
    
  };
  useEffect(() => {
    
  }, []);
  useEffect(() => {
    if (item?.id) {
      getData();
    }
  }, [props.item]);
  return (
    <Drawer {...drawerProps}>
      <div id="message_info" className="rc_content_bg">
        <div className="upper_part">
          <Descriptions title={t('基本信息')}>
            <Descriptions.Item label={t('发件人')} span={3}>
              {detail.createName || (
              <span className="no_content">{t('暂无')}</span>
                )}
            </Descriptions.Item>
            <Descriptions.Item label={t('时间')} span={3}>
              {detail.createAt ? (
                  moment(detail.createAt).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )
                ) : (
                  <span className="no_content">{t('暂无')}</span>
                )}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <div className="lower_part">
          <div className="title">{detail.title}</div>
          <p className="template_content">
            {detail.content}
          </p>
          {detail.route &&
              Object.keys(detail.route).length !== 0 && (
                <a className="look_view" onClick={handleRoute}>
                  {t('去查看')}
                </a>
              )}
        </div>
      </div>
    </Drawer>);
};
MessageDetail.defaultProps = {
  item: undefined
};
export default MessageDetail;
