/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-20 15:46:58
 * @LastEditors: songone
 * @LastEditTime: 2022-12-22 09:43:22
 * @FilePath: \tide-user-center-web\src\api\org.ts
 */
import request from '@/utils/request';

// 获取机构列表
export const getOrgList = (params: any) => request({
  url: '/base/user-identity-management/api-admin/v1/org',
  method: 'get',
  params
});
// 获取机构详情
export const getOrgDetail = (companyId: string) => request({
  url: `/base/user-identity-management/api-admin/v1/org/${companyId}`,
  method: 'get',
});
// 获取机构用户
export const getOrgUserList = (params: any) => request({
  url: '/base/user-identity-management/api/v1/users',
  method: 'get',
  params
});
// 更新机构信息
export const updateOrgInfo = (orgId: string, data: any) => request({
  url: `/base/user-identity-management/api-admin/v1/org/${orgId}`,
  method: 'put',
  data
});
