import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { Descriptions, Tooltip } from 'antd';
import moment from 'moment';
import { getBLen } from '@/utils';
import './index.less';

const RCDescriptions = (props: any) => {
  const {
    data, title, items, column, ...other
  } = props;
  const num = column <= 2 ? 60 : 30;
  let list = items.map((k: any) => (
    <Descriptions.Item label={k.name} key={k.key}>
      {k.type === 'custom' && k.content}
      {k.type === 'date' &&
        (data && data[k.key] ? (
          <Tooltip title={moment(data[k.key]).format('YYYY-MM-DD HH:mm:ss')}>
            <span>{moment(data[k.key]).format('YYYY-MM-DD HH:mm:ss')}</span>
          </Tooltip>
        ) : (
          <div style={{ color: '#9EA5B2' }}>{ t('暂无') }</div>
        ))}
      {k.type === 'Boolean' && <div>{data[k.key] ? t('是') : t('否')}</div>}
      {k.type !== 'Boolean' &&
        k.type !== 'date' &&
        k.type !== 'custom' &&
        (data && data[k.key] ? (
          <Tooltip title={`${data[k.key]}`}>
            <span>{getBLen(`${data[k.key]}`, num)}</span>
          </Tooltip>
        ) : (
          <div style={{ color: '#9EA5B2' }}>{ t('暂无') }</div>
        ))}
    </Descriptions.Item>
  ));

  if (data.metaData) {
    const arr = data.metaData.map((m:any) => (
      <Descriptions.Item label={m.key} key={m.key}>
        <Tooltip title={`${m.value}`}>
          <span>{getBLen(`${m.value}`, num)}</span>
        </Tooltip>
      </Descriptions.Item>
    ));
    list = list.concat(arr);
  }

  return (
    <Descriptions
      className="details"
      title={title ? <div className="title">{title}</div> : null}
      column={column}
      {...other}
    >
      {list}
    </Descriptions>
  );
};

export default RCDescriptions;
