import { t } from '@rootcloud/rc-i18n-cli/hooks';
import './index.less';

const RCNoDataDisplay = (props: any) => (
  <div className="no_data_css" style={{ ...props.style }}>
    <div className="box_position">
      <div style={{ marginBottom: 10 }}>
        <img
          src={props.image || '/static/table-default.svg'}
          width="120"
          height="120"
          alt=""
        />
      </div>
      <span>{props.text || t('暂无数据')}</span>
    </div>
  </div>
);

export default RCNoDataDisplay;
