import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useState, useEffect } from 'react';
import { Skeleton, Table, Tooltip, Dropdown, Menu, Input, Button, Modal, Row, Col, message, Form } from 'antd';
import moment from 'moment';
import RCNoDataDisplay from '@/components/RCNoDataDisplay';
import RCButtonWithLoading from '@/components/RCButtonWithLoading';
import { getBLen, getStrLen } from '@/utils';
import RCIcon from '@/common/Icon';
import request from '@/utils/request';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from '@/components/BreadCrumb';
import './index.less';

function AuthorityManagement() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const PAGEOBJ = {
    total: 0,
    current: 1,
    pageSize: 10
  };
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [pageObj, setPageObj] = useState(PAGEOBJ);
  const [inputText, setInputText] = useState('');
  const [tableList, setTableList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState({} as any);
  const [addModal, setAddModal] = useState(false);

  // const alert = (
  //   <div className="alert_tips">
  //     <div>{t('角色权限只用于工业区块链BaaS平台')}</div>
  //     <div>{t('角色拥有一组权限的虚拟身份，用于服务、操作和资源的访问权限。')}</div>
  //     <div>{t('请将策略打包授予角色')}</div>
  //   </div>
  // );

  // 请求角色列表
  const getRolesList = () => {
    const params:any = {
      limit: pageObj.pageSize,
      page: pageObj.current,
      sort: 'createdAt,desc',
      scope: 'INTERNAL',
    };
    if (inputText) {
      params.name = inputText.trim();
    }
    const obj = {
      url: '/base/user-identity-management/api/v1/roles',
      method: 'get',
      params
    };
    setLoading(true);
    request(obj).then((res:any) => {
      const { results, total } = res;
      const o = { ...pageObj };
      o.total = total;
      setTableList(results);
      setPageObj(o);
      setLoading(false);
    }).catch(() => {
      setTableList([]);
      setPageObj(PAGEOBJ);
      setLoading(false);
    });
  };

  useEffect(() => { getRolesList(); }, [pageObj.current, pageObj.pageSize]);

  // 分页
  const changePageSize = (page: any, pageSize: any) => {
    const o = { ...pageObj };
    o.current = 1;
    o.pageSize = pageSize;
    setPageObj(o);
  };
  
  const changePageCurrent = (page: any, pageSize: any) => {
    const o = { ...pageObj };
    o.current = page;
    o.pageSize = pageSize;
    setPageObj(o);
  };

  // 过滤
  const handleChangeInput = (e:any) => {
    setInputText(e.target.value);
  };

  // 搜索
  const handleSearch = () => {
    if (pageObj.current !== 1 || pageObj.pageSize !== 10) {
      setPageObj(PAGEOBJ);
    } else {
      getRolesList();
    }
  };

  // 创建角色
  const handleAddRole = () => {
    setAddModal(true);
  };

  // 刪除自定義角色
  const deletePolicy = (record:any) => {
    setDeleteRecord(record);
    setDeleteModal(true);
  };

  const confirmRemove = () => {
    setActionLoading(true);
    request({
      url: `/base/user-identity-management/api/v1/roles/${deleteRecord.id}`,
      method: 'delete',
    }).then(() => {
      message.success(t('删除成功'));
      getRolesList();
      setPageObj({ ...PAGEOBJ, ...{ current: 1 } });
      setActionLoading(false);
      setDeleteModal(false);
    }).catch(() => {
      setActionLoading(false);
    });
  };

  // 添加角色
  const handleSubmit = (values:any) => {
    const params = {
      type: 'CUSTOM',
      name: values.name,
      scope: 'INTERNAL',
      desc: values.desc
    };
    setActionLoading(true);
    request({
      url: '/base/user-identity-management/api/v1/roles',
      method: 'post',
      body: params
    }).then(() => {
      form.resetFields();
      message.success(t('角色创建成功'));
      setActionLoading(false);
      setAddModal(false);
      getRolesList();
    }).catch(() => {
      setActionLoading(false);
    });
  };

  const columns = [
    {
      title: t('角色名称'),
      dataIndex: 'name',
      key: 'name',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {text && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('创建人'),
      dataIndex: 'creator',
      key: 'creator',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { id: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('创建时间'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string, record: { id: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('描述'),
      dataIndex: 'desc',
      key: 'desc',
      render: (text: string, record: { id: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <Tooltip title={text}><span>{getBLen(text, 20)}</span></Tooltip>}
        </Skeleton>
      ),
    },
    {
      title: t('角色类型'),
      dataIndex: 'type',
      key: 'type',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { id: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <span>{text === 'SYSTEM' ? t('系统角色') : t('自定义角色')}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('操作'),
      dataIndex: 'action',
      key: 'aciton',
      width: 140,
      render: (text: any, record: any) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.id && (
            <>
              <a onClick={() => { navigate(`../permission/authority-management/${record.id}`); }}>{t('查看')}</a>
              {record.type === 'CUSTOM' && (
                <Dropdown
                  overlay={
                    <Menu onClick={() => { deletePolicy(record); }}>
                      <Menu.Item key="1" style={{ minWidth: 90, textAlign: 'center' }}>
                        <span style={{ color: '#E60000' }}>{ t('删除') }</span>
                      </Menu.Item>
                    </Menu>
                  }
                  placement="bottomRight"
                >
                  <RCIcon type="icon-overflow" style={{ marginLeft: 20 }} />
                </Dropdown>
              )}
            </>
          )}
        </Skeleton>
      ),
    },
  ];
  return (
    <div className="page-body">
      <BreadCrumb name={t('角色管理')} />
      <div className="page-container">
        {/* <Alert description={alert} type="success" closable message={t('角色提示')} /> */}
        <div className="member-list" style={{ margin: '16px 0' }}>
          <Input className="member-list-search" placeholder={t('请输入角色名称')} allowClear onChange={handleChangeInput} suffix={<RCIcon type="icon-search" style={{ cursor: 'pointer' }} onClick={handleSearch} />} onPressEnter={handleSearch} />
          <Button type="primary" onClick={handleAddRole}>{ t('创建角色') }</Button>
        </div>
        <Table
          rowKey={record => record.id}
          columns={columns}
          locale={{ emptyText: <RCNoDataDisplay /> }}
          dataSource={tableList}
          pagination={
            pageObj.total > 10
                ? {
                    showQuickJumper: true,
                    showSizeChanger: true,
                    total: pageObj.total,
                    current: pageObj.current,
                    pageSize: pageObj.pageSize,
                    onShowSizeChange: changePageSize,
                    onChange: changePageCurrent,
                  }
                : false
            }
        />
        <Modal
          visible={deleteModal}
          maskClosable={false}
          width={428}
          closeIcon={<RCIcon type="icon-cancel" />}
          className="rc-modal"
          onCancel={() => { setDeleteModal(false); }}
          footer={null}
        >
          <Row>
            <Col span={24} className="rc-modal-icon"><RCIcon type="icon-Mainwarningsfilled" style={{ color: '#e60000' }} /></Col>
            <Col span={24} className="rc-modal-title">
              <span>{ t('您确定要删除角色“{{name}}”吗？', { ...deleteRecord }) }</span>
            </Col>
            <Col span={24} className="rc-modal-tip"><span>{ t('删除后，该角色会丢失，此操作不可撤销') }</span></Col>
            <Col span={24} className="rc-modal-btn">
              <Button type="default" style={{ marginRight: 16 }} onClick={() => { setDeleteModal(false); }}>{ t('取消') }</Button>
              <RCButtonWithLoading danger type="primary" onClick={confirmRemove} loading={actionLoading}>{ t('删除') }</RCButtonWithLoading>
            </Col>
          </Row>
        </Modal>
        <Modal
          visible={addModal}
          maskClosable={false}
          width={576}
          title={t('创建内部角色')}
          closeIcon={<RCIcon type="icon-cancel" />}
          footer={null}
          style={{ textAlign: 'left' }}
          onCancel={() => { setAddModal(false); form.resetFields(); }}
        >
          <Form
            layout="horizontal"
            colon
            initialValues={{ register: 'password' }}
            onFinish={handleSubmit}
            form={form}
          >
            <Form.Item
              name="name"
              label={<div className="add_style_text">{ t('角色名称') }</div>}
              rules={[
                {
                  required: true,
                  min: 4,
                  max: 20,
                  message: <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入角色名称') }</div>,
                  validator: async (_:any, value:string) => {
                    if (!value || !value.trim()) {
                      _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入角色名称') }</div>;
                      return Promise.reject();
                    } else if (_.max < getStrLen(value)) {
                      _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />角色名称不能超过{_.max}个字符</div>;
                      return Promise.reject();
                    } else if (_.min > getStrLen(value)) {
                      _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />角色名称不能少于{_.min}个字符</div>;
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder={t('请输入角色名称')} allowClear autoComplete="off" />
            </Form.Item>
            <Form.Item
              name="desc"
              label={<div className="add_style_text"><span className="mr-8">{ t('描述说明') }</span><span className="tag_tips">{ t('选填') }</span></div>}
              rules={[
                {
                  required: false,
                  max: 200,
                  message: '',
                  validator: async (_:any, value:string) => {
                    if (value && _.max < getStrLen(value)) {
                      _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />描述说明不能超过{_.max}个字符</div>;
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input.TextArea placeholder={t('请输入描述说明，限200字符')} allowClear autoComplete="off" autoSize={{ minRows: 4 }} />
            </Form.Item>
            <Form.Item className="modal-btn-left">
              <Button type="default" style={{ marginRight: 15 }} onClick={() => { setAddModal(false); form.resetFields(); }}>{ t('取消') }</Button>
              <RCButtonWithLoading type="primary" htmlType="submit" loading={actionLoading}>{ t('确定') }</RCButtonWithLoading>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default AuthorityManagement;
