import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useState, useEffect } from 'react';
import { Input, message, Table, Skeleton, Modal, Button, Row, Col } from 'antd';
import moment from 'moment';
import RCNoDataDisplay from '@/components/RCNoDataDisplay';
import RCButtonWithLoading from '@/components/RCButtonWithLoading';
import RCIcon from '@/common/Icon';
import request from '@/utils/request';
import { getBLen } from '@/utils';
import './index.less';

const PermissionUser = (props: any) => {
  const PAGEOBJ = { total: 0, current: 1, pageSize: 10 };
  const [loading, setLoading] = useState(false);
  const [pageObj, setPageObj] = useState(PAGEOBJ);
  const [inputText, setInputText] = useState('');
  const [tableList, setTableList] = useState([]);

  // 解除关联
  const [relieveModal, setRelieveModal] = useState(false);
  const [relieveRecord, setRelieveRecord] = useState({} as any);
  const [loadingComfirm, setLoadingComfirm] = useState(false);

  // 请求策略列表
  const getUserList = () => {
    const params: any = {
      limit: pageObj.pageSize,
      page: pageObj.current,
      roleName: decodeURIComponent(props.roleName),
      sort: 'userId,desc',
      withTotal: true,
    };
    if (inputText) {
      params.userName = inputText.trim();
    }
    const obj = {
      url: '/base/user-identity-management/api/v1/role-user/users',
      method: 'get',
      params,
    };
    setLoading(true);
    request(obj)
      .then((res: any) => {
        const { results, total } = res;
        const o = { ...pageObj };
        o.total = total;
        setTableList(results);
        setPageObj(o);
        setLoading(false);
      })
      .catch(() => {
        setTableList([]);
        setPageObj(PAGEOBJ);
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserList();
  }, [pageObj.current, pageObj.pageSize]);

  // 过滤
  const handleChangeInput = (e: any) => {
    setInputText(e.target.value);
  };

  // 搜索
  const handleSearch = () => {
    if (pageObj.current !== 1 || pageObj.pageSize !== 10) {
      setPageObj(PAGEOBJ);
    } else {
      getUserList();
    }
  };

  // 分页
  const changePageSize = (page: any, pageSize: any) => {
    const o = { ...pageObj };
    o.current = 1;
    o.pageSize = pageSize;
    setPageObj(o);
  };

  const changePageCurrent = (page: any, pageSize: any) => {
    const o = { ...pageObj };
    o.current = page;
    o.pageSize = pageSize;
    setPageObj(o);
  };

  // 解除关联
  const confirmRelieve = () => {
    setLoadingComfirm(true);
    const params = {
      userId: relieveRecord.userId,
      roleIds: [props.roleId],
      orgId: relieveRecord.orgId
    };
    request({
      url: '/base/user-identity-management/api/v1/user-roles/relieve',
      method: 'patch',
      body: params,
    })
      .then(() => {
        message.success(t('移除角色成功'));
        setLoadingComfirm(false);
        setRelieveModal(false);
        getUserList();
      })
      .catch(() => {
        setLoadingComfirm(false);
      });
  };

  const columns = [
    {
      title: t('用户名'),
      dataIndex: 'userName',
      key: 'userName',
      render: (
        text:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined,
      ) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {text && <span>{getBLen(text, 20)}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('姓名'),
      dataIndex: 'displayName',
      key: 'displayName',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { userId: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.userId && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('授权时间'),
      dataIndex: 'bindAt',
      key: 'bindAt',
      render: (text: string, record: { userId: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.userId && moment(text).format('YYYY-MM-DD HH:mm:ss')}
        </Skeleton>
      ),
    },
    {
      title: t('操作'),
      dataIndex: 'actions',
      key: 'actions',
      width: 80,
      render: (text: any, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record.userId &&
            <span>
              <a style={{ color: '#e60000' }} onClick={() => { setRelieveModal(true); setRelieveRecord(record); }}>{ t('解除') }</a>
            </span>
          }
        </Skeleton>
      ),
    },
  ];

  return (
    <div>
      <div className="policy-input">
        <Input
          placeholder={t('请输入用户名')}
          allowClear
          onChange={handleChangeInput}
          suffix={
            <RCIcon type="icon-search" style={{ cursor: 'pointer' }} onClick={handleSearch} />
          }
          onPressEnter={handleSearch}
          style={{ width: 319 }}
        />
      </div>
      <Table
        rowKey={record => record.userId}
        columns={columns}
        locale={{ emptyText: <RCNoDataDisplay /> }}
        dataSource={tableList}
        pagination={
          pageObj.total > 10
            ? {
                showQuickJumper: true,
                showSizeChanger: true,
                total: pageObj.total,
                current: pageObj.current,
                pageSize: pageObj.pageSize,
                onShowSizeChange: changePageSize,
                onChange: changePageCurrent,
              }
            : false
        }
      />
      <Modal
        visible={relieveModal}
        maskClosable={false}
        width={428}
        closeIcon={<RCIcon type="icon-cancel" />}
        className="rc-modal"
        onCancel={() => { setRelieveModal(false); }}
        footer={null}
      >
        <Row>
          <Col span={24} className="rc-modal-icon"><RCIcon type="icon-Mainwarningsfilled" style={{ color: '#e60000' }} /></Col>
          <Col span={24} className="rc-modal-title">
            <span>{ t('您确定要移除“{{userName}}”该角色吗？', { ...relieveRecord }) }</span>
          </Col>
          <Col span={24} className="rc-modal-tip"><span>{ t('移除后，该角色需要重新赋予') }</span></Col>
          <Col span={24} className="rc-modal-btn">
            <Button type="default" style={{ marginRight: 16 }} onClick={() => { setRelieveModal(false); }}>{ t('取消') }</Button>
            <RCButtonWithLoading danger type="primary" onClick={confirmRelieve} loading={loadingComfirm}>{ t('确定') }</RCButtonWithLoading>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default PermissionUser;
