import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import JSEncrypt from 'jsencrypt';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import request from '@/utils/request';
import { setToken, setRefreshToken } from '@/utils/auth';
import { setCurrentUserInfo, clearLogin } from '@/utils';
import './index.less';

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleLogin = async (username:string, pd:string) => {
    try {
      await request({
        url: '/base/user-identity-management/auth/login',
        method: 'post',
        body: { userName: username.trim(), password: pd }
      }).then((res:any) => {
        message.success(t('登录成功'));
        setToken(res.token);
        setRefreshToken(res.refresh_token);
        setCurrentUserInfo(res);
        navigate('/layout/user/permission/member-management');
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    } catch (error: any) {
      setLoading(false);
    }
  };

  // 获取pubilc key
  const getPublicKey = (values: any) => {
    const { username, password } = values;
    let publicKey = '';
    setLoading(true);
    request({ url: '/base/user-identity-management/auth/public-key', method: 'get' }).then((res:any) => {
      publicKey = res;
      const jsencrypt = new JSEncrypt();
      jsencrypt.setPublicKey(publicKey);
      const pd = jsencrypt.encrypt(password) as string;
      handleLogin(username, pd);
    }).catch(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    clearLogin();
  }, []);

  return (
    <div>
      <div className="login-header">
        <img src="/static/logo.svg" alt="" className="log" />
      </div>
      <div className="login-content">
        <img
          src="/static/summary.svg"
          alt=""
          width="740"
          height="520"
        />
        <div className="login-wrap">
          <div className="login-title-wrap">
            <h2 className="login-title">{ t('登录') }</h2>
            <p className="login-desc">
              {t('欢迎登录工业区块链')}
            </p>
          </div>
          <Form size="large" onFinish={getPublicKey}>
            <Form.Item name="username">
              <Input prefix={<UserOutlined />} placeholder={t('账号')} />
            </Form.Item>
            <Form.Item name="password">
              <Input.Password type="password" prefix={<LockOutlined />} visibilityToggle placeholder={t('密码')} />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary" block loading={loading}>{ t('登录') }</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="login-footer">
        { t('Copyright © 2022 ROOTCLOUD. All rights reserved 树根互联 版权所有 粤ICP备19026860号-1') }
      </div>
    </div>
  );
};

export default Login;
