/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-11-18 17:43:39
 * @LastEditors: songone
 * @LastEditTime: 2022-11-28 15:35:37
 * @FilePath: \tide-user-center-web\src\store\index.tsx
 */
import { createContext, useReducer } from 'react';
import { getMenuTree } from '@/utils/index';


interface IStateType {
  userInfo: any;
  menus: any[];
  loading: boolean;
}
const defaultState: IStateType = {
  userInfo: {
    user: {
      userId: '',
      userName: '',
      roles: []
    },
    org: {
      id: ''
    }
  },
  menus: [],
  loading: false
};
function reducer(state: IStateType, action: any) {
  switch (action.type) {
    case 'setUserInfo':
      return { ...state, userInfo: action.payload };
    case 'setMenus':
      return { ...state, menus: getMenuTree('-1', action.payload, [], 'MENU') };
    case 'setLoading':
      return { ...state, loading: action.payload };
    default:
      return { ...state };
  }
}
export const MyContext = createContext({ state: defaultState });

const ContextProvider = ({ children } : { children: any }) => {
  const [state, dispatch] = useReducer(
    reducer,
    defaultState
  );
  
  const value = {
    state,
    dispatch
  };
  return (
    <MyContext.Provider value={value}>
      {children}
    </MyContext.Provider>
  );
};
export default ContextProvider;
