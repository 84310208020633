/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-20 14:36:27
 * @LastEditors: songone
 * @LastEditTime: 2022-12-22 14:26:09
 * @FilePath: \tide-user-center-web\src\pages\orgs-management\index.tsx
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Table, message, Skeleton, Tooltip, Form, PaginationProps } from 'antd';
import BreadCrumb from '@/components/BreadCrumb';
import RCIcon from '@/common/Icon';
import { getOrgList } from '@/api/org';
import { getBLen } from '@/utils';
import moment from 'moment';

const OrgsManagementPage: React.FC<{}> = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [searchForm, setSearchForm] = useState<{
    sort: string;
    withTotal: boolean;
    page: number;
    limit: number;
    orgName: string;
  }>({
    sort: 'createAt,DESC',
    withTotal: true,
    page: 1,
    limit: 10,
    orgName: '',
  });
  const [listData, setListData] = useState({
    total: 0,
    results: []
  });
  const getData = async () => {
    try {
      setLoading(true);
      const result = await getOrgList(searchForm);

      if (result && !result.code && !result.error) {
        setListData(result);
        // this.companyList = result.results;
        // this.total = result.total;
        // this.loading = false;
      } else {
        setListData({
          total: 0,
          results: []
        });
        message.error(result?.message || t('获取机构列表失败'));
      }
    } catch (err) {
      setListData({
        total: 0,
        results: []
      });
      message.error(t('当前网络不稳定，请稍后重试'));
    }
    setLoading(false);
  };
  const onFinish = (values: { orgName: string; }) => {
    setSearchForm({
      ...searchForm,
      orgName: values.orgName ? values.orgName.trim() : '',
      page: 1
    });
  };

  useEffect(() => {
    getData();
  }, [searchForm]);

  const columns = [
    {
      title: t('机构名称'),
      dataIndex: 'orgName',
      key: 'orgName',
      render: (text: string) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {text && (
            <Tooltip title={text}>
              <span>{getBLen(text, 20)}</span>
            </Tooltip>
          )}
        </Skeleton>
      ),
    },
    {
      title: t('所属行业'),
      dataIndex: 'industry',
      key: 'industry',
      render: (text: string, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record.orgName && text}
        </Skeleton>
      ),
    },
    {
      title: t('管理员'),
      dataIndex: 'orgAdmin',
      key: 'orgAdmin',
      render: (text: string, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record.orgName && text}
        </Skeleton>
      ),
    },
    {
      title: t('手机'),
      dataIndex: 'mobile',
      key: 'mobile',
      render: (text: string, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record.orgName && text}
        </Skeleton>
      ),
    },
    {
      title: t('创建时间'),
      dataIndex: 'createAt',
      key: 'createAt',
      render: (text: string, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record.orgName && (
            <span>
              {text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}
            </span>
          )}
        </Skeleton>
      ),
    },
    {
      title: t('操作'),
      dataIndex: 'action',
      key: 'action',
      render: (text: string, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1, }}
          title={false}
        >
          {record.orgName && (
            <a onClick={() => { navigate(`../detail/${record.orgId}`); }}>
              {t('机构详情')}
            </a>
          )}
        </Skeleton>
      ),
    },
  ];
  const pagenation: PaginationProps = {
    total: listData.total,
    current: searchForm.page,
    pageSize: searchForm.limit,
    onChange: (page: number, pageSize: number) => {
      setSearchForm({
        ...searchForm,
        page: page || 1,
        limit: pageSize || 20
      });
    },
  };
  return (
    <div className="page-body">
      <BreadCrumb name={t('机构管理')} />
      <div className="page-container">
        <Form onFinish={onFinish} form={form} layout="inline" className="filter-container">
          <Form.Item label={null} name="orgName">
            <Input placeholder={t('请输入机构名称')} suffix={<RCIcon type="icon-search" onClick={() => form.submit()} />} />
          </Form.Item>
          
        </Form>
        <div>
          <Table
            columns={columns}
            dataSource={listData.results}
            pagination={pagenation}
            rowKey={record => record.orgId}
          />
        </div>
      </div>
      
    </div>
  );
};

export default OrgsManagementPage;
