import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useState, useEffect } from 'react';
import { Input, Table, Skeleton, Modal, Form, Button } from 'antd';
import { useParams } from 'react-router-dom';
import BreadCrumb from '@/components/BreadCrumb';
import RCDescriptions from '@/components/RCDescriptions';
import RCNoDataDisplay from '@/components/RCNoDataDisplay';
import RCButtonWithLoading from '@/components/RCButtonWithLoading';
import RCIcon from '@/common/Icon';
import request from '@/utils/request';
import moment from 'moment';
import { getStrLen } from '@/utils';
import './index.less';

const PolicyDetails = () => {
  const pageParams = useParams();
  const [form] = Form.useForm();
  const PAGEOBJ = {
    total: 0,
    current: 1,
    pageSize: 10,
  };
  const [loading, setLoading] = useState(false);
  const [pageObj, setPageObj] = useState(PAGEOBJ);
  const [basicData, setBasicData] = useState({} as any);
  const [switchModal, setSwitchModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [inputText, setInputText] = useState('');
  const [tableList, setTableList] = useState([]);

  // 查询策略详情
  const getPolicyDetails = () => {
    const obj = {
      url: `/base/user-identity-management/api/v1/policies/${pageParams.id}`,
      method: 'get',
    };
    request(obj)
      .then((res: any) => { setBasicData(res); })
      .catch(() => {});
  };

  // 请求策略列表
  const getPolicyList = () => {
    const params: any = {
      limit: pageObj.pageSize,
      page: pageObj.current,
      policyId: pageParams.id,
    };
    if (inputText) {
      params.name = inputText.trim();
    }
    const obj = {
      url: '/base/user-identity-management/api/v1/permissions',
      method: 'get',
      params,
    };
    setLoading(true);
    request(obj)
      .then((res: any) => {
        const { results, total } = res;
        const o = { ...pageObj };
        o.total = total;
        setTableList(results);
        setPageObj(o);
        setLoading(false);
      })
      .catch(() => {
        setTableList([]);
        setPageObj(PAGEOBJ);
        setLoading(false);
      });
  };

  useEffect(() => {
    getPolicyDetails();
    getPolicyList();
  }, []);

  useEffect(() => {
    getPolicyList();
  }, [pageObj.current, pageObj.pageSize]);

  // 展开或收起
  const handleExpand = () => {
    setSwitchModal(!switchModal);
  };

  // 过滤
  const handleChangeInput = (e: any) => {
    setInputText(e.target.value);
  };

  // 搜索
  const handleSearch = () => {
    if (pageObj.current !== 1 || pageObj.pageSize !== 10) {
      setPageObj(PAGEOBJ);
    } else {
      getPolicyList();
    }
  };

  // 分页
  const changePageSize = (page: any, pageSize: any) => {
    const o = { ...pageObj };
    o.current = 1;
    o.pageSize = pageSize;
    setPageObj(o);
  };

  const changePageCurrent = (page: any, pageSize: any) => {
    const o = { ...pageObj };
    o.current = page;
    o.pageSize = pageSize;
    setPageObj(o);
  };

  const actionsName = (actions: any[]) => {
    if (!actions || !actions.length) {
      return null;
    }

    const nameList = actions.map(k => k.name);
    return nameList.toString();
  };

  // 编辑策略
  const handleSubmit = async (values: any) => {
    const params = { ...basicData, ...values };
    setLoading(true);
    request({
      url: `/base/user-identity-management/api/v1/policies/${pageParams.id}`,
      method: 'put',
      body: params
    }).then(() => {
      setLoading(false);
      setEditModal(false);
      getPolicyDetails();
    }).catch(() => {
      setLoading(false);
    });
  };

  const columns = [
    {
      title: t('权限'),
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      render: (
        text:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined,
      ) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {text && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('操作'),
      dataIndex: 'actions',
      key: 'actions',
      render: (text: any[], record: { id: any }) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record.id && <span>{actionsName(text)}</span>}
        </Skeleton>
      ),
    },
  ];
  return (
    <div className="page-body">
      <BreadCrumb
        name={`详情：${basicData.name || ''}`}
        showBack
      />
      <div className="page-container">
        <div
          className="content"
          style={!switchModal ? { maxHeight: 200 } : { maxHeight: 56 }}
        >
          <RCDescriptions
            title={
              <div>
                {t('基本信息')}
                {basicData.type !== '0' && (
                <RCIcon type="icon-bianji" className="icon" onClick={() => { setEditModal(true); }} />
              )}
                <span className="update_time">
                  {t('更新时间')}：
                  {moment(basicData?.updateTime).format('YYYY-MM-DD HH:mm:ss')}
                </span>
              </div>
          }
            column={3}
            data={basicData}
            items={[
            { name: t('策略名称'), key: 'name' },
            { name: t('创建时间'), key: 'createdAt', type: 'date' },
          ]}
          />
          <div className="desc">
            <div className="desc_title">{ t('描述信息') }</div>
            <div className="desc_content">
              {basicData.describe || <div className="no-content">{ t('暂无') }</div>}
            </div>
          </div>
        </div>
        <div className="action" onClick={handleExpand}>
          {!switchModal && (
          <>
            {t('收起')} <RCIcon type="icon-arrow_up_line" style={{ marginLeft: 8 }} />
          </>
        )}
          {switchModal && (
          <>
            {t('展开更多')}
            <RCIcon type="icon-arrow_down_line" style={{ marginLeft: 8 }} />
          </>
        )}
        </div>
        <div className="line" />
        <div className="member-list" style={{ margin: '16px 0' }}>
          <Input
            className="member-list-search"
            placeholder={t('请输入权限名称')}
            allowClear
            onChange={handleChangeInput}
            suffix={
              <RCIcon
                type="icon-search"
                style={{ cursor: 'pointer' }}
                onClick={handleSearch}
              />
          }
            onPressEnter={handleSearch}
          />
        </div>
        <Table
          rowKey={record => record.id}
          columns={columns}
          locale={{ emptyText: <RCNoDataDisplay /> }}
          dataSource={tableList}
          pagination={
          pageObj.total > 10
            ? {
                showQuickJumper: true,
                showSizeChanger: true,
                total: pageObj.total,
                current: pageObj.current,
                pageSize: pageObj.pageSize,
                onShowSizeChange: changePageSize,
                onChange: changePageCurrent,
              }
            : false
        }
        />
      </div>
      <Modal
        visible={editModal}
        maskClosable={false}
        width={527}
        title={t('修改基本信息')}
        closeIcon={<RCIcon type="icon-cancel" />}
        footer={null}
        style={{ textAlign: 'left' }}
        onCancel={() => { setEditModal(false); form.resetFields(); }}
      >
        <Form
          layout="horizontal"
          colon
          onFinish={handleSubmit}
          form={form}
          initialValues={{ name: basicData.name, describe: basicData.describe }}
        >
          <Form.Item
            name="name"
            label={<div className="add_style_text">{ t('策略名称') }</div>}
            rules={[
            {
              required: true,
              min: 4,
              max: 20,
              message: <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入策略名称') }</div>,
              validator: async (_:any, value:string) => {
                if (!value || !value.trim()) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入策略名称') }</div>;
                  return Promise.reject();
                } else if (_.max < getStrLen(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />策略名称不能超过{_.max}个字符</div>;
                  return Promise.reject();
                } else if (_.min > getStrLen(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />策略名称不能少于{_.min}个字符</div>;
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
          >
            <Input placeholder={t('请输入策略名称')} allowClear autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="describe"
            label={<div className="add_style_text">{ t('描述说明') }</div>}
            rules={[
            {
              required: false,
              max: 200,
              message: '',
              validator: async (_:any, value:string) => {
                if (value && _.max < getStrLen(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />描述说明不能超过{_.max}个字符</div>;
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
          >
            <Input.TextArea placeholder={t('请输入描述说明')} allowClear autoComplete="off" autoSize={{ minRows: 4 }} />
          </Form.Item>
          <Form.Item className="modal-btn-left">
            <Button type="default" style={{ marginRight: 15 }} onClick={() => { setEditModal(false); form.resetFields(); }}>{ t('取消') }</Button>
            <RCButtonWithLoading type="primary" htmlType="submit" loading={loading}>{ t('确认') }</RCButtonWithLoading>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PolicyDetails;
