/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-11-01 17:17:56
 * @LastEditors: songone
 * @LastEditTime: 2023-01-04 17:29:44
 * @FilePath: \tide-user-center-web\src\pages\event-center\index.tsx
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Table, TableColumnType, Skeleton, PaginationProps } from 'antd';
import RCTimePicker from '@/components/RCTimePicker';
import RCIcon from '@/common/Icon';
import { getEventList } from '@/api/event';
import { handleError, getCurrentUserInfo } from '@/utils';
import BreadCrumb from '@/components/BreadCrumb';
import useDropdownSelect from '@/components/common/useDropdownSelect';
import SearchInput from '@/components/common/SearchInput';

import EventDetail from './components/detail';


type ItemType = {
  id: string;
  requestId: string;
  bizId: string;
  userId: string;
  userName: string;
  userDisplayName: string;
  orgId: string;
  orgName: string;
  ip: string;
  moduleName: string;
  operation: string;
  resourceType: string;
  resourceName: string;
  requestCreationTime: string;
  apiPath: string;
  succeed: boolean;
  content: any;
  extendProperties: any;
}

const EventCenterPage: React.FC<{}> = () => {
  const [loading, setLoading] = useState(false);
  const [detailProps, setDetailProps] = useState<{
    visible: boolean;
    item: ItemType | undefined;
  }>({
    visible: false,
    item: undefined
  });
  const [searchForm, setSearchForm] = useState<{
    sort: 'createdAt,desc' | string,
    page: number;
    limit: number;
    startTime: undefined | moment.Moment,
    endTime: undefined | moment.Moment,
    searchCondition?: string; // 用户名、姓名
    moduleName?: string; // 模块名称
    operation?: string; // 操作内容
  }>({
    sort: 'createdAt,desc',
    page: 1,
    limit: 10,
    startTime: undefined,
    endTime: undefined,
    searchCondition: '',
    moduleName: ''
  });
  const [selectModel, dropdownSelect] = useDropdownSelect([
    { name: t('baas平台'), key: 'tide_baas' },
    { name: t('用户中心'), key: 'tide-user-center' },
  ]);
  const [listData, setListData] = useState({
    results: [],
    total: 0
  });
  const getData = async () => {
    setLoading(true);
    if (!selectModel?.key) {
      return;
    }
    try {
      const { org, user } = getCurrentUserInfo();
      const result = await getEventList(selectModel.key, {
        ...searchForm,
        startTime: searchForm.startTime?.format('x'),
        endTime: searchForm.endTime?.format('x'),
        orgId: org?.id,
        userId: user?.userId
      });
      setListData(result);
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };
  const getTime = (val: moment.Moment[]) => {
    setSearchForm({
      ...searchForm,
      page: 1,
      startTime: val?.length > 0 ? val[0] : undefined,
      endTime: val?.length > 1 ? val[1] : undefined,
    });
  };
  const handleLook = async (record: ItemType) => {
    setDetailProps({
      ...detailProps,
      visible: true,
      item: record
    });
  };
  const handleCloseDetail = () => {
    setDetailProps({
      ...detailProps,
      visible: false,
      item: undefined
    });
  };
  // const handleFinish = (values: any) => {
  //   setSearchForm({
  //     ...searchForm,
  //     searchCondition: values.searchCondition ? values.searchCondition.trim() : '',
  //     moduleName: values.moduleName ? values.moduleName.trim() : ''
  //   });
  // };
  const handleSearch = (value: string) => {
    setSearchForm({
      ...searchForm,
      searchCondition: value,
    });
  };
  
  useEffect(() => {
    if (searchForm.startTime && searchForm.endTime && selectModel) {
      getData();
    }
  }, [searchForm, selectModel]);
  const columns: TableColumnType<any>[] = [
    {
      title: t('用户名'),
      dataIndex: 'userName',
      render: (text: string) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          <span>{text}</span>
        </Skeleton>
      ),
    },
    {
      title: t('姓名'),
      dataIndex: 'userDisplayName',
      render: (text: string) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          <span>{text}</span>
        </Skeleton>
      ),
    },
    {
      title: 'IP地址',
      dataIndex: 'ip',
      render: (text: string) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          <span>{text}</span>
        </Skeleton>
      ),
    },
    {
      title: t('所属模块'),
      dataIndex: 'moduleName',
      render: (text: string) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          <span>{text}</span>
        </Skeleton>
      ),
    },
    {
      title: t('操作内容'),
      dataIndex: 'operation',
      render: (text: string) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          <span>{text}</span>
        </Skeleton>
      ),
    },
    {
      title: t('资源类型'),
      dataIndex: 'resourceType',
      render: (text: string) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          <span>{text}</span>
        </Skeleton>
      ),
    },
    {
      title: t('资源名称'),
      dataIndex: 'resourceName',
      render: (text: string) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          <span>{text}</span>
        </Skeleton>
      ),
    },
    {
      title: t('执行结果'),
      dataIndex: 'succeed',
      render: (succeed: boolean) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          <span>{succeed ? <><RCIcon type="icon-successfilled" style={{ color: '#00B259' }} />&nbsp;{t('成功')} </> : <><RCIcon type="icon-close-filled" style={{ color: '#E60000' }} />&nbsp;{ t('失败')} </>}</span>
        </Skeleton>
      ),
    },
    {
      title: t('操作时间'),
      dataIndex: 'requestCreationTime',
      render: (text: string) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>
        </Skeleton>
      ),
    },
    {
      title: t('操作'),
      dataIndex: 'id',
      render: (text: string, record: ItemType) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          <a onClick={() => handleLook(record)}>查看</a>
        </Skeleton>
      ),
    },
  ];
  const pagenation: PaginationProps = {
    total: listData.total,
    current: searchForm.page,
    pageSize: searchForm.limit,
    onChange: (page: number, pageSize: number) => {
      setSearchForm({
        ...searchForm,
        page: page || 1,
        limit: pageSize || 20
      });
    },
  };
  
  return (
    <div className="page-body">
      <BreadCrumb name={t('事件中心')} />
      <div className="page-container">
        <div className="filter-container">
          <div className="filter-item">
            <RCTimePicker
              onChange={getTime}
              defVal={{ type: 'quickly', key: 'l24h', chinese: t('过去24小时') }}
            />
          </div>
          <div className="filter-item">
            <SearchInput placeholder={t('请输入用户名/姓名')} onSearch={handleSearch} />
          </div>
        </div>
        <div>
          所属模块：
          {dropdownSelect}
        </div>
        <div>
          <Table
            columns={columns}
            dataSource={listData.results}
            rowKey={record => record.id}
            pagination={pagenation}
          />
        </div>
        <EventDetail {...detailProps} onClose={handleCloseDetail} title={t('事件详情')} width={600} />
      </div>
    </div>
  );
};

export default EventCenterPage;
