import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { useState, useEffect } from 'react';
import { Input, message, Modal, Form, Button, Row, Col, Tabs, Avatar, Dropdown, Menu } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import BreadCrumb from '@/components/BreadCrumb';
import RCButtonWithLoading from '@/components/RCButtonWithLoading';
import RCDescriptions from '@/components/RCDescriptions';
import RCIcon from '@/common/Icon';
import request from '@/utils/request';
import MemberRoles from '@/components/MemberRoles';
// import MemberApplicationAuthorization from '@/components/MemberApplicationAuthorization';
// import MemberAccessKey from '@/components/MemberAccessKey';
import MemberEditHistory from '@/components/MemberEditHistory';
import { getStrLen } from '@/utils';
import { PHOHE_RULE, EMAIL_RULE } from '@/assets/contants';
import './index.less';

const MemberDetails = () => {
  const navigate = useNavigate();
  const pageParams = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [basicData, setBasicData] = useState({} as any);
  const [editModal, setEditModal] = useState(false);
  const [activeKey, setActiveKey] = useState('role');
  // 编辑历史
  const [historyModal, setHistoryModal] = useState(false);

  // 查询用户
  const getUserDetails = () => {
    const obj = {
      url: `/base/user-identity-management/api/v1/users/${pageParams.id}`,
      method: 'get',
    };
    request(obj)
      .then((res: any) => { setBasicData(res); })
      .catch(() => {});
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  // tabs切換
  const changeTabs = (key:string) => {
    setActiveKey(key);
  };

  // 编辑用户
  const editUserInfo = (item:any) => {
    if (item.key === 'user') {
      setEditModal(true);
    } else {
      setHistoryModal(true);
    }
  };

  const handleSubmit = (values:any) => {
    const body = {
      userName: values.userName,
      displayName: values.displayName,
      email: values.email,
      mobile: values.cellphone,
    };
    setLoading(true);
    const obj = {
      url: `/base/user-identity-management/api/v1/users/${pageParams.id}`,
      method: 'put',
      body
    };
    request(obj)
      .then(() => { setEditModal(false); getUserDetails(); message.success(t('更新用户信息成功')); setLoading(false); })
      .catch(() => { setLoading(false); });
  };

  const handleCancel = () => {
    setHistoryModal(false);
  };

  // 编辑用户
  const menu = (
    <Menu onClick={editUserInfo}>
      <Menu.Item key="user">
        <span>{ t('编辑用户') }</span>
      </Menu.Item>
      <Menu.Item key="history">
        <span>{ t('编辑历史') }</span>
      </Menu.Item>
    </Menu>
  );

  const title = (
    <div className="page-title-box">
      <div>{basicData.userName}</div>
      <div>
        <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
          <RCIcon type="icon-overflow" />
        </Dropdown>
      </div>
    </div>
  );

  return (
    <div className="page-body">
      <BreadCrumb name={<span>{title}</span>} showBack goBack={() => { navigate(-1); }} />
      <div className="page-container">
        <div>
          <Row justify="space-between" align="middle" className="member_info">
            <Col span={16}>
              <RCDescriptions
                title={t('基本信息')}
                column={1}
                data={basicData}
                items={[
                  { name: t('用户ID'), key: 'userId' },
                  { name: t('用户名'), key: 'userName' },
                  { name: t('用户姓名'), key: 'displayName' },
                  { name: t('手机号'), key: 'mobile' },
                  { name: t('电子邮箱'), key: 'email' },
                ]}
              />
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Avatar src="/static/avatar.svg" size={100} icon="user" />
            </Col>
          </Row>
        </div>
        <div className="line" />
        <div style={{ background: '#fff' }}>
          <Tabs activeKey={activeKey} onChange={changeTabs}>
            <Tabs.TabPane tab={t('角色')} key="role" />
            {/* <Tabs.TabPane tab={t('应用授权')} key="app" />
            <Tabs.TabPane tab="AccessKey" key="accesskey" /> */}
          </Tabs>
        </div>
        {activeKey === 'role' && <MemberRoles userId={pageParams.id} />}
        {/* {activeKey === 'app' && <MemberApplicationAuthorization userId={pageParams.id} />}
        {activeKey === 'accesskey' && <MemberAccessKey userId={pageParams.id} />} */}
      </div>
      <Modal
        visible={editModal}
        maskClosable={false}
        width={527}
        title={t('编辑用户详细信息')}
        closeIcon={<RCIcon type="icon-cancel" />}
        footer={null}
        style={{ textAlign: 'left' }}
        onCancel={() => { setEditModal(false); form.resetFields(); }}
      >
        <Form
          layout="horizontal"
          colon
          initialValues={{ ...basicData }}
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item
            name="displayName"
            label={<div className="add_style_text">{ t('姓名') }</div>}
            rules={[
            {
              required: true,
              min: 4,
              max: 30,
              message: <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入姓名') }</div>,
              validator: async (_:any, value:string) => {
                if (!value || !value.trim()) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入姓名') }</div>;
                  return Promise.reject();
                } else if (_.max < getStrLen(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />姓名不能超过{_.max}个字符</div>;
                  return Promise.reject();
                } else if (_.min > getStrLen(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />姓名不能少于{_.min}个字符</div>;
                  return Promise.reject();
                } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入正确的姓名') }</div>;
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
            extra={<div className="tag_tips"><RCIcon type="icon-promptfilled" className="mr-8" />4~30个字符,且不含特殊字符（1个汉字算2个字符）</div>}
          >
            <Input placeholder={t('请输入姓名')} allowClear autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="userName"
            label={<div className="add_style_text">{ t('用户名') }</div>}
            rules={[
            {
              required: true,
              min: 3,
              max: 45,
              message: <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入用户名') }</div>,
              validator: async (_:any, value:string) => {
                if (!value || !value.trim()) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入用户名') }</div>;
                  return Promise.reject();
                } else if (_.max < getStrLen(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />用户名不能超过{_.max}个字符</div>;
                  return Promise.reject();
                } else if (_.min > getStrLen(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />用户名不能少于{_.min}个字符</div>;
                  return Promise.reject();
                } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入正确的用户名') }</div>;
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
            extra={<div className="tag_tips"><RCIcon type="icon-promptfilled" className="mr-8" />3~45个数字、字母，不包含特殊字符</div>}
          >
            <Input disabled placeholder={t('请输入用户名')} allowClear autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="mobile"
            label={<div className="add_style_text"><span>{ t('手机号') }</span><span style={{ marginLeft: 8 }} className="tag_tips">{ t('选填') }</span></div>}
            rules={[
            {
              required: false,
              message: '',
              validator: async (_:any, value:string) => {
                if (value && value.trim() && !PHOHE_RULE.test(value.trim())) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入正确的手机号码') }</div>;
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
          >
            <Input placeholder={t('请输入手机号')} allowClear autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="email"
            label={<div className="add_style_text"><span>{ t('电子邮箱') }</span><span style={{ marginLeft: 8 }} className="tag_tips">{ t('选填') }</span></div>}
            rules={[
            {
              required: false,
              message: '',
              validator: async (_:any, value:string) => {
                if (value && value.trim() && !EMAIL_RULE.test(value.trim())) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入正确的电子邮箱') }</div>;
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
          >
            <Input placeholder={t('请输入手机号')} allowClear autoComplete="off" />
          </Form.Item>
          <Form.Item className="modal-btn-left">
            <Button type="default" style={{ marginRight: 15 }} onClick={() => { setEditModal(false); form.resetFields(); }}>{ t('取消') }</Button>
            <RCButtonWithLoading type="primary" htmlType="submit" loading={loading}>{ t('确认') }</RCButtonWithLoading>
          </Form.Item>
        </Form>
      </Modal>
      <MemberEditHistory userId={pageParams.id} showModal={historyModal} handleCancel={handleCancel} />
    </div>
  );
};

export default MemberDetails;
