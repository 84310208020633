
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import RCIcon from '@/common/Icon';
import './index.less';

type PropsType = {
  showBack?: boolean;
  goBack?: any;
  name?: string | ReactElement;
  actionRender?: ReactElement;
}
const BreadCrumb = (props: PropsType) => {
  const navigate = useNavigate();
  const handleBack = () => {
    if (props.goBack) {
      props.goBack();
    } else {
      navigate(-1);
    }
  };
  return (
    <div className="breadcrumb">
      {props.showBack && <RCIcon type="icon-back" className="back-icon" onClick={handleBack} />}
      <span className="bread_title">{props.name}</span>
      {
        props.actionRender && (
        <div className="actions-box">
          {props.actionRender}
        </div>
        )
      }
    </div>
  );
};

BreadCrumb.defaultProps = {
  showBack: false,
  goBack: undefined,
  name: '',
  actionRender: undefined
};

export default BreadCrumb;
