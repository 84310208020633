import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { useState, useEffect } from 'react';
import { Dropdown, Tooltip, Spin, Menu, List, Skeleton, Checkbox } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { getBLen } from '@/utils';
import RCIcon from '@/common/Icon';
import RCSearchInput from '../RCSearchInput';
import './index.less';

const RCSearchSelect = (props: any) => {
  const {
    value,
    placeholder,
    dataSource = [],
    loading,
    checkMode = true,
    keyValue,
    dataId = 'id',
    hasMore,
    showCombinedValue,
    displayQuantity = 10,
  } = props;

  const [visible, setVisible] = useState(false);
  const [showText, setShowText] = useState('' as any);
  const [searchText, seSearchText] = useState('');

  const triggerChange = (changedValue: any) => {
    const { onChange } = props;

    if (onChange) {
      onChange(changedValue);
    }
  };

  useEffect(() => {
    if (!checkMode) {
      setShowText(value);
    }
  }, [value]);

  // 設置打開下拉
  const handleChangeVisible = (isVisible: boolean) => {
    setVisible(isVisible);
    if (!isVisible) {
      seSearchText('');
    }
    if (props.handleClickVisible) {
      seSearchText('');
      props.handleClickVisible(isVisible);
    }
  };

  // 过滤
  const changeSearchValue = (e: any) => {
    seSearchText(e.target.value);
  };

  const clearValue = () => {
    seSearchText('');
    props.handleClearSearch('');
  };

  // 选择下拉选型
  const selectItme = (item: any) => {
    const { onSelect, backCombinedValue } = props;
    if (onSelect && !checkMode) {
      onSelect(item);
    }

    if (!checkMode) {
      let currentValue = item[keyValue];
      const showCurrentText = item[keyValue];

      if (backCombinedValue) {
        if (Array.isArray(backCombinedValue)) {
          currentValue = `${item[backCombinedValue[0]]}/${
            item[backCombinedValue[1]]
          }`;
        } else {
          currentValue = item[backCombinedValue];
        }
      }

      if (props.changeValue) {
        props.changeValue(currentValue);
      }

      triggerChange(currentValue);
      setShowText(showCurrentText);
      setVisible(true);
    }
  };

  const handleChecked = (e: any, item: any) => {
    const k = dataId || 'id';
    const list = Array.isArray(value) ? value : [];
    const onSelect = props.onSelect;
    let valueArr = [];

    if (e.target.checked) {
      valueArr = [...list, item];
    } else {
      valueArr = [...list].filter(key => key[k] !== item[k]);
    }
    // setActions(valueArr);
    if (onSelect) {
      onSelect(valueArr);
    }

    triggerChange(valueArr);
  };

  // 下拉菜单
  const menu = (
    <Menu className="menu_box">
      <div>
        <RCSearchInput
          className="search_input"
          placeholder={placeholder}
          value={searchText}
          onPressEnter={(e: any) => {
            e.stopPropagation();
            props.handleSearchRole(searchText);
          }}
          handleSearch={(e: any) => {
            e.stopPropagation();
            props.handleSearchRole(searchText);
          }}
          onChange={changeSearchValue}
          handleClearSearch={() => { clearValue(); }}
          autoComplete="off"
        />
      </div>
      <div
        className="list_box"
        style={{ maxHeight: dataSource.length ? displayQuantity * 32 : '' }}
      >
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={() => {
            if (props.handleInfiniteOnLoad) { props.handleInfiniteOnLoad(); }
          }}
          hasMore={!loading && hasMore}
          useWindow={false}
        >
          <List
            size="small"
            dataSource={dataSource}
            renderItem={(item: any) => (
              <List.Item onClick={() => { selectItme(item); }}>
                <Skeleton loading={loading} active paragraph={{ rows: 1 }} title={false}>
                  {checkMode && (
                    <Checkbox
                      key={item[dataId]}
                      checked={Array.isArray(value) && value?.some(
                        (k: any) => k[dataId] === item[dataId],
                      )}
                      onChange={(e: any) => { handleChecked(e, item); }}
                    >
                      <Tooltip title={item[keyValue]}>
                        <span>{getBLen(item[keyValue], 50)}</span>
                      </Tooltip>
                    </Checkbox>
                  )}
                  {!checkMode && (
                    <Tooltip title={item[keyValue]}>
                      <span>
                        {showCombinedValue
                          ? getBLen(`${item[showCombinedValue[0]]}(${item[showCombinedValue[1]]})`, 50)
                          : getBLen(item[keyValue], 50)}
                      </span>
                    </Tooltip>
                  )}
                </Skeleton>
              </List.Item>
            )}
          />
          {loading && hasMore && (
            <div className="list_spin">
              <Spin size="small" />
            </div>
          )}
        </InfiniteScroll>
      </div>
    </Menu>
  );
  return (
    <div id="search_select_box" className="search_select_box">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        visible={visible}
        onVisibleChange={handleChangeVisible}
        getPopupContainer={() => document.getElementById('search_select_box') as HTMLElement}
      >
        <div className="search_box" onClick={e => e.preventDefault()}>
          {checkMode &&
            (value && value?.length > 0 ? (
              <div className="search_box_text">
                {value?.map((k: any, i: any) => {
                  if (i < 3) {
                    return (
                      <div className="search_box_item" key={k[keyValue]}>
                        {getBLen(k[keyValue], 12)}
                      </div>
                    );
                  } else if (i === 3) {
                    return (
                      <div className="search_box_item" key="surplus">
                        + {value?.length - 3} ...
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            ) : (
              <div className="search_box_text" style={{ color: '#C8CED9' }}>
                {t('请选择')}
              </div>
            ))}
          {!checkMode &&
            (showText ? (
              <div className="search_box_text">
                <Tooltip title={showText}>
                  <span>{getBLen(showText, 60)}</span>
                </Tooltip>
              </div>
            ) : (
              <div className="search_box_text" style={{ color: '#C8CED9' }}>
                {t('请选择')}
              </div>
            ))}
          <RCIcon
            type="icon-arrow_down_line"
            className={visible ? 'search_icon_up' : 'search_icon_down'}
          />
        </div>
      </Dropdown>
    </div>
  );
};

export default RCSearchSelect;
