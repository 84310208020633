/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-22 14:28:34
 * @LastEditors: songone
 * @LastEditTime: 2022-12-22 16:09:18
 * @FilePath: \tide-user-center-web\src\pages\resource-management\details.tsx
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';

import RCDescriptions from '@/components/RCDescriptions';
import BreadCrumb from '@/components/BreadCrumb';
import { getResourceDetails } from '@/api/resource';

const ResourceDetailPage = () => {
  const { resourceId } = useParams();

  const [data, setData] = useState<any>({});
  const getDetails = async () => {
    if (!resourceId) {
      return;
    }
    try {
      const result = await getResourceDetails(resourceId);

      if (result && !result.error && result.id) {
        setData(result);
      } else {
        setData({});
      }
    } catch (error) {
      message.error(t('当前网络不稳定，请稍后重试'));
    }
  };
  useEffect(() => {
    getDetails();
  }, [resourceId]);
  const actionsItems =
      data && data?.actions
        ? data.actions.map((k: any, i: number) => (
          <div
            style={{
                marginTop: 32,
              }}
            key={`actions${i}`}
          >
            <RCDescriptions
              title={t('资源动作')}
              column={1}
              data={k}
              items={[
                  {
                    name: t('名称'),
                    key: 'name',
                  },
                  {
                    name: t('操作'),
                    key: 'code',
                  },
                ]}
            />
          </div>
        ))
        : null;
  return (
    <div className="page-body">
      <BreadCrumb name={t('资源管理')} showBack />
      <div className="page-container" >
        <div id="resource_details" className="resource_details">
          <div>
            <RCDescriptions
              title={t('基本信息')}
              column={1}
              data={data}
              items={[
                {
                  name: t('资源名称'),
                  key: 'name',
                },
                {
                  name: t('资源编码'),
                  key: 'code',
                },
              ]}
            />
          </div>
          <div className="desc">
            <div className="desc_title">{t('资源描述：')}</div>
            <div className="desc_content">
              {data.describe || (
              <span
                style={{
                    color: '#9EA5B2',
                  }}
              >
                {t('暂无')}
              </span>
              )}
            </div>
          </div>
          {actionsItems}
        </div>
      </div>
    </div>
  );
};

export default ResourceDetailPage;
