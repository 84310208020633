/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2023-01-03 16:58:12
 * @LastEditors: songone
 * @LastEditTime: 2023-01-03 18:00:39
 * @FilePath: \tide-user-center-web\src\components\common\useDropdownSelect\index.tsx
 */
import { Dropdown, Menu } from 'antd';
import { useState } from 'react';
import RCIcon from '@/common/Icon';

type ListItemType = {
  key: string;
  name: string;
}
const useDropdownSelect = (list: ListItemType[]) => {
  const [val, setVal] = useState<ListItemType>(list[0]);
  const menuOnclick = ({ key } : any) => {
    const valItem = list.find(item => item.key === key);
    if (valItem) { setVal(valItem); }
  };
  const menu = (
    <Menu onClick={menuOnclick}>
      {
        list.map(item => (
          <Menu.Item key={item.key}>{item.name}</Menu.Item>
        ))
      }
    </Menu>
  );
  const Dom = (
    <Dropdown overlay={menu}>
      <span>
        {val.name}
        <RCIcon type="icon-arrow_down_line" style={{ marginLeft: 12 }} />
      </span>
    </Dropdown>
  );
  return [val, Dom];
};

export default useDropdownSelect;
