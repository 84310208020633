/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-20 16:49:38
 * @LastEditors: songone
 * @LastEditTime: 2022-12-22 11:23:37
 * @FilePath: \tide-user-center-web\src\pages\orgs-management\detail.tsx
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton, Descriptions, Row, Col, Tag, message, Tabs } from 'antd';
import BreadCrumb from '@/components/BreadCrumb';
import RCIcon from '@/common/Icon';
import moment from 'moment';
import { getOrgDetail } from '@/api/org';

import RoleSelectWithSearch from './components/RoleSelectWithSearch';
import UserTable from './components/UserTable';
import DetailForm from './components/DetailForm';

import './index.less';

const OrgDetailPage: React.FC<{}> = () => {
  const { orgId } = useParams();
  const [loading, setLoading] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [companyDetails, setCompanyDetails] = useState<any>({});
  
  // 获取企业基本详情
  const getDetails = async () => {
    if (!orgId) {
      return null;
    }
    setLoading(true);
    try {
      const result = await getOrgDetail(orgId);

      if (result && !result.code && !result.error) {
        setCompanyDetails(result);
      } else {
        setCompanyDetails({});
      }
    } catch (err) {
      message.error(t('当前网络不稳定，请稍后重试'));
    }
    setLoading(false);
  };
  
  const handleEditInfo = () => {
    setEditVisible(true);
  };
  const handleCancelEdit = () => {
    setEditVisible(false);
  };
  const handleEditSuccess = () => {
    setEditVisible(false);
    getDetails();
  };
  useEffect(() => {
    getDetails();
  }, [orgId]);
  
  return (
    <div className="page-body">
      <BreadCrumb name={companyDetails?.orgName} showBack />
      <div className="page-container orgs-detail-page">
        <div id="company_details">
          <div className="company_details">
            <div className="company_info">
              <span className="company_text">
                {t('创建时间：')}
                {companyDetails.createAt ? moment(companyDetails.createAt).format('YYYY-MM-DD HH:mm:ss') : <span className="no_content">{t('暂无')}</span>}
              </span>
              <span
                className={'company_text'}
                style={{
                  marginLeft: 24
                }}
              >
                {t('企业ID：')}
                {companyDetails?.orgId || <span className="no_content">{t('暂无')}</span>}
              </span>
            </div>
            <div>
              {!editVisible && (
              <Skeleton
                active
                loading={loading}
                paragraph={{
                    rows: 5
                  }}
                title={false}
              >
                <Descriptions
                  column={{
                      xs: 1,
                      sm: 1,
                      md: 1,
                      xl: 3
                    }}
                  className="company_basicInfo"
                  title={
                    <Row justify="space-between">
                      <Col span={12}>{t('基本信息')}</Col>
                      <Col span={12} style={{ textAlign: 'right' }}>
                        <a
                          className="companyEdit"
                          onClick={() => {
                              handleEditInfo();
                            }}
                        >
                          <RCIcon
                            type="icon-bianji"
                            style={{
                                marginRight: 8
                              }}
                          />
                          {t('编辑信息')}
                        </a>
                      </Col>
                    </Row>
                    }
                >
                  <Descriptions.Item label={t('机构名称')}>
                    <span title={companyDetails?.orgName}>{companyDetails?.orgName || <span className="no_content">{t('暂无')}</span>}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('纳税识别号')}>
                    <span title={companyDetails?.orgName}>{companyDetails?.taxNumber || <span className="no_content">{t('暂无')}</span>}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('许可类企业')}>{companyDetails?.licensedEnterprise ? t('是') : t('否')}</Descriptions.Item>
                  <Descriptions.Item label={t('所属行业')}>
                    <span title={companyDetails?.industry}>{companyDetails?.industry || <span className="no_content">{t('暂无')}</span>}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('机构邮箱')}>
                    <span title={companyDetails?.email}>{companyDetails?.email || <span className="no_content">{t('暂无')}</span>}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('邮政编码')}>
                    <span title={companyDetails?.zipCode}>{companyDetails?.zipCode || <span className="no_content">{t('暂无')}</span>}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('机构电话')}>
                    <span title={companyDetails?.mobile}>{companyDetails?.mobile || <span className="no_content">{t('暂无')}</span>}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('机构微信')}>
                    <span title={companyDetails?.wechat}>{companyDetails?.wechat || <span className="no_content">{t('暂无')}</span>}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('机构传真')}>
                    <span title={companyDetails?.fax}>{companyDetails?.fax || <span className="no_content">{t('暂无')}</span>}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('机构来源')} span={1}>
                    {t('BaaS运营控制中心')}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('是否自动加入联盟')} span={2}>
                    {companyDetails?.autoJoinConsortia ? t('是') : t('否')}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('机构地址')} span={3}>
                    <span title={companyDetails?.address}>{companyDetails?.address || <span className="no_content">{t('暂无')}</span>}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('机构标签')} span={3}>
                    {companyDetails?.tags && (
                    <>
                      {companyDetails.tags.split(',').map((TD: string) => (
                        <Tag className="default_tag">{TD}</Tag>
                          ))}
                    </>
                      )}
                    {!companyDetails?.tags && <span className="no_content">{t('暂无')}</span>}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions title={t('机构描述')} className="company_description">
                  <Descriptions.Item >
                    { companyDetails?.describe || <span className="no_content">{t('暂无')}</span> }
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions title={t('机构备注')} className="company_description">
                  <Descriptions.Item >
                    { companyDetails?.remark || <span className="no_content">{t('暂无')}</span> }
                  </Descriptions.Item>
                </Descriptions>
              </Skeleton>
              )}
              {editVisible && (
              <DetailForm
                detail={companyDetails}
                orgId={orgId}
                onCancel={handleCancelEdit}
                onSuccess={handleEditSuccess}
              />
              )}
              {/* {showEditInfo !== 'block' && (
              <Skeleton
                active
                loading={loading}
                paragraph={{
                    rows: 5
                  }}
                title={false}
              >
                <Descriptions
                  style={{
                      marginTop: 32
                    }}
                  column={{
                      xs: 1,
                      sm: 1,
                      md: 1,
                      xl: 3
                    }}
                  title={
                    <Row justify="space-between">
                      <Col span={12}>{t('资源限制')}</Col>
                      <Col
                        span={12}
                        style={{
                            textAlign: 'right'
                          }}
                      >
                        <a
                          className="companyEdit"
                          onClick={() => {
                              handleEditInfo('block');
                            }}
                        >
                          <RCIcon
                            type="icon-bianji"
                            style={{
                                marginRight: 8
                              }}
                          />
                          {t('编辑信息')}
                        </a>
                      </Col>
                    </Row>
                    }
                >
                  <Descriptions.Item label={t('可创建联盟数量')}>{companyDetails?.consortiaQuantity || 0}</Descriptions.Item>
                  <Descriptions.Item label={t('可创建应用数量')}>{companyDetails?.applicationQuantity || 0}</Descriptions.Item>
                  <Descriptions.Item label={t('可拥有节点数量')}>{companyDetails?.peerQuantity || 0}</Descriptions.Item>
                </Descriptions>
              </Skeleton>
              )} */}
            </div>
            <div className="company_adminInfo">
              <h4>{t('管理员信息')}</h4>
              <div className="admin_info_box">
                <Row justify="start" align="middle">
                  <Skeleton
                    active
                    loading={loading}
                    avatar
                    paragraph={{
                      rows: 2
                    }}
                    title={false}
                  >
                    <Col className="admin_info_img">
                      <img src="/static/images/avatar.svg" alt="" />
                    </Col>
                    <Col>
                      <div className="admin_info_name">
                        <span>
                          {companyDetails?.userVo?.displayName || companyDetails?.userVo?.userName || <span className="no_content">{t('暂无')}</span>}
                        </span>
                        <Tag className="admin_tag">{t('管理员')}</Tag>
                        <div id="select_box" className="select_box">
                          <RoleSelectWithSearch orgId={orgId} userId={companyDetails?.userVo?.userId} />
                          {/* <TreeSelectWithSearch
                            ele="select_box"
                            loading={loadingRole}
                            queryLoading={queryLoading}
                            handleSearchRole={handleSearchRole}
                            handleClearSearch={handleClearSearch}
                            handleCheckedRole={handleCheckedRole}
                            handleCloseTags={handleCloseTags}
                            handleClickVisible={handleClickVisibleRole}
                            tableData={toJS(roleList)}
                            selectRoleList={toJS(selectRoleList)}
                            checkList={toJS(checkList)}
                          /> */}
                        </div>
                      </div>
                      <span
                        className="admin_info_concact"
                        style={{
                          marginRight: 30
                        }}
                      >
                        {t('电话：')}
                        {companyDetails?.userVo?.mobile || <span className="no_content">{t('暂无')}</span>}
                      </span>
                      <span className="admin_info_concact">
                        {t('邮箱：')}
                        {companyDetails?.userVo?.email || <span className="no_content">{t('暂无')}</span>}
                      </span>
                    </Col>
                  </Skeleton>
                </Row>
                {/* <div className={styles.replace_admin}>
                 <Dropdown
                   overlay={menuDrop}
                   getPopupContainer={() =>
                     document.getElementById('company_details')
                   }
                   placement="bottomRight"
                 >
                   <RCIcon type="icon-overflow" />
                 </Dropdown>
                </div> */}
              </div>
            </div>
          </div>
          <div className="company_table">
            <Tabs>
              <Tabs.TabPane tab={t('成员')} key="item-1">
                <div className="company_table_css">
                  <UserTable orgId={orgId} />
                </div>
              </Tabs.TabPane>
            </Tabs>;
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgDetailPage;
