import ReactDOM from 'react-dom';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import ContextProvider from '@/store/index';
import { getMenuTree, getCurrentUserInfo, transformUrl, setAppInfo } from '@/utils';
import { updateLocale } from '@rootcloud/rc-i18n-cli/hooks';

// import { getToken, getRefreshToken } from '@/utils/auth';
import { MENUDATA } from '@/common/menu';
import App from './App';
import reportWebVitals from './reportWebVitals';

// 非开发环境访问登录页时需要去到平台登录
const userInfo = getCurrentUserInfo();
// const route = window.location.pathname;
// if (route.indexOf('/login') > -1 && process.env.NODE_ENV !== 'development') {
//   clearLogin();
//   if (window.parent) {
//     const url = window.parent.location.origin;
//     window.parent.location.replace(`${url}/login`);
//   }
// }
const menu = getMenuTree('-1', MENUDATA, [], 'MENU');

const langConfig = {
  'zh-CN': require('../public/static/locales/zh-CN/common.json'),
  en: require('../public/static/locales/en/common.json')
};
const urlParams = transformUrl(window.location.search);

const lang: 'zh-CN' | 'en' = urlParams.lang || 'zh-CN';
setAppInfo({
  appId: urlParams.app || 'baas-platform',
  tenantId: urlParams?.tenant || 'baas-platform',
  lang
});
const currentConfig = langConfig[lang];

updateLocale(currentConfig);

if (window.top && window.top !== window) {
  window.top.postMessage({ type: 'message', data: '加载完成' }, '*');
}

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <ContextProvider>
      <App menu={menu} userInfo={userInfo} />
    </ContextProvider>
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
