import { Button } from 'antd';
import RCIcon from '@/common/Icon';

const RCButtonWithLoading = (props:any) => {
  const { children, ...other } = props;
  delete other.loading;
  return (
    <Button {...other} disabled={props.loading || props.disabled}>
      {props.loading && <RCIcon type="icon-loading" spin />}
      {children}
    </Button>
  );
};

export default RCButtonWithLoading;
