/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-21 16:26:20
 * @LastEditors: songone
 * @LastEditTime: 2022-12-22 10:49:19
 * @FilePath: \tide-user-center-web\src\pages\orgs-management\components\DetailForm\index.tsx
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { Form, Row, Col, Input, Button, Select, Tag, message } from 'antd';
import RCIcon from '@/common/Icon';
import { getStrLen } from '@/utils';
import { useEffect, useState } from 'react';
import { updateOrgInfo } from '@/api/org';

// const ErrorMessage = (message: string) => (
//   <div className="err_tips_ab">
//     <RCIcon
//       type="icon-Mainwarningsfilled"
//       className="mr-8"
//     />
//     {message}
//   </div>
// );

type PropsType = {
  onCancel?: any;
  onSuccess?: any;
  detail: any;
  orgId?: string;
}

const DetailForm = (props: PropsType) => {
  const [form] = Form.useForm();
  const { orgId, detail } = props;
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleFinish = async (values: any) => {
    const params = {
      orgName: values.orgName?.trim(),
      taxNumber: values.taxNumber?.trim() || undefined,
      industry: values.industry?.trim() || undefined,
      email: values.email?.trim() || undefined,
      zipCode: values.zipCode?.trim() || undefined,
      mobile: values.mobile?.trim() || undefined,
      wechat: values.wechat?.trim() || undefined,
      fax: values.fax?.trim() || undefined,
      address: values.address?.trim() || undefined,
      // source: values.origin || undefined,
      tags: tags.join(','),
      describe: values.describe?.trim() || undefined,
      remark: values.remark?.trim() || undefined,
      autoJoinConsortia: values.autoJoinConsortia,
      licensedEnterprise: values.licensedEnterprise
    };

    // if (values.autoJoinConsortia !== undefined) {
    //   params.autoJoinConsortia = values.autoJoinConsortia === 'true';
    // } else {
    //   params.autoJoinConsortia = formValue.autoJoinConsortia;
    // }

    // if (values.license !== undefined) {
    //   params.licensedEnterprise = values.license === 'true';
    // } else {
    //   params.licensedEnterprise = formValue.licensedEnterprise;
    // }

    setLoading(true);
    
    const result = await updateOrgInfo(orgId || '', params);

    if (result && !result.code && !result.error) {
      message.success(t('更新机构信息成功'));
      if (props.onSuccess) {
        props.onSuccess();
      }
    } else {
      message.error(result.message || t('更新机构信息失败'));
    }
  };
  const addTags = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e: any) => {
    if (e.target?.value) {
      setInputValue(e.target.value);
    } else {
      setInputValue('');
    }
  };

  // 输入标签确认
  const handleInputConfirm = () => {
    let resultTags = [...tags];

    if (!inputValue) {
      message.warning(t('标签内容不能为空'));
      resultTags = [...tags];
    }
    const val = inputValue.trim();

    // eslint-disable-next-line
    const len = inputValue.trim().replace(/[^\x00-\xff]/g, '00').length;

    if (len > 16) {
      message.warning(t('标签最长不能超过16个字符'));
    }

    if (inputValue.trim() && !tags.includes(val)) {
      resultTags = [...tags, inputValue.trim()];
    }

    if (tags.length > 5) {
      message.warning(t('标签最多输入5个！'));
    }
    setInputVisible(false);
    setTags(resultTags);
    setInputValue('');
  };

  // 删除标签
  const handleClose = (removedTag: string) => {
    const resultTags = tags.filter(tag => tag !== removedTag);
    setTags(resultTags);
  };

  useEffect(() => {
    form.setFieldsValue({
      ...detail
    });
    if (detail.tags) {
      setTags(detail.tags);
    }
  }, [detail]);

  return (
    <div className="company_adminInfo">
      <h4>
        {t('基本信息')}
      </h4>
      <Form onFinish={handleFinish} form={form} className="rc_form_h">
        <Row gutter={24}>
          <Col lg={8} md={24} sm={24} xs={24} key="orgName">
            <Form.Item
              label={
                <div className="add_style_text">
                  {t('机构名称')}
                </div>
                    }
              rules={
                      [
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.reject(new Error(t('请输入机构名称')));
                            }
                            if (getStrLen(value) > 60) {
                              return Promise.reject(new Error(t('机构名称不能超过60个字符')));
                            }
                            if (getStrLen(value) < 4) {
                              return Promise.reject(new Error(t('机构名称不能少于4个字符')));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]
                    }
              name="orgName"
            >
              <Input placeholder={t('请输入机构名称')} allowClear />
            </Form.Item>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} key="payTaxes">
            <Form.Item
              label={
                <div className="add_style_text">
                  {t('纳税识别号')}
                </div>
                    }
              name="taxNumber"
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(new Error(t('请输入纳税识别号')));
                    }
                    if (getStrLen(value) < 15 ||
                    /[\u4E00-\u9FA5]/.test(value) ||
                    getStrLen(value) > 20) {
                      return Promise.reject(new Error(t('请输入正确的企业纳税识别号')));
                    }
                    return Promise.resolve();
                  },
                }),
                    ]}
            >
              <Input
                placeholder={t('请输入企业纳税识别号')}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} key="license">
            <div
              id="license_type"
              style={{
                      position: 'relative',
                    }}
            >
              <Form.Item
                label={
                  <div className="add_style_text">
                    {t('许可类企业')}
                  </div>
                      }
                name="licensedEnterprise"
              >
                <Select
                  placeholder={t('请选择')}
                  suffixIcon={<RCIcon type="icon-arrow_down_line" />}
                >
                  <Select.Option value>{t('是')}</Select.Option>
                  <Select.Option value={false}>{t('否')}</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} key="industry">
            <Form.Item
              label={
                <div className="add_style_text">
                  {t('所属行业')}
                </div>
                    }
              name="industry"
            >
              <Input placeholder={t('请输入所属行业')} allowClear />
            </Form.Item>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} key="email">
            <Form.Item
              label={
                <div className="add_style_text">
                  {t('机构邮箱')}
                </div>
                    }
              name="email"
            >
              <Input placeholder={t('请输入机构邮箱')} allowClear />
            </Form.Item>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} key="postal">
            <Form.Item
              label={
                <div className="add_style_text">
                  {t('邮政编码')}
                </div>
                    }
              name="zipCode"
            >
              <Input placeholder={t('请输入邮政编码')} allowClear />
            </Form.Item>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} key="phone">
            <Form.Item
              label={
                <div className="add_style_text">
                  {t('机构电话')}
                </div>
                    }
              name="mobile"
            >
              <Input placeholder={t('请输入机构电话号码')} allowClear />
            </Form.Item>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} key="wx">
            <Form.Item
              label={
                <div className="add_style_text">
                  {t('机构微信')}
                </div>
                    }
              name="wechat"
            >
              <Input placeholder={t('请输入机构微信')} allowClear />
            </Form.Item>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} key="autoJoinConsortia">
            <div
              id="autoJoinConsortia"
              style={{
                      position: 'relative',
                    }}
            >
              <Form.Item
                label={
                  <div className="add_style_text">
                    {t('是否自动加入联盟')}
                  </div>
                      }
                name="autoJoinConsortia"
              >
                <Select
                  placeholder={t('请选择')}
                  suffixIcon={<RCIcon type="icon-arrow_down_line" />}
                >
                  <Select.Option value>{t('是')}</Select.Option>
                  <Select.Option value={false}>{t('否')}</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} key="fax">
            <Form.Item
              label={
                <div className="add_style_text">
                  {t('机构传真')}
                </div>
                    }
              name="fax"
            >
              <Input placeholder={t('请输入机构传真')} allowClear />
            </Form.Item>
          </Col>
          <Col span={24} key="tags">
            <Form.Item
              label={
                <div className="add_style_text">
                  {t('机构标签')}
                </div>
                    }
              name="tags"
            >
              <>
                <span>
                  {tags?.map(item => (
                    <Tag
                      closable
                      onClose={() => {
                                handleClose(item);
                              }}
                      key={item}
                    >
                      {item}
                    </Tag>
                          ))}
                </span>
                {inputVisible && (
                  <Input
                    type="text"
                    size="small"
                    autoComplete="off"
                    style={{
                              width: 78,
                              borderRadius: 12,
                              cursor: 'pointer',
                            }}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                  />
                        )}
                {!inputVisible && tags?.length < 5 && (
                  <Tag
                    style={{
                              borderStyle: 'dashed',
                            }}
                    onClick={addTags}
                    className="site-tag-plus"
                  >
                    {t('添加标签')}
                  </Tag>
                        )}
              </>
            </Form.Item>
          </Col>
                
          <Col lg={16} md={24} sm={24} xs={24} key="address">
            <Form.Item
              label={
                <div className="add_style_text">
                  {t('机构地址')}
                </div>
                    }
              name="address"
            >
              {/* <Form.Item label={<div className={formStyles.add_style_text} style={{ color: 'transparent', userSelect: 'none' }}>地址详情</div>}> */}
              <Input placeholder={t('请输入机构详情地址')} allowClear />
            </Form.Item>
          </Col>
          <Col span={16} key="org_description">
            <Form.Item
              label={
                <div className="add_style_text">
                  {t('机构描述')}
                </div>
                    }
              name="describe"
            >
              <Input.TextArea
                autoSize={{
                          maxRows: 6,
                          minRows: 4,
                        }}
                placeholder={t('请输入机构描述，200字以内')}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={16} key="org_mark">
            <Form.Item
              label={
                <div className="add_style_text">
                  {t('机构备注')}
                </div>
                    }
              name="remark"
            >
              <Input.TextArea
                autoSize={{
                          maxRows: 6,
                          minRows: 4,
                        }}
                placeholder={t('请输入机构备注，200字以内')}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={24} key="btn">
            <Form.Item
              style={{
                  textAlign: 'right',
                }}
            >
              <Button
                style={{
                    marginRight: 15,
                  }}
                onClick={() => {
                    if (props.onCancel) {
                      props.onCancel();
                    }
                  }}
              >
                {t('取消')}
              </Button>
              <Button
                type="primary"
                loading={loading}
                htmlType="submit"
              >
                {t('保存')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

DetailForm.defaultProps = {
  onCancel: undefined,
  onSuccess: undefined,
  orgId: undefined
};

export default DetailForm;
