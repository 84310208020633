import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { Button, Table } from 'antd';
import RCIcon from '@/common/Icon';
import RCButtonWithLoading from '@/components/RCButtonWithLoading';
import './index.less';

const RCTransfer = (props:any) => {
  const {
    search,
    loading,
    leftTableData,
    leftTableColumns,
    leftTotal,
    leftPage,
    changeLeftPageCurrent,
    rightTableData,
    rightTableColumns,
    rowSelection,
  } = props;
  const ht = search ? { height: 486 } : { height: 438 };
  const rightPageSize = search ? 10 : 8;
  const rightScroll: any = search ? { y: rightTableData.length > 10 ? 336 : false } : { y: rightTableData.length > 8 ? 290 : false };
  const localeLeft = {
    emptyText: (
      <div className="no_data_css" style={{ height: 255 }}>
        <div className="box_position">
          <div style={{ marginBottom: 10 }}>
            <img src="/static/table-default.svg" width="120" height="120" alt="" />
          </div>
          <span>{ t('暂无数据') }</span>
        </div>
      </div>
    ),
  };
  const localeRight = {
    emptyText: (
      <div className="no_data_css" style={search ? { height: 304 } : { height: 256 }}>
        <div className="box_position">
          <div style={{ marginBottom: 10 }}>
            <img src="/static/table-default.svg" width="120" height="120" alt="" />
          </div>
          <span>{ t('暂无数据') }</span>
        </div>
      </div>
    ),
  };
  return (
    <div style={ht}>
      <div className="rc_transfer">
        {/* 左 */}
        <div className="rc_transfer_table">
          <div className="rc_transfer_title"> 待选择（共 {leftTableData.length} 个）</div>
          {search}
          <Table
            rowKey={record => record.id}
            rowSelection={rowSelection}
            columns={leftTableColumns}
            dataSource={leftTableData}
            style={{ borderTop: '1px solid #eee' }}
            locale={localeLeft}
            pagination={
              leftTotal > 8
                ? {
                    showTotal: total => `共${total}条`,
                    current: leftPage,
                    pageSize: 8,
                    total: leftTotal,
                    size: 'small',
                    onChange: changeLeftPageCurrent,
                  }
                : false
            }
          />
        </div>
        {/* 中间 */}
        <div className="rc_transfer_icon" style={ht}>
          <div className="rc_icon">
            <RCIcon type="icon-arrow_right_line" />
          </div>
          <div className="rc_icon rc_icon_left">
            <RCIcon type="icon-arrow_right_line" />
          </div>
        </div>
        {/* 右 */}
        <div className="rc_transfer_table right_table">
          <div className="rc_transfer_title"> 已选择（共 {rightTableData.length} 个）</div>
          <Table
            rowKey={record => record.id}
            columns={rightTableColumns}
            dataSource={rightTableData}
            style={{ borderTop: '1px solid #eee' }}
            locale={localeRight}
            scroll={rightScroll}
            pagination={
              rightTableData.length > rightPageSize
                ? {
                    showTotal: (total: any) => `共${total}条`,
                    pageSize: rightPageSize,
                    total: rightTableData.length,
                    size: 'small',
                  }
                : false
            }
          />
        </div>
      </div>

      <div className="rc_transfer_btn">
        <Button type="default" style={{ marginRight: 16 }} onClick={() => props.handleCancel()}>
          {t('取消')}
        </Button>
        <RCButtonWithLoading type="primary" onClick={() => props.handleComfirm()} loading={loading}>
          {t('确定')}
        </RCButtonWithLoading>
      </div>
    </div>
  );
};

export default RCTransfer;
