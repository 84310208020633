/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-11-04 15:39:31
 * @LastEditors: songone
 * @LastEditTime: 2023-01-03 18:10:26
 * @FilePath: \tide-user-center-web\src\api\event.ts
 */
import request from '@/utils/request';

export const getEventList = (tenantId: any, params: any) => request({
  url: `/base/event-hubs-service/api/v1/events/${tenantId}/list`,
  method: 'get',
  params
});

export const getEventDetail = (tenantId: string, id: string) => request({
  url: `/base/event-hubs-service/api/v1/events/${tenantId}/${id}`,
  method: 'get'
});
