/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-26 13:36:39
 * @LastEditors: songone
 * @LastEditTime: 2022-12-26 20:06:37
 * @FilePath: \tide-user-center-web\src\pages\menus-management\create.tsx
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Form, Col, Button, Divider, Input, message } from 'antd';
import RCIcon from '@/common/Icon';
import BreadCrumb from '@/components/BreadCrumb';
import pinyin from 'js-pinyin';
import { transformUrl } from '@/utils';
import { createMenu } from '@/api/menus';

import PolicySelectWithSearch from './components/PolicySelectWithSearch';

const CreateMenuPage = () => {
  const {
    type, parent, parentName, parentCode, sort
  } = transformUrl();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageName, setPageName] = useState(t('菜单'));

  // 生成资源编码
  const onNameChange = ({ target: { value } } : { target: any }) => {
    const str = pinyin.getFullChars(value.trim());
    const code = parentCode ? `${parentCode}-${str}` : str;
    form.setFieldsValue({ code });
  };
  const handleFinish = async (values: any) => {
    setLoading(true);
    const params = {
      name: values.name?.trim(),
      code: values.code,
      path: values.path,
      sort: values.sort,
      type,
      parentId: parent || '-1',
      icon: values?.icon,
      describe: values?.describe,
      policies: (values?.policies && values?.policies?.length > 0) ? values.policies.map((k: any) => k.id) : []
    };
    try {
      const result = await createMenu(params);

      if (result && result.id && !result.error) {
        message.success(t('创建成功'));
        navigate(-1);
      } else if (result.message) {
        message.warning(result.message);
      } else {
        message.warning(t('创建失败'));
      }
    } catch (error) {
      message.error(t('当前网络不稳定，请稍后重试'));
    }
    setLoading(false);
  };
  useEffect(() => {
    if (type === 'BUTTON') {
      setPageName(t('按钮'));
    } else {
      setPageName(parent ? t('子菜单') : t('菜单'));
    }
    form.setFieldsValue({
      sort,
      parentName: parentName ? decodeURIComponent(parentName) : undefined
    });
  }, [type, parent, sort]);
  const back = () => {
    navigate(-1);
  };
  return (
    <div className="page-body">
      <BreadCrumb
        name={t('创建') + (pageName || '')}
        showBack
      />
      <div className="page-container create_menus" >
        <Row justify="center">
          <Form
            form={form}
            style={{
                width: '100%',
              }}
            className="rc_form"
            onFinish={handleFinish}
          >
            <Row justify="center" align="top">
              <Col
                style={{
                    marginRight: 105,
                  }}
              >
                <div className="title">{t('资源录入')}</div>
              </Col>
              <Col>
                <div
                  style={{
                      width: 478,
                    }}
                >
                  <Form.Item
                    label={
                      <div className="add_style_text">
                        {pageName}
                        {t('名称')}
                      </div>
                      }
                    name="name"
                    extra={
                      <div className="tag_tips">
                        <RCIcon
                          type="icon-promptfilled"
                          style={{ marginRight: 8 }}
                        />
                        {t('4～60个字符（1个汉字算2个字符）')}
                      </div>
                  }
                  >
                    <Input
                      placeholder={t('请输入{slot0}名称', { slot0: pageName })}
                      autoComplete="off"
                      onChange={onNameChange}
                    />
                  
                  </Form.Item>
                  {parent && (
                  <Form.Item
                    label={
                      <div className="add_style_text">{t('父级菜单')}</div>
                        }
                    name="parentName"
                  >
                    <Input
                      disabled
                      placeholder={t('请输入父级菜单')}
                      autoComplete="off"
                    />
                  </Form.Item>
                    )}

                  <Form.Item
                    label={
                      <div className="add_style_text">
                        {t('路由')}
                        <span
                          className="tag_tips"
                          style={{ marginLeft: 8 }}
                        >
                          {t('选填')}
                        </span>
                      </div>
                      }
                    name="path"
                  >
                    <Input
                      placeholder={t('请输入路由')}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item
                    label={<div className="add_style_text" >{t('资源编码')}</div>}
                    name="code"
                  >
                    <Input
                      disabled
                      placeholder={t('请输入资源编码')}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item
                    label={<div className="add_style_text" >{t('排序')}</div>}
                    name="sort"
                  >
                    <Input
                      disabled
                      placeholder={t('请输入序号')}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <div className="add_style_text">
                        {t('图标')}
                        <span
                          className="tag_tips"
                          style={{ marginLeft: 8 }}
                        >
                          {t('选填')}
                        </span>
                      </div>
                      }
                    name="icon"
                  >
                    <Input
                      placeholder={t('请输入图标')}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <div className="add_style_text">
                        {t('菜单描述')}
                        <span
                          className="tag_tips"
                          style={{ marginLeft: 8 }}
                        >
                          {t('选填')}
                        </span>
                      </div>
                      }
                    name="describe"
                  >
                    <Input.TextArea
                      placeholder={t('请填写菜单描述信息，限200字符。')}
                      autoComplete="off"
                      style={{ height: 90 }}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Divider />
            <Row justify="center" align="top">
              <Col style={{ marginRight: 105 }}>
                <div className="title">{t('关联策略')}</div>
              </Col>
              <Col>
                <div style={{ width: 478 }}>
                  <div className="tips">
                    {t('策略是用于定义和描述一条或多条权限的集合，您可以根据管理需求添加一条或多条策略')}
                  </div>
                  {/* 与策略绑定 */}
                  <Form.Item
                    label={
                      <div className="add_style_text">
                        {t('策略')}
                        <span
                          className="tag_tips"
                          style={{ marginLeft: 8 }}
                        >
                          {t('选填')}
                        </span>
                      </div>
                      }
                    name="policies"
                  >
                    <PolicySelectWithSearch />
                  </Form.Item>
                  <Form.Item style={{ textAlign: 'right', margin: '32px 0 100px' }}>
                    <Button
                      onClick={back}
                      style={{ marginRight: 16 }}
                    >
                      {t('取消')}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      {t('确定')}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </Row>
      </div>
    </div>
  );
};

export default CreateMenuPage;
