/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-11-01 17:50:50
 * @LastEditors: songone
 * @LastEditTime: 2023-01-04 17:17:19
 * @FilePath: \tide-user-center-web\src\components\RCTimePicker\index.tsx
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Input, List, Select, InputNumber, Button, message, DatePicker } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import RCIcon from '@/common/Icon';
import { menuList } from './config';

import './index.less';

const RangePicker:any = DatePicker.RangePicker;

type OptionsType = {
  showFont: string; // 显示在输入框的内容
  type: 'quickly' | 'dynamic' | 'static'; // 选择框菜单选择
  timeType: string; // 快捷选择类型的时间类型
  dynamicType: string; // 自定义动态时间的时间类型
  dynamicNumber: number; // 动态时间类型对应的参数
  pickerVis: boolean; // 日期选择框展示控制
}

type IPropsType = {
  onChange?: any;
  defVal?: any;
};
const RCTimePicker = (props: IPropsType) => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const [visible, setVisible] = useState(false);
  const [pickerVis, setPickerVis] = useState(false);
  const [options, setOptions] = useState<OptionsType>({
    showFont: '', // 显示在输入框的内容
    type: 'quickly', // 选择框菜单选择
    timeType: '', // 快捷选择类型的时间类型
    dynamicType: 'hr', // 自定义动态时间的时间类型
    dynamicNumber: 0, // 动态时间类型对应的参数
    pickerVis: false, // 日期选择框展示控制
  });
  const selectType = (val: any) => {
    setOptions({
      ...options,
      type: val.key,
      pickerVis: val.key === 'static'
    });
  };
  const hideAll = () => {
    setPickerVis(false);
    setVisible(false);
  };
  const changeTime = (val: any) => {
    // 需要先判断父组件传入的接收时间参数的方法是不是一个函数，不是则中断
    if (typeof props.onChange !== 'function') {
      message.error(t('接受时间参数的函数不存在或其不是一个函数！'));
      return false;
    }
    const { type, dynamicType, dynamicNumber } = options;
    let startTime = moment();
    let endTime = moment();
    if (type === 'quickly') {
      // 这里的val参数是一个包含key字段的对象
      
      if (val.key === 'l5m') {
        startTime = moment().subtract(5, 'm');
      } else if (val.key === 'l1h') {
        startTime = moment().subtract(1, 'h');
      } else if (val.key === 'l24h') {
        startTime = moment().subtract(24, 'h');
      } else if (val.key === 'l7d') {
        startTime = moment().subtract(7, 'd');
      } else if (val.key === 'l30d') {
        startTime = moment().subtract(30, 'd');
      } else if (val.key === 'td') {
        startTime = moment().startOf('day');
        endTime = moment().endOf('day');
      } else if (val.key === 'tw') {
        startTime = moment().startOf('week');
        endTime = moment().endOf('week');
      } else if (val.key === 'tm') {
        startTime = moment().startOf('month');
        endTime = moment().endOf('month');
      }
      props.onChange([startTime, endTime]);
      setOptions({
        ...options,
        timeType: val.key,
        showFont: val.chinese,
      });
    } else if (type === 'dynamic') {
      // 这里没有用到val参数
      let showFont = '';
      if (dynamicType === 'min') {
        if (dynamicNumber > 120) {
          message.error(t('超过上限120 (分钟)'));
          return;
        }
        showFont = `过去${dynamicNumber}分钟`;
        endTime = moment().subtract(dynamicNumber, 'm');
      } else if (dynamicType === 'hr') {
        if (dynamicNumber > 48) {
          message.error(t('超过上限48 (小时)'));
          return;
        }
        showFont = t('过去{{dynamicNumber}}小时', { dynamicNumber });
        endTime = moment().subtract(dynamicNumber, 'h');
      } else if (dynamicType === 'day') {
        if (dynamicNumber > 60) {
          message.error(t('超过上限60 (天)'));
          return;
        }
        showFont = t('过去{{dynamicNumber}}天', { dynamicNumber });
        endTime = moment().subtract(dynamicNumber, 'd');
      } else if (dynamicType === 'week') {
        if (dynamicNumber > 100) {
          message.error(t('超过上限100 (周)'));
          return;
        }
        showFont = t('过去{{dynamicNumber}}周', { dynamicNumber });
        endTime = moment().subtract(dynamicNumber, 'w');
      } else if (dynamicType === 'month') {
        if (dynamicNumber > 24) {
          message.error(t('超过上限24 (月)'));
          return;
        }
        showFont = t('过去{{dynamicNumber}}月', { dynamicNumber });
        endTime = moment().subtract(dynamicNumber, 'M');
      }
      setOptions({
        ...options,
        showFont,
      });
      props.onChange([endTime, moment()]);
    } else if (type === 'static') {
      // 这里的val参数是选择的时间时间范围
      setOptions({
        ...options,
        showFont: `${moment(val?.[0]).format(dateFormat)}~${moment(val?.[1]).format(dateFormat)}`,
      });
      props.onChange(val);
    }
    hideAll();
  };
  const showBox = (e: any) => {
    e.nativeEvent.stopImmediatePropagation();
    if (!visible) {
      setPickerVis(true);
      setVisible(true);
    }
  };
  useEffect(() => {
    document.onclick = () => {
      hideAll();
    };
    return () => {
      document.onclick = null;
    };
  }, []);
  useEffect(() => {
    if (props.defVal) {
      changeTime(props.defVal);
    }
  }, []);
  const {
    type, timeType, showFont, dynamicType, dynamicNumber,
  } = options;
  return (
    <div className="rc-time-picker" onClick={showBox}>
      <Input suffix={<CalendarOutlined />} placeholder={t('请选择')} value={showFont} />
      <div style={{ display: visible ? 'flex' : 'none' }} className="container">
        <List className="TimeSelectPickerList" split={false}>
          {
            menuList.map(val => <List.Item className={val.key === type ? 'TimeSelectPickerSelected' : ''} onClick={() => selectType(val)} extra={<RCIcon type="right" />} key={val.key}>{val.chinese}</List.Item>)
          }
        </List>
        {
          // 类型为快捷选择
          type === 'quickly' &&
          <List className="TimeSelectPickerList" split={false}>
            {
              menuList[0].children.map(val => <List.Item className={val.key === timeType ? 'TimeSelectPickerSelected' : ''} onClick={() => changeTime(val)} key={val.key}>{val.chinese}</List.Item>)
            }
          </List>
        }
        {
          // 类型为自定义动态时间
          type === 'dynamic' &&
          <div className="dynamicContainer">
            <div className="timeContainer">
              <span>{ t('过去') }</span>
              <InputNumber className="antInputNumber" precision={0} onChange={value => setOptions({ ...options, dynamicNumber: value })} min={0} value={dynamicNumber} />
              <Select
                getPopupContainer={triggerNode => triggerNode.parentNode}
                value={dynamicType}
                className="antSelect"
                onChange={e => setOptions({ ...options, dynamicType: e })}
              >
                {
                  menuList[1]?.children.map(val => (
                    <Select.Option key={val.english} value={val.english}>
                      {val.chinese}
                    </Select.Option>))
                }
              </Select>
            </div>
            <Button onClick={() => changeTime({})} disabled={!dynamicNumber} className="dynamicButton" type="primary">{ t('确认') }</Button>
          </div>
        }
        {
          // 类型为自定义静态时间
          type === 'static' &&
          <div className="staticBox">
            <RangePicker
              style={{ marginTop: 4 }}
              dateRender={(current: any) => (
                <div className="ant-calendar-date rangerPickerDatePanel">
                  {current.date()}
                </div>
              )}
              onOk={(time:any) => changeTime(time)}
              dropdownClassName="staticDatePicker"
              open={pickerVis}
              size="small"
              showTime
            />
          </div>
        }
      </div>
    </div>);
};
RCTimePicker.defaultProps = {
  onChange: () => {},
  defVal: undefined
};

export default RCTimePicker;
