/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-11-01 17:17:56
 * @LastEditors: songone
 * @LastEditTime: 2022-12-08 20:50:00
 * @FilePath: \tide-user-center-web\src\pages\message-center\index.tsx
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Form, Input, Table, TableColumnType, Skeleton, Tooltip, Row, Col, message, PaginationProps } from 'antd';
import RCIcon from '@/common/Icon';
import { getMessageList, updateMessageRead } from '@/api/message';
import { handleError, getBLen } from '@/utils';

import MessageDetail from './components/detail';


type ItemType = {
  id: string;
  content: string;
  read: boolean;

}

const MessageCenterPage: React.FC<{}> = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [detailProps, setDetailProps] = useState<{
    visible: boolean;
    item: ItemType | undefined;
  }>({
    visible: false,
    item: undefined
  });
  const [searchForm, setSearchForm] = useState<{
    sort: string;
    withTotal: boolean;
    page: number;
    limit: number;
    content: string;
  }>({
    sort: 'read,asc,createAt,desc',
    withTotal: true,
    page: 1,
    limit: 10,
    content: '',
  });
  const [listData, setListData] = useState<{
    results: ItemType[],
    total: number
  }>({
    results: [],
    total: 0
  });
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const getData = async () => {
    setLoading(true);
    try {
      const result = await getMessageList({
        ...searchForm,
      });
      setSelectedKeys([]);
      setListData(result);
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };
  const handleLook = async (record: ItemType) => {
    setDetailProps({
      ...detailProps,
      visible: true,
      item: record
    });
  };
  const handleCloseDetail = () => {
    setDetailProps({
      ...detailProps,
      visible: false,
      item: undefined
    });
  };
  const handleRead = async (type?: 'all') => {
    try {
      let ids = selectedKeys;
      if (type === 'all') {
        ids = listData.results.map(item => item.id);
      }
      await updateMessageRead(ids);
      message.success(t('操作成功'));
    } catch (error) {
      handleError(error);
    }
  };
  const handleDelete = async () => {
    try {
      const ids = selectedKeys;
      await updateMessageRead(ids);
      message.success(t('操作成功'));
    } catch (error) {
      handleError(error);
    }
  };
  
  useEffect(() => {
    getData();
  }, [searchForm]);
  const columns: TableColumnType<any>[] = [
    {
      title: t('消息内容'),
      dataIndex: 'content',
      render: (text: string, record: ItemType) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          <>
            <RCIcon
              style={{
                    color: record.read === true ? '#505363' : '#00B259',
                    fontSize: record.read === true ? 14 : 15,
                  }}
              type={record.read === true ? 'icon-mail-on' : 'icon-mail-off'}
            />
            <Tooltip title={text}>
              <span
                style={{
                      marginLeft: 16,
                      cursor: 'pointer'
                    }}
                onClick={() => handleLook(record)}
              >
                {getBLen(text, 100)}
              </span>
            </Tooltip>
          </>
        </Skeleton>
      ),
    },
    {
      title: t('接收时间'),
      dataIndex: 'createAt',
      width: 200,
      render: (text: string) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>
        </Skeleton>
      ),
    },
  ];
  const pagenation: PaginationProps = {
    total: listData.total,
    current: searchForm.page,
    pageSize: searchForm.limit,
    onChange: (page: number, pageSize: number) => {
      setSearchForm({
        ...searchForm,
        page: page || 1,
        limit: pageSize || 20
      });
    },
  };
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedKeys(selectedRowKeys);
    },
    selectedRowKeys: selectedKeys
  };
  return (
    <div className="page-container message-center">
      <Form form={form} layout="inline" className="filter-container">
        <Form.Item className="filter-item" name="" label={null}>
          <Input placeholder="请输入内容" suffix={<RCIcon type="icon-search" />} />
        </Form.Item>
      </Form>
      <Row
        justify="space-between"
        className="selected"
        style={{
                top: selectedKeys.length > 0 ? 0 : -62,
                height: selectedKeys.length > 0 ? 40 : 0,
              }}
      >
        <Col span={6}>
          <span>
            {t('已选')}
            <span
              style={{
                      color: '#00b259',
                    }}
            >
              {selectedKeys.length}
            </span>{' '}
            / {listData.results.length}
            {t('项')}
          </span>
        </Col>
        <Col
          span={18}
          style={{ textAlign: 'right', }}
        >
          <a
            style={{ marginRight: 23 }}
            onClick={() => handleRead}
          >
            {t('标记已读')}
          </a>
          <a
            style={{ marginRight: 23, }}
            onClick={() => handleRead('all')}
          >
            {t('全部标记已读')}
          </a>
          <span className="delete" onClick={handleDelete}>
            {t('删除')}
          </span>
        </Col>
      </Row>
      <div>
        <Table
          bordered
          columns={columns}
          dataSource={listData.results}
          rowKey={record => record.id}
          pagination={pagenation}
          rowSelection={rowSelection}
        />
      </div>
      <MessageDetail {...detailProps} onClose={handleCloseDetail} title={t('事件详情')} width={600} />
    </div>);
};

export default MessageCenterPage;
