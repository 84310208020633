import Cookies from 'js-cookie';
import { AUTH_TOKEN, REFRESH_TOKEN } from '@/assets/contants';

const expires = (3600 * 1000 * 24 * 7) - (3600 * 1000);
export const setToken = (token: string) => {
  Cookies.set(AUTH_TOKEN, token, { expires });
};

export const setRefreshToken = (token: string) => {
  Cookies.set(REFRESH_TOKEN, token, { expires });
};

export const getToken = () => window.sessionStorage.getItem(AUTH_TOKEN) || null;

export const getRefreshToken = () => window.sessionStorage.getItem(REFRESH_TOKEN) || null;

export const removeToken = () => window.sessionStorage.removeItem(AUTH_TOKEN);

export const removeRefreshToken = () => window.sessionStorage.removeItem(REFRESH_TOKEN);

export const setCurrentToken = (accessToken: string, refreshToken: string) => {
  window.sessionStorage.setItem(AUTH_TOKEN, accessToken);
  window.sessionStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const getCurrentToken = () => {
  const accessToken = window.sessionStorage.getItem(AUTH_TOKEN);
  const refreshToken = window.sessionStorage.getItem(REFRESH_TOKEN);
  return {
    accessToken,
    refreshToken
  };
};

