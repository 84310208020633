/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-22 14:16:12
 * @LastEditors: songone
 * @LastEditTime: 2022-12-22 15:37:41
 * @FilePath: \tide-user-center-web\src\api\resource.ts
 */
import request from '@/utils/request';

// 获取角色列表
export const getResourceData = (params: any) => request({
  url: '/base/user-identity-management/api/v1/resources',
  method: 'get',
  params
});

// 获取角色列表
export const getResourceDetails = (resourceId: string) => request({
  url: `/base/user-identity-management/api/v1/resources/${resourceId}`,
  method: 'get',
});

