import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useState, useEffect } from 'react';
import { Skeleton, Table, Tooltip, Dropdown, Menu, Input, Button, Modal, Row, Col, message } from 'antd';
import moment from 'moment';
import RCNoDataDisplay from '@/components/RCNoDataDisplay';
import RCButtonWithLoading from '@/components/RCButtonWithLoading';
import { getBLen } from '@/utils';
import RCIcon from '@/common/Icon';
import request from '@/utils/request';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../components/BreadCrumb';
import './index.less';

function PolicyManagement() {
  const navigate = useNavigate();
  const PAGEOBJ = {
    total: 0,
    current: 1,
    pageSize: 10
  };
  const [loading, setLoading] = useState(false);
  const [pageObj, setPageObj] = useState(PAGEOBJ);
  const [inputText, setInputText] = useState('');
  const [tableList, setTableList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState({} as any);

  // const alert = (
  //   <div className="alert_tips">
  //     <div>{t('策略（Policy）是描述一组权限集。BaaS平台使用一种简单的权限策略语法来对权限集进行描述。')}</div>
  //     <div>{t('目前支持两种类型的权限策略：由树根格致管理员管理的系统策略和由机构管理员管理的自定义策略。')}</div>
  //     <div>
  //       <span className="dot" />
  //       {t('系统策略，统一由树根格致管理员创建，您只能使用而不能修改，系统策略的版本更新树根格致管理员维护')}；
  //     </div>
  //     <div>
  //       <span className="dot" />
  //       {t('自定义策略，您可以自主创建、更新和删除，自定义策略的版本更新由您自己维护。')}
  //     </div>
  //   </div>
  // );

  // 请求用户列表
  const getPolicyList = () => {
    const params:any = {
      limit: pageObj.pageSize,
      page: pageObj.current,
      sort: 'createdAt,desc'
    };
    if (inputText) {
      params.name = inputText.trim();
    }
    const obj = {
      url: '/base/user-identity-management/api/v1/policies',
      method: 'get',
      params
    };
    setLoading(true);
    request(obj).then((res:any) => {
      const { results, total } = res;
      const o = { ...pageObj };
      o.total = total;
      setTableList(results);
      setPageObj(o);
      setLoading(false);
    }).catch(() => {
      setTableList([]);
      setPageObj(PAGEOBJ);
      setLoading(false);
    });
  };

  useEffect(() => { getPolicyList(); }, [pageObj.current, pageObj.pageSize]);

  // 分页
  const changePageSize = (page: any, pageSize: any) => {
    const o = { ...pageObj };
    o.current = 1;
    o.pageSize = pageSize;
    setPageObj(o);
  };
  
  const changePageCurrent = (page: any, pageSize: any) => {
    const o = { ...pageObj };
    o.current = page;
    o.pageSize = pageSize;
    setPageObj(o);
  };

  // 过滤
  const handleChangeInput = (e:any) => {
    setInputText(e.target.value);
  };

  // 搜索
  const handleSearch = () => {
    if (pageObj.current !== 1 || pageObj.pageSize !== 10) {
      setPageObj(PAGEOBJ);
    } else {
      getPolicyList();
    }
  };

  // 创建策略
  const handleAddPolicy = () => {
    navigate('../permission/policy-management/create');
  };

  // 刪除自定義策略
  const deletePolicy = (record:any) => {
    setDeleteRecord(record);
    setDeleteModal(true);
  };

  const confirmRemove = () => {
    setLoading(true);
    request({
      url: `/base/user-identity-management/api/v1/policies/${deleteRecord.id}`,
      method: 'delete',
    }).then(() => {
      message.success(t('删除成功'));
      getPolicyList();
      setPageObj({ ...PAGEOBJ, ...{ current: 1 } });
      setLoading(false);
      setDeleteModal(false);
    }).catch(() => {
      setLoading(false);
    });
  };

  const columns = [
    {
      title: t('策略名称'),
      dataIndex: 'name',
      key: 'name',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {text && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('创建人'),
      dataIndex: 'creator',
      key: 'creator',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { id: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('创建时间'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string, record: { id: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('策略类型'),
      dataIndex: 'type',
      key: 'type',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { id: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <span>{text === '0' ? t('预设策略') : t('自定义策略')}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('描述'),
      dataIndex: 'describe',
      key: 'describe',
      render: (text: string, record: { id: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <Tooltip title={text}><span>{getBLen(text, 20)}</span></Tooltip>}
        </Skeleton>
      ),
    },
    {
      title: t('操作'),
      dataIndex: 'action',
      key: 'aciton',
      width: 140,
      render: (text: any, record: any) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.id && (
            <>
              <a onClick={() => { navigate(`../permission/policy-management/${record.id}`); }}>{ t('查看') }</a>
              {record.type === '1' && (
                <Dropdown
                  overlay={
                    <Menu onClick={() => { deletePolicy(record); }}>
                      <Menu.Item key="1" style={{ minWidth: 90, textAlign: 'center' }}>
                        <span style={{ color: '#E60000' }}>{ t('删除') }</span>
                      </Menu.Item>
                    </Menu>
                  }
                  placement="bottomRight"
                >
                  <RCIcon type="icon-overflow" style={{ marginLeft: 20 }} />
                </Dropdown>
              )}
            </>
          )}
        </Skeleton>
      ),
    },
  ];
  return (
    <div className="page-body">
      <BreadCrumb name={t('策略管理')} />
      <div className="page-container">
        {/* <Alert description={alert} type="success" closable /> */}
        <div className="member-list" style={{ margin: '16px 0' }}>
          <Input className="member-list-search" placeholder={t('请输入策略名称')} allowClear onChange={handleChangeInput} suffix={<RCIcon type="icon-search" style={{ cursor: 'pointer' }} onClick={handleSearch} />} onPressEnter={handleSearch} />
          <Button type="primary" onClick={handleAddPolicy}>{ t('创建策略') }</Button>
        </div>
        <Table
          rowKey={record => record.id}
          columns={columns}
          locale={{ emptyText: <RCNoDataDisplay /> }}
          dataSource={tableList}
          pagination={
            pageObj.total > 10
                ? {
                    showQuickJumper: true,
                    showSizeChanger: true,
                    total: pageObj.total,
                    current: pageObj.current,
                    pageSize: pageObj.pageSize,
                    onShowSizeChange: changePageSize,
                    onChange: changePageCurrent,
                  }
                : false
            }
        />
        <Modal
          visible={deleteModal}
          maskClosable={false}
          width={428}
          closeIcon={<RCIcon type="icon-cancel" />}
          className="rc-modal"
          onCancel={() => { setDeleteModal(false); }}
          footer={null}
        >
          <Row>
            <Col span={24} className="rc-modal-icon"><RCIcon type="icon-Mainwarningsfilled" style={{ color: '#e60000' }} /></Col>
            <Col span={24} className="rc-modal-title">
              <span>{ t('您确定要删除策略“{{name}}”吗？', { ...deleteRecord }) }</span>
            </Col>
            <Col span={24} className="rc-modal-tip"><span>{ t('删除后，该策略会丢失，此操作不可撤销') }</span></Col>
            <Col span={24} className="rc-modal-btn">
              <Button type="default" style={{ marginRight: 16 }} onClick={() => { setDeleteModal(false); }}>{ t('取消') }</Button>
              <RCButtonWithLoading danger type="primary" onClick={confirmRemove} loading={loading}>{ t('删除') }</RCButtonWithLoading>
            </Col>
          </Row>
        </Modal>
      </div>
    </div>
  );
}

export default PolicyManagement;
