import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { useEffect, useState } from 'react';
import { Spin, Tree } from 'antd';
import request from '@/utils/request';
import './index.less';

const PermissionMenu = (props: any) => {
  const [dataList, setDataList] = useState([] as any);
  const [loadingMenu, setLoadingMenu] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([] as any);

  // 递归
  const setTree = (data: any) => {
    const map: any = {};
    const tree: any[] = [];
    // 生成数据对象集合
    data.forEach((item:any) => {
      map[item.id] = item;
    });
    data.forEach((item:any) => {
      const parent = map[item.parentId];

      if (parent) {
        if (!Array.isArray(parent.child)) parent.child = [];
        parent.child.push(item);
      } else {
        tree.push(item);
      }
    });
    return tree;
  };

  // 查询是否有选中的菜单
  const getCheckData = () => {
    request({
      url: `/base/user-identity-management/api/v1/roles/${props.roleId}/menu`,
      method: 'get',
    })
      .then((res: any) => {
        if (Array.isArray(res)) {
          setCheckedKeys(res);
        }
      })
      .catch(() => {
        setCheckedKeys([]);
      });
  };

  // 获取菜单
  const getMenusList = () => {
    setLoadingMenu(true);
    request({
      url: `/base/user-identity-management/api/v1/roles/user/${props.userId}/menu`,
      method: 'get',
    })
      .then((res: any) => {
        const arr = setTree(res);
        setDataList(arr);
        setLoadingMenu(false);
        getCheckData();
      })
      .catch(() => {
        setDataList([]);
        setLoadingMenu(false);
      });
  };

  useEffect(() => {
    getMenusList();
  }, []);

  // 设置菜单
  const handleCheck = (keys:any) => {
    const params = { menuIds: keys };
    setLoadingMenu(true);
    request({
      url: `/base/user-identity-management/api/v1/roles/${props.roleId}/menu`,
      method: 'patch',
      body: params
    })
      .then(() => {
        setLoadingMenu(false);
        getCheckData();
      })
      .catch(() => {
        setLoadingMenu(false);
      });
  };

  // 菜单dom渲染
  const treeItme = (data: any) =>
    data.map((item: any) => {
      const title = (
        <div>
          <span>{item.name}</span>
          {item.type === 'MENU' && <span className="tree_tag green">{ t('菜单') }</span>}
          {item.type === 'BUTTON' && (
            <span className="tree_tag blue">{ t('按钮') }</span>
          )}
        </div>
      );

      if (item.child && item.child.length) {
        return (
          <Tree.TreeNode key={item.id} title={title}>
            {treeItme(item.child)}
          </Tree.TreeNode>
        );
      }

      return <Tree.TreeNode key={item.id} title={title} />;
    });

  return (
    <div id="roles-menu" className="menu_container">
      <div className="container">
        <Spin spinning={loadingMenu}>
          <Tree
            checkable
            blockNode
            onCheck={handleCheck}
            checkedKeys={checkedKeys}
          >
            {treeItme(dataList)}
          </Tree>
        </Spin>
      </div>
    </div>
  );
};

export default PermissionMenu;
