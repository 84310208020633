/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-22 11:25:26
 * @LastEditors: songone
 * @LastEditTime: 2022-12-22 15:12:58
 * @FilePath: \tide-user-center-web\src\pages\resource-management\index.tsx
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Table, Skeleton, Tooltip, message, PaginationProps } from 'antd';
import BreadCrumb from '@/components/BreadCrumb';
import SearchInput from '@/components/common/SearchInput';
import { getResourceData } from '@/api/resource';

import { getBLen } from '@/utils';

import './index.less';

const ResourceManagementPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchForm, setSearchForm] = useState<{
    sort: string;
    withTotal: boolean;
    page: number;
    limit: number;
    name: string;
  }>({
    sort: 'createAt,DESC',
    withTotal: true,
    page: 1,
    limit: 10,
    name: '',
  });
  const [listData, setListData] = useState({
    results: [],
    total: 0
  });
  const getData = async () => {
    setLoading(true);

    try {
      const result = await getResourceData(searchForm);
      if (result && !result.code && !result.error) {
        setListData(result);
      } else {
        setListData({
          results: [],
          total: 0
        });
      }
    } catch (error) {
      message.error(t('当前网络不稳定，请稍后重试'));
    }
    setLoading(false);
  };
  const handleSearch = (value: string) => {
    setSearchForm({
      ...searchForm,
      name: value,
      page: 1
    });
  };
  useEffect(() => {
    getData();
  }, [searchForm]);
  const columns = [
    {
      title: t('资源名称'),
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      render: (text: string) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {text && (
            <Tooltip title={text}>
              <span>{getBLen(text, 30)}</span>
            </Tooltip>
          )}
        </Skeleton>
      ),
    },
    {
      title: t('资源描述'),
      dataIndex: 'describe',
      key: 'describe',
      render: (text: string) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {text && (
            <Tooltip title={text}>
              <span>{getBLen(text, 30)}</span>
            </Tooltip>
          )}
        </Skeleton>
      ),
    },
    {
      title: t('操作'),
      dataIndex: 'action',
      key: 'action',
      width: 140,
      render: (text: string, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record.name && (
            <a
              onClick={() => {
              navigate(
                `../detail/${record.id}`
              );
            }}
            >
              {t('查看')}
            </a>
          )}
        </Skeleton>
      ),
    },
  ];
  const pagenation: PaginationProps = {
    total: listData.total,
    current: searchForm.page,
    pageSize: searchForm.limit,
    onChange: (page: number, pageSize: number) => {
      setSearchForm({
        ...searchForm,
        page: page || 1,
        limit: pageSize || 20
      });
    },
  };
  return (
    <div className="page-body">
      <BreadCrumb name={t('资源管理')} />
      <div className="page-container">
        <div id="resource_management" className="resource_management">
          <Row justify="space-between" align="middle">
            <Col>
              <SearchInput
                placeholder={t('请输入资源名称')}
                autoComplete="off"
                style={{
                  width: 320,
                  marginRight: 16,
                }}
                onSearch={handleSearch}
              />
            </Col>
          </Row>
          <div
            style={{
              marginTop: 16,
            }}
          >
            <Table
              rowKey={record => record.id}
              dataSource={listData.results}
              columns={columns}
              pagination={pagenation}
            />
          </div>

          {/* <RCConfirm
        visible={this.visible}
        getContainerId="resource_management"
        onCancel={this.closeConfirm}
        myIcon={this.referenced ? 'warning' : 'alarm'}
        confirmTitle={
              this.referenced
                ? t('该资源不可删除')
                : t('您确定要删除资源“{slot0}”吗？', {
                    slot0: this.rowMsg?.name,
                  })
            }
        confirmTips={
              this.referenced
                ? t('该资源已经被引用，不可删除')
                : t('删除后，该资源会丢失，此操作不可撤销')
            }
        confirm={
          <>
            {this.referenced ? (
              <Button type="primary" onClick={this.closeConfirm}>
                {t('知道了')}
              </Button>
                ) : (
                  <>
                    <Button type="secondary" onClick={this.closeConfirm}>
                      {t('取消')}
                    </Button>
                    <ButtonWithLoading
                      type="secondary"
                      style={{
                        marginLeft: 16,
                        color: '#E60000',
                        border: '1px solid #E60000',
                      }}
                      loading={this.loadingdelete}
                      onClick={this.deleteRow}
                    >
                      {t('删除')}
                    </ButtonWithLoading>
                  </>
                )}
          </>
            }
      /> */}
        </div>
      </div>
    </div>
    
  );
};

export default ResourceManagementPage;
