/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-22 16:54:00
 * @LastEditors: songone
 * @LastEditTime: 2022-12-26 20:01:49
 * @FilePath: \tide-user-center-web\src\pages\menus-management\index.tsx
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Row, Col, Button, Spin, Tree, Dropdown, Menu, message, Modal } from 'antd';
import SearchInput from '@/components/common/SearchInput';
import BreadCrumb from '@/components/BreadCrumb';
import RCNoDataDisplay from '@/components/RCNoDataDisplay';
import RCIcon from '@/common/Icon';

import { API_I18N_JSON } from '@/utils/constants';
import { getAppInfo, assembleQuery } from '@/utils';
import { getMenusTree, updateMenuOrder, deleteMenu } from '@/api/menus';

import '@/assets/styles/admin-menus.less';

const { TreeNode } = Tree;

const { lang } : { lang: string; } = getAppInfo();
const MenusManagementPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [current, setCurrent] = useState<any>({});
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  
  const getMenuData = async () => {
    setLoading(true);
    try {
      const result = await getMenusTree();
      if (result && !result.code && !result.error) {
        setTreeData(result);
      } else {
        message.error(result?.message || t('获取菜单资源失败'));
      }
    } catch (error) {
      message.error(t('当前网络不稳定，请稍后重试'));
    }
    setLoading(false);
  };

  const goToCreate = (type: 'MENU' | 'BUTTON', sort: number, parent ?: { id: string; name: string, code: string }) => {
    const query: any = {
      type,
      sort,
    };
    if (parent) {
      query.parent = parent?.id;
      query.parentName = parent?.name;
      query.parentCode = parent?.code;
    }
    const queryStr = assembleQuery(query);
    navigate(
      `../create?${queryStr}`,
    );
  };
  const handleClick = ({ key } : { key: string }) => {
    if (key === 'MENU' || key === 'BUTTON') {
      goToCreate(key, current?.child?.length ? current?.child?.length + 1 : 1, { id: current?.id, name: current?.name, code: current?.code });
    }
    if (key === 'DELETE') {
      setDeleteVisible(true);
    }
  };
  const treeToArray = (tree: any) => {
    const arr: any[] = [];

    const expanded = (datas: any) => {
      if (datas && datas.length > 0) {
        datas.forEach((e: any) => {
          arr.push(e);
          expanded(e.child);
        });
      }
    };

    expanded(tree);
    return arr;
  };
  const changeOrder = async (parentId: string, sort: string | number, draggedId: string) => {
    try {
      const result = await updateMenuOrder(draggedId, {
        parentId,
        sort,
      });

      if (result && !result.error) {
        getMenuData();
      } else if (result.message) {
        message.warning(result.message);
      } else {
        message.warning(t('修改失败'));
      }
    } catch (error) {
      message.error(t('当前网络不稳定，请稍后重试'));
    }
  };
  const handleDrop = (info: any) => {
    const dropKey = info.node.props.eventKey;
    const dropPos = info.node.props.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const data = treeToArray(treeData);
    const item = data.find(k => k.id === dropKey);
    let draggedId = null;
    let operated = null;
    if (info.dragNodesKeys.length === 1) {
      operated = data.find(k => k.id === info.dragNodesKeys[0]);
      draggedId = operated.id;
    } else if (info.dragNodesKeys.length > 1) {
      operated = data.find(k => k.id === info.dragNodesKeys[0]);

      if (operated.parentId === '-1') {
        draggedId = operated.id;
      } else {
        draggedId = operated.parentId;
      }
    }

    if (draggedId === item.id) return;

    if (!info.dropToGap) {
      const sort = item?.child ? item.child.length + 1 : 1;
      changeOrder(item.id, sort, draggedId);
    } else if (
      (info.node.props.children || []).length > 0 &&
      info.node.props.expanded &&
      dropPosition === 1
    ) {
      changeOrder(item.id, 1, draggedId);
    } else if (dropPosition === -1) {
      // 前
      changeOrder(item.parentId, item.sort, draggedId);
    } else {
      // 后
      changeOrder(item.parentId, item.sort + 1, draggedId);
    }
  };
  const handleDelete = async () => {
    setDeleteLoading(true);

    try {
      const result = await deleteMenu(current?.id);

      if (result.success) {
        message.success(t('删除成功'));
        getMenuData();
        setDeleteVisible(false);
      } else if (result.message) {
        message.warning(result.message);
      } else {
        message.warning(t('删除失败'));
      }
    } catch (error) {
      message.error(t('当前网络不稳定，请稍后重试'));
    }
    setDeleteLoading(false);
  };
  const viewDetails = (item: any) => {
    navigate(`../detail/${item.id}`);
  };
  useEffect(() => {
    getMenuData();
  }, []);

  const alertDesc = (
    <>
      <div>{t('创建好的菜单和按钮可以长按鼠标左键上下拖动并进行排序')}</div>
      <div>{t('一个菜单可以关联一个或者多个策略')}</div>
    </>
  );
  const renderItem = (data: any) => (
    data.map((item: any) => {
      const label = (
        <div>
          <span>{API_I18N_JSON?.[lang]?.[item.name] || item.name}</span>
          {item.type === 'MENU' && (
          <span className="tree_tag green">{t('菜单')}</span>
            )}
          {item.type === 'BUTTON' && (
          <span className="tree_tag blue">{t('按钮')}</span>
            )}
        </div>
      );
      const action = (
        <div>
          <a
            onClick={() => {
                viewDetails(item);
              }}
          >
            {t('查看')}
          </a>
          <Dropdown
            onVisibleChange={() => {
              setCurrent(item);
            }}
            overlay={
              <Menu onClick={handleClick}>
                {item.type === 'MENU' && (
                <Menu.Item
                  key="MENU"
                  style={{
                        minWidth: 90,
                      }}
                >
                  <span>{t('创建子菜单')}</span>
                </Menu.Item>
                  )}
                <Menu.Item
                  key="BUTTON"
                  style={{
                      minWidth: 90,
                    }}
                >
                  <span>{t('创建按钮')}</span>
                </Menu.Item>
                <Menu.Item
                  key="DELETE"
                  style={{
                      minWidth: 90,
                    }}
                >
                  <span
                    style={{
                        color: '#E60000',
                      }}
                  >
                    {t('删除')}
                  </span>
                </Menu.Item>
              </Menu>
              }
            placement="bottomRight"
          >
            <RCIcon
              type="icon-overflow"
              style={{
                  marginLeft: 20,
                }}
            />
          </Dropdown>
        </div>
      );
      const title = (
        <Row justify="space-between" align="middle">
          <Col>{label}</Col>
          <Col>{action}</Col>
        </Row>
      );

      if (item.child && item.child.length) {
        return (
          <TreeNode key={item.id} title={title}>
            {renderItem(item.child)}
          </TreeNode>
        );
      }

      return <TreeNode key={item.id} title={title} />;
    })
  );
  return (
    <div className="page-body">
      <BreadCrumb name={t('菜单管理')} />
      <div className="page-container" >
        <div id="AdminMenus" className="admin_menus">
          <div className="alert">
            <Alert
              message={t('提示')}
              description={alertDesc}
              type="success"
              closable
              style={{
                marginBottom: 16,
              }}
            />
          </div>

          <Row justify="space-between" align="middle">
            <Col span={20}>
              <SearchInput
                placeholder={t('请输入菜单名称')}
                autoComplete="off"
                style={{
                  width: 300,
                }}
              />
            </Col>
            <Col
              style={{
                textAlign: 'right',
              }}
              span={4}
            >
              <Button
                type="primary"
                onClick={() => {
                  goToCreate('MENU', (treeData.length || 0) + 1);
                }}
              >
                {t('创建菜单')}
              </Button>
            </Col>
          </Row>
          <Spin spinning={loading}>
            <div className="container">
              {treeData.length > 0 && (
              <Tree draggable blockNode onDrop={handleDrop}>
                {renderItem(treeData)}
              </Tree>
              )}
              {treeData.length === 0 && <RCNoDataDisplay />}
            </div>
          </Spin>
          <Modal
            visible={deleteVisible}
            onCancel={() => {
              setDeleteVisible(false);
            }}
            footer={null}
            className="rc-modal"
          >
            <Row>
              <Col span={24} className="rc-modal-icon"><RCIcon type="icon-Mainwarningsfilled" style={{ color: '#e60000' }} /></Col>
              <Col span={24} className="rc-modal-title">
                {
                t('您确定要删除{slot0}吗？', {
                  slot0: current?.name ? `${current?.name}` : t('该资源'),
                })
              }
              </Col>
              <Col span={24} className="rc-modal-tip">
                <span>
                  {
                    t(
                      '删除后，该菜单和菜单内所有资源会丢失，此操作不可撤销',
                    )
                    }
                </span>
              </Col>
              <Col span={24} className="rc-modal-btn">
                <Button
                  type="default"
                  style={{ marginRight: 16 }}
                  onClick={() => { setDeleteVisible(false); }}
                >{ t('取消') }
                </Button>
                <Button
                  danger
                  type="primary"
                  onClick={handleDelete}
                  loading={deleteLoading}
                >{ t('删除') }
                </Button>
              </Col>
            </Row>
          </Modal>

          {/* <RCConfirm
            visible={this.visibleTips}
            getContainerId="AdminMenus"
            onCancel={() => {
              this.visibleTips = false;
            }}
            myIcon="warning"
            confirmTitle={t('该资源不可删除')}
            confirmTips={t('该资源已经被引用，不可删除')}
            confirm={
              <Button
                type="primary"
                onClick={() => {
                  this.visibleTips = false;
                }}
              >
                {t('知道了')}
              </Button>
            }
          /> */}
        </div>
      </div>
    </div>
  );
};

export default MenusManagementPage;
