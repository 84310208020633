import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useState, useEffect } from 'react';
import { Input, message, Table, Skeleton, Modal, Tooltip, Button, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import RCNoDataDisplay from '@/components/RCNoDataDisplay';
import RCTransfer from '@/components/RCTransfer';
import RCSearchInput from '@/components/RCSearchInput';
import RCButtonWithLoading from '@/components/RCButtonWithLoading';
import RCIcon from '@/common/Icon';
import request from '@/utils/request';
import _ from 'lodash';
import { getBLen } from '@/utils';
import './index.less';

const MemberRoles = (props: any) => {
  const navigate = useNavigate();
  const PAGEOBJ = { total: 0, current: 1, pageSize: 10 };
  const [loading, setLoading] = useState(false);
  const [pageObj, setPageObj] = useState(PAGEOBJ);
  const [linkModal, setLinkModal] = useState(false);
  const [inputText, setInputText] = useState('');
  const [tableList, setTableList] = useState([]);

  // 关联策略
  const LEFTPAGEOBJ = { total: 0, current: 1, pageSize: 10 };
  const [leftTableData, setLeftTableData] = useState([]);
  const [rightTableData, setRightTableData] = useState<any[]>([]);
  const [leftPageObj, setLeftPageObj] = useState(LEFTPAGEOBJ);
  const [leftLoading, setLeftLoading] = useState(false);
  const [rightLoading, setRightLoading] = useState(false);
  const [transferSearchText, setTransferSearchText] = useState('');
  const [selectKeys, setSelectKeys] = useState<any[]>([]);
  const [selectRows, setSelectRows] = useState<any[]>([]);
  const [loadingComfirm, setLoadingComfirm] = useState(false);

  // 解除关联
  const [relieveModal, setRelieveModal] = useState(false);
  const [relieveRecord, setRelieveRecord] = useState({} as any);

  // 请求策略列表
  const getRoleList = () => {
    const params: any = {
      limit: pageObj.pageSize,
      page: pageObj.current,
      userId: props.userId,
    };
    if (inputText) {
      params.roleName = inputText.trim();
    }
    const obj = {
      url: '/base/user-identity-management/api/v1/user-roles',
      method: 'get',
      params,
    };
    setLoading(true);
    request(obj)
      .then((res: any) => {
        const { results, total } = res;
        const o = { ...pageObj };
        o.total = total;
        setTableList(results);
        setPageObj(o);
        setLoading(false);
      })
      .catch(() => {
        setTableList([]);
        setPageObj(PAGEOBJ);
        setLoading(false);
      });
  };

  useEffect(() => {
    getRoleList();
  }, []);

  useEffect(() => {
    getRoleList();
  }, [pageObj.current, pageObj.pageSize]);

  // 过滤
  const handleChangeInput = (e: any) => {
    setInputText(e.target.value);
  };

  // 搜索
  const handleSearch = () => {
    if (pageObj.current !== 1 || pageObj.pageSize !== 10) {
      setPageObj(PAGEOBJ);
    } else {
      getRoleList();
    }
  };

  // 分页
  const changePageSize = (page: any, pageSize: any) => {
    const o = { ...pageObj };
    o.current = 1;
    o.pageSize = pageSize;
    setPageObj(o);
  };

  const changePageCurrent = (page: any, pageSize: any) => {
    const o = { ...pageObj };
    o.current = page;
    o.pageSize = pageSize;
    setPageObj(o);
  };

  // 获取左侧数据
  const getLeftData = () => {
    const params:any = {
      page: leftPageObj.current,
      limit: 8,
      scope: 'INTERNAL',
    };
    if (transferSearchText) {
      params.name = transferSearchText;
    }
    setLeftLoading(true);
    request({
      url: '/base/user-identity-management/api/v1/roles',
      method: 'get',
      params,
    })
      .then((res: any) => {
        const { results, total } = res;
        const o = { ...leftPageObj };
        o.total = total;
        setLeftTableData(results);
        setLeftPageObj(o);
        setLeftLoading(false);
      })
      .catch(() => {
        setLeftTableData([]);
        setPageObj(LEFTPAGEOBJ);
        setLeftLoading(false);
      });
  };

  useEffect(() => {
    getLeftData();
  }, [leftPageObj.current, transferSearchText]);

  // 获取右侧数据
  const getRightData = () => {
    const params:any = {
      page: pageObj.current,
      limit: 9999,
      userId: props.userId,
      scope: 'INTERNAL',
    };
    setRightLoading(true);
    request({
      url: '/base/user-identity-management/api/v1/user-roles',
      method: 'get',
      params,
    })
      .then((res: any) => {
        const { results } = res;
        setRightTableData(results);
        setRightLoading(false);
        setSelectRows(results);
        setSelectKeys(results.map((k:any) => k.id));
      })
      .catch(() => {
        setRightTableData([]);
        setRightLoading(false);
      });
  };

  const handleChangeSelect = (keys: any[], rows:any) => {
    setSelectRows(rows);
    setSelectKeys(keys);
    setRightTableData(rows);
  };

  const changeLeftPageCurrent = (page: any) => {
    const o = { ...leftPageObj };
    o.current = page;
    setLeftPageObj(o);
  };

  // 关联的策略搜索
  const changeTransferSearchValue = (e:any) => {
    setTransferSearchText(e.target.value);
  };

  const changeTransferSerchText = () => {
    const o = { ...leftPageObj };
    o.current = 1;
    setLeftPageObj(o);
  };

  const handleTransferClearSearch = () => {
    const o = { ...leftPageObj };
    o.current = 1;
    setLeftPageObj(o);
    setTransferSearchText('');
  };

  // 取消关联
  const handleCancel = () => {
    setLinkModal(false);
    setSelectKeys([]);
    setLeftPageObj(LEFTPAGEOBJ);
  };

  // 确认关联
  const handleComfirm = () => {
    const roleIds = rightTableData.map(k => k.id);
    const params = { roleIds, userId: props.userId };
    setLoadingComfirm(true);
    let uri = 'api/v1/user-roles/binding';
    if (selectRows.length > rightTableData.length) {
      uri = 'api/v1/user-roles/relieve';
    }
    request({
      url: `/base/user-identity-management/${uri}`,
      method: 'patch',
      body: params,
    })
      .then(() => {
        message.success(t('角色更新成功'));
        setLoadingComfirm(false);
        handleCancel();
        getRoleList();
      })
      .catch(() => {
        setLoadingComfirm(false);
      });
  };

  // 解除关联
  const confirmRelieve = () => {
    const params = {
      userId: props.userId,
      roleIds: [relieveRecord.id],
    };
    setLoadingComfirm(true);
    request({
      url: '/base/user-identity-management/api/v1/user-roles/relieve',
      method: 'patch',
      body: params,
    })
      .then(() => {
        message.success(t('策略已解除'));
        setLoadingComfirm(false);
        setRelieveModal(false);
        getRoleList();
      })
      .catch(() => {
        setLoadingComfirm(false);
      });
  };

  const handleCancelSelect = (obj:any) => {
    const originSelectedData = rightTableData.concat();
    const originSelectedKeys = selectKeys.concat();
    const presentSelectedData = originSelectedData.filter(
      item => item.id !== obj.id
    );

    const presentSelectedKeys = _.without(originSelectedKeys, obj.id);
    
    setRightTableData(presentSelectedData);
    setSelectKeys(presentSelectedKeys);
  };

  const columns = [
    {
      title: t('角色名称'),
      dataIndex: 'name',
      key: 'name',
      render: (
        text:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined,
      ) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {text && <Tooltip title={text}><span>{getBLen(text, 60)}</span></Tooltip>}
        </Skeleton>
      ),
    },
    {
      title: t('角色类型'),
      dataIndex: 'type',
      key: 'type',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { id: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <span>{text === 'SYSTEM' ? t('系统角色') : t('自定义角色')}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('角色描述'),
      dataIndex: 'desc',
      key: 'desc',
      render: (text: string, record: { id: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <Tooltip title={text}><span>{getBLen(text, 60)}</span></Tooltip>}
        </Skeleton>
      ),
    },
    {
      title: t('操作'),
      dataIndex: 'actions',
      key: 'actions',
      width: 160,
      render: (text: any, record: any) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.id &&
            <span>
              <a onClick={() => { navigate(`/user/permission/authority-management/${record.id}`); }}>{ t('查看') }</a>
              <a className="relieve" onClick={() => { setRelieveModal(true); setRelieveRecord(record); }}>{ t('移除') }</a>
            </span>
          }
        </Skeleton>
      ),
    },
  ];

  // 关联策略左边栏
  const leftColumns = [
    {
      title: t('角色名称'),
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      render: (
        text:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined,
      ) => (
        <Skeleton
          active
          loading={leftLoading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {text && <Tooltip title={text}><span>{getBLen(text, 20)}</span></Tooltip>}
        </Skeleton>
      ),
    },
    {
      title: t('角色类型'),
      dataIndex: 'type',
      key: 'type',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { id: any; }) => (
        <Skeleton active loading={leftLoading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <span>{text === 'SYSTEM' ? t('系统角色') : t('自定义角色')}</span>}
        </Skeleton>
      ),
    },
  ];

  // 关联策略右边栏
  const rightColumns = [
    {
      title: t('角色名称'),
      dataIndex: 'name',
      key: 'name',
      width: '50%',
      render: (text: | boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => (
        <Skeleton active loading={rightLoading} paragraph={{ rows: 1 }} title={false}>
          {text && <Tooltip title={text}><span>{getBLen(text, 20)}</span></Tooltip>}
        </Skeleton>
      ),
    },
    {
      title: t('角色类型'),
      dataIndex: 'type',
      key: 'type',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { id: any; }) => (
        <Skeleton active loading={rightLoading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <span>{text === 'SYSTEM' ? t('系统角色') : t('自定义角色')}</span>}
        </Skeleton>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { id: any; }) => (
        <Skeleton active loading={rightLoading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <span className="rc_transfer_close" onClick={() => { handleCancelSelect(record); }}><RCIcon type="icon-cancel" /></span>}
        </Skeleton>
      ),
    },
  ];

  const rowSelection = {
    onChange: handleChangeSelect,
    selectedRowKeys: selectKeys,
  };

  return (
    <div>
      <div className="policy-input">
        <Input
          placeholder={t('请输入角色名称')}
          allowClear
          onChange={handleChangeInput}
          suffix={<RCIcon type="icon-search" style={{ cursor: 'pointer' }} onClick={handleSearch} />}
          onPressEnter={handleSearch}
          style={{ width: 319 }}
        />
        <Button type="primary" onClick={() => { setLinkModal(true); getLeftData(); getRightData(); }}>{ t('添加角色') }</Button>
      </div>
      <Table
        rowKey={record => record.id}
        columns={columns}
        locale={{ emptyText: <RCNoDataDisplay /> }}
        dataSource={tableList}
        pagination={
          pageObj.total > 10
            ? {
                showQuickJumper: true,
                showSizeChanger: true,
                total: pageObj.total,
                current: pageObj.current,
                pageSize: pageObj.pageSize,
                onShowSizeChange: changePageSize,
                onChange: changePageCurrent,
              }
            : false
        }
      />
      <Modal
        visible={linkModal}
        maskClosable={false}
        width={1078}
        title={t('添加角色')}
        closeIcon={<RCIcon type="icon-cancel" />}
        footer={null}
        style={{ textAlign: 'left' }}
        onCancel={() => { setLinkModal(false); }}
      >
        <RCTransfer
          search={
            <RCSearchInput
              placeholder={t('请输入角色名称')}
              autoComplete="off"
              onChange={changeTransferSearchValue}
              onPressEnter={changeTransferSerchText}
              handleSearch={changeTransferSerchText}
              handleClearSearch={handleTransferClearSearch}
              noSearch={false}
              style={{ marginBottom: 12 }}
              value={transferSearchText}
            />
          }
          leftTableData={leftTableData}
          leftTableColumns={leftColumns}
          leftTotal={leftPageObj.total}
          leftPage={leftPageObj.pageSize}
          changeLeftPageCurrent={changeLeftPageCurrent}
          rightTableData={rightTableData}
          rightTableColumns={rightColumns}
          selectedRowKeys={selectKeys}
          rowSelection={rowSelection}
          loading={loadingComfirm}
          handleComfirm={handleComfirm}
          handleCancel={handleCancel}
        />
      </Modal>
      <Modal
        visible={relieveModal}
        maskClosable={false}
        width={428}
        closeIcon={<RCIcon type="icon-cancel" />}
        className="rc-modal"
        onCancel={() => { setRelieveModal(false); }}
        footer={null}
      >
        <Row>
          <Col span={24} className="rc-modal-icon"><RCIcon type="icon-Mainwarningsfilled" style={{ color: '#e60000' }} /></Col>
          <Col span={24} className="rc-modal-title">
            <span>{ t('你确定移除“{{name}}”吗？', { ...relieveRecord }) }</span>
          </Col>
          <Col span={24} className="rc-modal-tip"><span>{ t('移除后，该角色将解除绑定当前用户，请谨慎操作') }</span></Col>
          <Col span={24} className="rc-modal-btn">
            <Button type="default" style={{ marginRight: 16 }} onClick={() => { setRelieveModal(false); }}>{ t('取消') }</Button>
            <RCButtonWithLoading danger type="default" onClick={confirmRelieve} loading={loadingComfirm}>{ t('确定') }</RCButtonWithLoading>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default MemberRoles;
