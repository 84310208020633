import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { useState, useEffect } from 'react';
import { Input, message, Modal, Form, Button, Row, Col, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import BreadCrumb from '@/components/BreadCrumb';
import RCButtonWithLoading from '@/components/RCButtonWithLoading';
import RCIcon from '@/common/Icon';
import request from '@/utils/request';
import moment from 'moment';
import PermissionMenu from '@/components/PermissionMenu';
import PermissionPolicy from '@/components/PermissionPolicy';
import PermissionUser from '@/components/PermissionUser';
import { getStrLen, getCurrentUserInfo } from '@/utils';
import './index.less';

const AuthorityDetails = () => {
  const navigate = useNavigate();
  const pageParams = useParams();
  const userInfo = getCurrentUserInfo();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [basicData, setBasicData] = useState({} as any);
  const [editName, setEditName] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [activeKey, setActiveKey] = useState('1');
  const [roleNameText, setRoleNameText] = useState('');
  const [roleDescText, setRoleDescText] = useState('');

  // 查询策略详情
  const getAuthorityDetails = () => {
    const obj = {
      url: `/base/user-identity-management/api/v1/roles/${pageParams.id}`,
      method: 'get',
    };
    request(obj)
      .then((res: any) => {
        setBasicData(res);
        setRoleNameText(res.name);
        setRoleDescText(res.desc);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getAuthorityDetails();
  }, []);

  // tabs切換
  const changeTabs = (key:string) => {
    setActiveKey(key);
  };

  // 编辑角色名称
  const handleChangeInput = (e:any) => {
    setRoleNameText(e.target.value);
  };

  const handleClickConfirm = (des:string) => {
    if (!roleNameText) {
      message.info(t('角色名称不能为空'));
      return;
    }
    if (getStrLen(roleNameText) < 4) {
      message.info(t('角色名称不能少于4个字符，一个中文两个字符'));
      return;
    }
    if (getStrLen(roleNameText) > 20) {
      message.info(t('角色名称不能超过20个字符，一个中文两个字符'));
      return;
    }
    setLoading(true);
    const params:any = {
      name: roleNameText.trim(),
    };
    if (des) {
      params.desc = des?.trim();
    }
    const obj = {
      url: `/base/user-identity-management/api/v1/roles/${pageParams.id}`,
      method: 'put',
      body: params
    };
    request(obj)
      .then(() => { setEditName(false); getAuthorityDetails(); message.success(t('角色信息更新成功')); setLoading(false); setEditModal(false); })
      .catch(() => { setLoading(false); });
  };

  // 修改描述
  const handleSubmit = async (values: any) => {
    handleClickConfirm(values.description);
  };

  const title = (
    <span className="authority-title">
      {!editName && <span>{basicData.name}</span> }
      {editName && (
        <span className="input_box">
          <Input placeholder={t('请输入角色名称')} style={{ width: 200 }} onChange={handleChangeInput} value={roleNameText} />
          <span className="edit_btn">
            <Button icon={<CheckOutlined />} size="small" style={{ marginRight: 3 }} onClick={() => { handleClickConfirm(''); }} />
            <Button icon={<CloseOutlined />} size="small" onClick={() => { setEditName(false); setRoleNameText(basicData.name); }} />
          </span>
        </span>
      )}
      {basicData.type === 'CUSTOM' && <RCIcon type="icon-bianji" className="authority-edit" onClick={() => { setEditName(true); }} />}
      {basicData.scope === 'INTERNAL' && <span className="authority-label internal">{t('内部角色')}</span>}
      {basicData.scope === 'EXTERNAL' && <span className="authority-label external">{t('外部角色')}</span>}
    </span>
  );

  return (
    <div className="page-body">
      <BreadCrumb
        name={title}
        showBack
        goBack={() => { navigate(-1); }}
      />
      <div className="page-container">
        <div>
          <Row justify="space-between" align="middle">
            <Col>
              <div className="role_id">
                <span className="role_key">{ t('角色ID') }：</span>
                {basicData.id || <div className="no-content">{t('暂无')}</div>}
              </div>
            </Col>
            <Col>
              <div className="role_id">
                <span className="role_key">{t('更新时间')}：</span>
                {basicData.updateTime ? moment(basicData.updateTime).format('YYYY-MM-DD HH:mm:ss') : <div className="no-content">{t('暂无')}</div>}
              </div>
            </Col>
          </Row>
          <div className="description">
            <div className="description_title">
              { t('描述说明') }
              {basicData.type === 'CUSTOM' && (
              <RCIcon className="authority-edit edit-css" type="icon-bianji" onClick={() => { setEditModal(true); }} />
            )}
            </div>
            <div className="description_content">
              {basicData.desc || <div className="no-content">{t('暂无')}</div>}
            </div>
          </div>
        </div>
        <div className="line" />
        {basicData.scope === 'INTERNAL' && (
          <div style={{ background: '#fff' }}>
            <Tabs activeKey={activeKey} onChange={changeTabs}>
              <Tabs.TabPane tab={t('菜单')} key="1" />
              <Tabs.TabPane tab={t('策略')} key="2" />
              <Tabs.TabPane tab={t('授权')} key="3" />
            </Tabs>
          </div>
        )}
        {activeKey === '1' && <PermissionMenu roleId={pageParams.id} userId={userInfo.user.userId} />}
        {activeKey === '2' && <PermissionPolicy roleId={pageParams.id} type={basicData.type} />}
        {activeKey === '3' && <PermissionUser roleId={pageParams.id} roleName={basicData.name} />}
      </div>
      <Modal
        visible={editModal}
        maskClosable={false}
        width={527}
        title={t('修改描述说明')}
        closeIcon={<RCIcon type="icon-cancel" />}
        footer={null}
        style={{ textAlign: 'left' }}
        onCancel={() => { setEditModal(false); form.resetFields(); }}
      >
        <Form
          layout="horizontal"
          colon
          onFinish={handleSubmit}
          form={form}
          initialValues={{ description: roleDescText }}
        >
          <Form.Item
            name="description"
            label={<div className="add_style_text"><span>{t('描述说明')}</span><span style={{ marginLeft: 8 }} className="tag_tips">{t('选填')}</span></div>}
            rules={[
            {
              required: false,
              max: 200,
              message: '',
              validator: async (_:any, value:string) => {
                if (value && _.max < getStrLen(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('描述说明不能超过{{max}}个字符', { max: _.max }) }</div>;
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
          >
            <Input.TextArea placeholder={t('请输入描述说明，限200字符')} allowClear autoComplete="off" autoSize={{ minRows: 4 }} />
          </Form.Item>
          <Form.Item className="modal-btn-left">
            <Button type="default" style={{ marginRight: 15 }} onClick={() => { setEditModal(false); form.resetFields(); }}>{t('取消')}</Button>
            <RCButtonWithLoading type="primary" htmlType="submit" loading={loading}>{ t('确认') }</RCButtonWithLoading>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AuthorityDetails;
