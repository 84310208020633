/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-08-24 14:22:33
 * @LastEditors: songone
 * @LastEditTime: 2023-01-03 16:35:02
 * @FilePath: \tide-user-center-web\src\components\Auth\Auth.tsx
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { Spin } from 'antd';
import { Outlet } from 'react-router-dom';
import useStores from '@/hooks/useStores';
import { useEffect } from 'react';
import { tokenLogin, getUserTenants } from '@/api/user';
import { setCurrentToken } from '@/utils/auth';
import { setCurrentUserInfo, getAppInfo } from '@/utils';

export default () => {
  const store: any = useStores();
  const isLogin = !!store?.state?.userInfo?.user?.id;
  const isLoading = store.state.loading;
  useEffect(() => {
    if (!store?.state?.userInfo?.user?.id) {
      store.dispatch({ type: 'setLoading', payload: true });
      tokenLogin().then((res: any) => {
        if (res) {
          const appInfo = getAppInfo();
          // eslint-disable-next-line camelcase
          const { refresh_token, token, ...payload } = res;
          setCurrentToken(refresh_token, token);
          setCurrentUserInfo(res);
          store.dispatch({ type: 'setUserInfo', payload });
          if (appInfo.appId !== 'baas-platform') {
            // 如果是需要租户信息的，获取一下租户信息
            getUserTenants().then((result: any) => {
              // eslint-disable-next-line no-unused-vars
              const tenants = result.payload || [];
            });
            store.dispatch({ type: 'setLoading', payload: false });
          } else {
            store.dispatch({ type: 'setLoading', payload: false });
          }
        }
      });
    }
  }, []);
  if (isLoading) {
    return (
      <Spin />
    );
  }
  if (isLogin) {
    return (
      <Outlet />
    );
  }
  return (
    <div>{ t('无登录信息') }</div>
  );
};
