import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useEffect, useState } from 'react';
import RCIcon from '@/common/Icon';
import { Tooltip, Modal, Skeleton, Table } from 'antd';
import moment from 'moment';
import { getBLen } from '@/utils/index';
import request from '@/utils/request';
import RCNoDataDisplay from '@/components/RCNoDataDisplay';

const USERINFO = [
  { name: 'displayName', title: t('姓名') },
  { name: 'email', title: t('邮箱') },
  { name: 'cellphone', title: t('手机号') },
  { name: 'mobile', title: t('手机号') },
  { name: 'username', title: t('用户名') },
];

function MemberEditHistory(props: any) {
  const [loading, setLoading] = useState(false);
  const [tableList, setTableList] = useState([]);

  // 获取编辑用户历史
  const getUserLog = () => {
    const params = {
      sort: 'createdAt,desc',
      page: 1,
      limit: 500
    };
    setLoading(true);
    request({
      url: `/base/user-identity-management/api/v1/baas/user/${props.userId}/changes`,
      method: 'get',
      params
    }).then((res:any) => {
      const { results } = res;
      const list = [] as any;
      results.forEach((item:any) => {
        const obj = { ...item };

        if (item.changeDetails && item.changeDetails?.length) {
          const arr = item.changeDetails.concat();

          for (let i = 0; i < arr.length; i += 1) {
            delete obj.changeDetails;
            const obt = Object.assign(arr[i], obj);
            list.push(obt);
          }
        }
      });
      setTableList(list);
      setLoading(false);
    }).catch(() => {
      setTableList([]);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (props.showModal) {
      getUserLog();
    }
  }, [props.showModal]);

  const columns = [
    {
      title: t('时间'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {text && <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('修改人'),
      dataIndex: 'createdByName',
      key: 'createdByName',
      render: (
        text:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined,
        record: { userId: any },
      ) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record.userId && <Tooltip title={text}>{getBLen(text, 20)}</Tooltip>}
        </Skeleton>
      ),
    },
    {
      title: t('修改类型'),
      dataIndex: 'propertyName',
      key: 'propertyName',
      render: (
        text:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined,
        record: { userId: any },
      ) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record.userId &&
            <span>
              {USERINFO.map((k) => {
              if (k.name === text) {
                return k.title;
              }
              return '';
            })}
            </span>
          }
        </Skeleton>
      ),
    },
    {
      title: t('修改前'),
      dataIndex: 'value',
      key: 'value',
      render: (
        text:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined,
        record: { userId: any },
      ) => (
        <Skeleton active loading={false} paragraph={{ rows: 1 }} title={false}>
          {record.userId && <Tooltip title={text}>{getBLen(text, 20)}</Tooltip>}
        </Skeleton>
      ),
    },
    {
      title: t('修改后'),
      dataIndex: 'changedValue',
      key: 'changedValue',
      render: (text: string, record: { userId: any }) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record.userId && <Tooltip title={text}>{getBLen(text, 20)}</Tooltip>}
        </Skeleton>
      ),
    },
  ];
  return (
    <Modal
      visible={props.showModal}
      maskClosable={false}
      width={980}
      title={t('编辑历史')}
      closeIcon={<RCIcon type="icon-cancel" />}
      footer={null}
      style={{ textAlign: 'left' }}
      onCancel={() => { props.handleCancel(); }}
    >
      <Table
        rowKey={record => record.userId}
        columns={columns}
        locale={{ emptyText: <RCNoDataDisplay /> }}
        dataSource={tableList}
        pagination={
          tableList.length > 10
            ? {
                pageSizeOptions: ['10', '20', '50'],
                showQuickJumper: true,
                showSizeChanger: true,
                total: tableList.length,
              }
            : false
        }
      />
    </Modal>
  );
}

export default MemberEditHistory;
