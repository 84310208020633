import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useState } from 'react';
import RCIcon from '@/common/Icon';
import { Button, Input, Form, Modal, Radio, Select, Skeleton, Table, message, Tooltip } from 'antd';
import { getStrLen, getBLen } from '@/utils/index';
import request from '@/utils/request';
import { PASSWORD_RULE } from '@/assets/contants';
import RCButtonWithLoading from '@/components/RCButtonWithLoading';

function AddMembers(props:any) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [currentType, setCurrentType] = useState('userName');
  const [searchText, setSearchText] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [userList, setUserList] = useState<any[]>([]);

  // 表单提交
  const handleSubmit = async (values: any) => {
    const params = {
      userName: values.userName,
      displayName: values.name,
      password: values.password,
      confirmPassword: values.confirm_password,
      authSource: 'proxy',
    };
    setLoading(true);
    request({
      url: '/base/user-identity-management/api/v1/users',
      method: 'post',
      body: params
    }).then(() => {
      form.resetFields();
      setLoading(false);
      props.handleCancel(true);
    }).catch(() => {
      setLoading(false);
    });
  };

  // 选择类型
  const changeInputType = (value:string) => {
    setCurrentType(value);
  };

  const selectBefore = (
    <Select value={currentType} style={{ width: 140 }} onChange={changeInputType}>
      <Select.Option value="userName">{ t('用户名') }</Select.Option>
      <Select.Option value="mobile">{ t('手机号') }</Select.Option>
      <Select.Option value="email">{ t('邮箱') }</Select.Option>
    </Select>
  );

  // 搜索
  const handleChange = (e:any) => {
    setSearchText(e.target.value);
  };

  const handleSearch = (value:any) => {
    if (!value) {
      return;
    }
    const params:any = {};
    params[currentType] = value;
    setSearchLoading(true);
    request({
      url: '/base/user-identity-management/api/v1/users/search',
      method: 'get',
      params
    }).then((res:any) => {
      const userIndex = userList.findIndex((user:any) => user.userId === res.userId);
      if (userIndex >= 0) {
        message.error(t('该成员已经存在添加成员列表中'));
      } else {
        const arr:any[] = userList.concat();
        setSearchText('');
        arr.push(res);
        setUserList(arr);
      }
      setSearchLoading(false);
    }).catch(() => {
      setSearchLoading(false);
    });
  };

  // 确认添加
  const handleConfirm = () => {
    const params = userList.map(item => item.userId);
    setLoading(true);
    request({
      url: '/base/user-identity-management/api/v1/users/join-org',
      method: 'patch',
      body: params
    }).then((res:any) => {
      const { joinFailUsers } = res;
      if (joinFailUsers.length > 0) {
        // 如果有失败的数据处理
        message.error(t('以下用户添加失败，请稍后重试'));
        const list = joinFailUsers.map((item:any) => {
          const obj = userList.find(user => user.userId === item.userId);
          return { ...obj, reason: item.reason };
        });
        setUserList(list);
      } else {
        setAddModal(false);
        message.success(t('添加成功'));
        props.handleCancel(true);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  };

  // 删除
  const handleDelete = (userId:string) => {
    const arr = userList.concat();
    const index = arr.findIndex(item => item.userId === userId);
    arr.splice(index, 1);
    setUserList(arr);
  };

  const columns = [
    {
      title: t('用户名'),
      dataIndex: 'userName',
      key: 'userName',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {text && <Tooltip title={text}><span>{getBLen(text, 20)}</span></Tooltip>}
        </Skeleton>
      ),
    },
    {
      title: t('姓名'),
      dataIndex: 'displayName',
      key: 'displayName',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { userId: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.userId && <Tooltip title={text}><span>{getBLen(text, 20)}</span></Tooltip>}
        </Skeleton>
      ),
    },
    {
      title: t('邮箱'),
      dataIndex: 'email',
      key: 'email',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { userId: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.userId && <Tooltip title={text}><span>{getBLen(text, 10)}</span></Tooltip>}
        </Skeleton>
      ),
    },
    {
      title: t('手机'),
      dataIndex: 'mobile',
      key: 'mobile',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { userId: any; }) => (
        <Skeleton active loading={false} paragraph={{ rows: 1 }} title={false}>
          {record.userId && <Tooltip title={text}><span>{getBLen(text, 20)}</span></Tooltip>}
        </Skeleton>
      ),
    },
    {
      title: t('操作'),
      dataIndex: 'action',
      key: 'aciton',
      width: 60,
      render: (text: any, record: { userId: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.userId && (<a style={{ color: '#e60000' }} onClick={() => { handleDelete(record.userId); }}>{ t('删除') }</a>)}
        </Skeleton>
      ),
    },
  ];

  return (
    <>
      <Modal
        visible={props.showModal}
        maskClosable={false}
        width={527}
        title={t('新增机构成员')}
        closeIcon={<RCIcon type="icon-cancel" />}
        footer={null}
        style={{ textAlign: 'left' }}
        onCancel={() => { props.handleCancel(); form.resetFields(); }}
      >
        <div className="add_member_link" onClick={() => { setAddModal(true); props.handleCancel(); }}>用户已有账号？立即添加</div>
        <Form
          layout="horizontal"
          colon
          initialValues={{ register: 'password' }}
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item
            name="register"
            label={<div className="add_style_text">{ t('注册方式') }</div>}
            rules={[
            {
              required: true,
              message: <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请选择注册方式') }</div>,
            },
          ]}
          >
            <Radio.Group>
              <Radio value="email" disabled>{ t('邮箱') }</Radio>
              <Radio value="phone" disabled>{ t('手机') }</Radio>
              <Radio value="password">{ t('用户名密码') }</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="userName"
            label={<div className="add_style_text">{ t('用户名') }</div>}
            rules={[
            {
              required: true,
              min: 3,
              max: 45,
              message: <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入用户名') }</div>,
              validator: async (_: any, value: string) => {
                if (!value || !value.trim()) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入用户名') }</div>;
                  return Promise.reject();
                } else if (_.max < getStrLen(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />用户名不能超过{_.max}个字符</div>;
                  return Promise.reject();
                } else if (_.min > getStrLen(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />用户名不能少于{_.min}个字符</div>;
                  return Promise.reject();
                } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入正确的用户名') }</div>;
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
            extra={<div className="tag_tips"><RCIcon type="icon-promptfilled" className="mr-8" />3~45个数字、字母，不包含特殊字符</div>}
          >
            <Input type="text" placeholder={t('请输入用户名')} allowClear autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="password"
            label={<div className="add_style_text">{ t('密码') }</div>}
            rules={[
            {
              required: true,
              message: <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入成员用于登录的密码') }</div>,
              validator: async (_: any, value: string) => {
                if (!value || !value.trim()) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入成员用于登录的密码') }</div>;
                  return Promise.reject();
                } else if (!PASSWORD_RULE.test(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />密码需数字、大写字母、小写字母、特殊字符三种或三种以上组合且长度不小于8位</div>;
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
          >
            <Input.Password type="new-password" placeholder={t('请输入成员用于登录的密码')} allowClear autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label={<div className="add_style_text">{ t('确认密码') }</div>}
            dependencies={['password']}
            rules={[
            {
              required: true,
              message: '',
            },
            ({ getFieldValue }) => ({
              validator: async (_: any, value: string) => {
                if (!value || !value.trim()) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请再次输入成员用于登录的密码') }</div>;
                  return Promise.reject();
                } else if (getFieldValue('password') !== value) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('两次密码输入不一致') }</div>;
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            })
          ]}
          >
            <Input.Password type="new-password" placeholder={t('再次输入成员用于登录的密码')} allowClear autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="name"
            label={<div className="add_style_text">{ t('姓名') }</div>}
            rules={[
            {
              required: true,
              min: 1,
              max: 50,
              message: <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入姓名') }</div>,
              validator: async (_: any, value: string) => {
                if (!value || !value.trim()) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入姓名') }</div>;
                  return Promise.reject();
                } else if (_.max < getStrLen(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />姓名不能超过{_.max}个字符</div>;
                  return Promise.reject();
                } else if (_.min > getStrLen(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />姓名不能少于{_.min}个字符</div>;
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
            extra={<div className="tag_tips"><RCIcon type="icon-promptfilled" className="mr-8" />1~50个字符，不包含特殊字符</div>}
          >
            <Input placeholder={t('请输入姓名')} allowClear autoComplete="off" />
          </Form.Item>
          <Form.Item className="modal-btn-left">
            <Button type="default" style={{ marginRight: 15 }} onClick={() => { props.handleCancel(); form.resetFields(); }}>{ t('取消') }</Button>
            <RCButtonWithLoading type="primary" htmlType="submit" loading={loading}>{ t('确认') }</RCButtonWithLoading>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={addModal}
        maskClosable={false}
        width={650}
        title={t('添加成员')}
        closeIcon={<RCIcon type="icon-cancel" />}
        footer={null}
        style={{ textAlign: 'left' }}
        onCancel={() => { setAddModal(false); setUserList([]); form.resetFields(); }}
      >
        <div>
          <Input.Search addonBefore={selectBefore} value={searchText} loading={searchLoading} allowClear placeholder={t('请输入，点击回车确认')} onChange={handleChange} onSearch={handleSearch} />
          <div style={{ minHeight: 300 }}>
            <div className="member-number">{ t('添加成员列表') }({userList.length})</div>
            {userList.length > 0 && (
              <Table size="small" rowKey={row => row.userId} columns={columns} dataSource={userList} pagination={false} />
            )}
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button type="default" style={{ marginRight: 16 }} onClick={() => { setAddModal(false); setUserList([]); form.resetFields(); }}>{ t('取消') }</Button>
            <RCButtonWithLoading type="primary" onClick={handleConfirm} loading={loading} disabled={userList.length < 1}>{ t('确认') }</RCButtonWithLoading>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AddMembers;
