/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-07 18:10:04
 * @LastEditors: songone
 * @LastEditTime: 2022-12-07 18:18:26
 * @FilePath: \tide-user-center-web\src\pages\event-center\components\detail.tsx
 */
import { Drawer, DrawerProps } from 'antd';
import { useEffect, useState } from 'react';
import { getEventDetail } from '@/api/event';
import { handleError } from '@/utils';
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';

hljs.registerLanguage('json', json);

type PropsType = DrawerProps & {
  item?: any
}
const EventDetail = (props: PropsType) => {
  const { item, ...drawerProps } = props;
  const [content, setContent] = useState<any>({});
  const getData = async () => {
    try {
      const result = await getEventDetail('Traceability-p0f2685c37', item?.id);
      const resultContent = JSON.parse(result.content);
      setContent(resultContent);
    } catch (error) {
      handleError(error);
    }
  };
  useEffect(() => {
    hljs.initHighlightingOnLoad();
    // document.querySelectorAll('pre code').forEach((el: any) => {
    //   // hljs.initHighlightingOnLoad();
    //   // @ts-ignore
    //   if (hljs.initLineNumbersOnLoad) {
    //     // @ts-ignore
    //     hljs.initLineNumbersOnLoad({
    //       singleLine: true
    //     });
    //   }
    // });
  }, [content]);
  useEffect(() => {
    if (item?.id) {
      getData();
    }
  }, [props.item]);
  return (
    <Drawer {...drawerProps}>
      <pre>
        <code className="language-json">
          { JSON.stringify(content, null, 2) }
        </code>
      </pre>
    </Drawer>);
};
EventDetail.defaultProps = {
  item: undefined
};
export default EventDetail;
