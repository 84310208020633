/* eslint-disable guard-for-in */
import { KEY_STORAGE_SESSION_DATA } from '@/assets/contants';
import { useNavigate } from 'react-router-dom';
import request from '@/utils/request';
import { removeToken, getToken, getRefreshToken, setCurrentToken, removeRefreshToken } from '@/utils/auth';

const jwt = require('jsonwebtoken');

export function getMenuTree(parent: any, arr: any[], res: any, type = 'MENU') {
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i].parent === parent && arr[i].content.type === type) {
      const obj: any = {};
      const menu = arr[i];
      if (menu.content.icon) {
        const iconArr = menu.content.icon.split('#');
        if (iconArr.length && iconArr[1]) {
          obj.icon = iconArr[1];
          obj.customIcon = true;
        } else {
          obj.icon = menu.content.icon;
        }
      }
      if (menu.content.path) {
        obj.url = menu.content.path;
      }
      if (menu.content.sort) {
        obj.sort = menu.content.sort;
      }
      obj.key = menu.content.key;
      obj.name = menu.name;
      obj.id = menu.id;
      obj.parent = menu.parent;
      res.push(obj);
      arr.splice(i, 1);
      i -= 1;
    }
  }
  res.forEach((r: any) => {
    r.children = [];
    getMenuTree(r.id, arr, r.children);
    if (r.children.length === 0) {
      delete r.children;
    }
  });
  return res;
}
export const setAppInfo = (info: any) => {
  if (info) {
    window.sessionStorage.setItem('appInfo', JSON.stringify(info));
  }
};
export const getAppInfo = () => {
  const infoData = window.sessionStorage.getItem('appInfo');
  if (infoData) {
    return JSON.parse(infoData);
  }
  return {};
};


// 获取local用户信息
export function getCurrentUserInfo() {
  const sessionData = window.sessionStorage.getItem(KEY_STORAGE_SESSION_DATA);
  if (sessionData) {
    return JSON.parse(sessionData);
  }
  return '';
}

// 获取local用户信息
export function setCurrentUserInfo(sessionData: any) {
  if (sessionData && sessionData.user) {
    window.sessionStorage.setItem(KEY_STORAGE_SESSION_DATA, JSON.stringify(sessionData));
    setCurrentToken(sessionData.token, sessionData.refresh_token);
  }
}

// 删除local用户信息
export function removeCurrentUserInfo() {
  window.sessionStorage.removeItem(KEY_STORAGE_SESSION_DATA);
}

// 解析url路径参数
export function transformUrl(str?: string) {
  const searchString = str || window.location.search;
  // 创建目标对象
  const obj = {} as any;
  // 截取数据字符串
  const data = searchString.split('?')[1];
  // 将数据字符串表现为数组
  if (data) {
    const aParams = data.split('&');
    for (let i = 0; i < aParams.length; i += 1) {
      // 数据属性与数值分离
      const aParam = aParams[i].split('=');
      // 使用属性括号进行属性赋值
      obj[aParam[0]] = aParam[1];
    }
  }
  return obj;
}

// 参数格式化
export function assembleQuery(param: any) {
  const query = [];

  if (param) {
    for (const key in param) {
      query.push(`${encodeURIComponent(key)}=${encodeURIComponent(param[key])}`);
    }
  }
  return query.join('&');
}

// 中文字符转为2个英文字符
export function getStrLen(str: string | null | any) {
  if (str == null) return 0;
  if (typeof str !== 'string') {
    // eslint-disable-next-line no-param-reassign
    str += '';
  }
  // eslint-disable-next-line no-control-regex
  const reg = /[^\x00-\xff]/g;

  if (reg.test(str)) {
    return str.replace(reg, '**').length;
  }
  return str.length;
}

// 中文字符转为2个英文字符
export function getBLen(str: any, n: number, ellipsis = false) {
  if (!str) {
    return;
  }
  // eslint-disable-next-line
  const strReg = /[^\x00-\xff]/g

  const _str = str.replace(strReg, '**');

  const _len = _str.length;

  if (_len > n) {
    const _newLen = Math.floor(n / 2);

    const _strLen = str.length;

    for (let i = _newLen; i < _strLen; i += 1) {
      const _newStr = str.substr(0, i).replace(strReg, '**');

      if (_newStr.length === n) {
        if (ellipsis) {
          return str.substr(0, i);
        }

        return `${str.substr(0, i)}...`;
      } else if (_newStr.length > n) {
        if (ellipsis) {
          return str.substr(0, i - 1);
        }

        return `${str.substr(0, i - 1)}...`;
      } else if (
        _newStr.length === n - 1 &&
        strReg.test(str.substr(str.length - 1, 1))
      ) {
        if (ellipsis) {
          return str.substr(0, i);
        }

        return `${str.substr(0, i)}...`;
      }
    }
  } else {
    return str;
  }
}


// 判断当前运行环境不是dev环境时，回登录页需要跳转到父级登录页
export function PushLoginPage () {
  if (window.top && window.top !== window) {
    window.postMessage({
      type: 'toLogin',
      data: ''
    }, '*');
  } else {
    window.location.href = '/common-page/login';
  }
}


// 清除用户登录信息
export function clearLogin () {
  window.localStorage.clear();
  window.sessionStorage.clear();
  removeToken();
  removeRefreshToken();
}

export function GetTokenLogin() {
  const navigate = useNavigate();
  const accessToken = getToken();
  const refreshToken = getRefreshToken();
  if (accessToken && refreshToken) {
    request({
      url: '/base/user-identity-management/auth/token-login',
      method: 'post',
      body: { access_token: accessToken, refresh_token: refreshToken },
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then((res:any) => {
      setCurrentUserInfo(res);
    }).catch(() => {
      navigate('/login');
    });
  } else {
    navigate('/login');
  }
}

// 获取当前的顶级域
export function getTopDomain() {
  const url = window.location.hostname;
  if (url) {
    const arr = url.split('.');
    arr.shift();
    return arr.join('.');
  }
  return url;
}

export function tokenPayloadParser(token: any) {
  let payload = {};

  try {
    const decoded = jwt.decode(token, {
      complete: true,
    });
    if (!decoded.payload) {
      return undefined;
    }

    payload = { ...decoded.payload };
  } catch (e) {
    return undefined;
  }

  return payload;
}
// 处理错误信息, 可用来上报
// eslint-disable-next-line no-unused-vars
export function handleError(err: any) {
  
}
