import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useState } from 'react';
import { Input, message, Form, Button, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from '@/components/BreadCrumb';
import RCButtonWithLoading from '@/components/RCButtonWithLoading';
import RCIcon from '@/common/Icon';
import PolicyComponent from '@/components/PolicyComponent';
import request from '@/utils/request';
import { getStrLen } from '@/utils';
import './index.less';

const CreatePolicys = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [, setFlag] = useState(false);

  // 提交
  const handleSubmit = (values: any) => {
    if (!values.permissions || !values.permissions.length) {
      message.info(t('请添加权限'));
      return;
    }
    const permissions = values.permissions.map((k: any) => ({
      name: k.name,
      resourceCode: k.resourceCode,
      effect: k.effect,
      allSelect: k.allSelect,
      actions: k.actions,
    }));
    const params = {
      name: values.name,
      describe: values.describe,
      type: 1,
      permissions,
    };
    setLoading(true);
    request({
      url: '/base/user-identity-management/api/v1/policies',
      method: 'post',
      body: params
    }).then(() => {
      form.resetFields();
      message.success(t('策略创建成功'));
      setLoading(false);
      navigate(-1);
    }).catch(() => {
      setLoading(false);
    });
  };

  const listenPermissionData = (e:any) => {
    setFlag(!(e.length > 0));
  };

  return (
    <div className="page-body">
      <BreadCrumb
        name={t('创建策略')}
        showBack
        goBack={() => { navigate(-1); }}
      />
      <div className="page-container">
        <div className="create-policy">
          <Form
            layout="horizontal"
            colon
            onFinish={handleSubmit}
            form={form}
          >
            <Form.Item
              name="name"
              label={<div className="add_style_text">{ t('策略名称') }</div>}
              rules={[
            {
              required: true,
              min: 4,
              max: 60,
              message: <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入策略名称') }</div>,
              validator: async (_:any, value:string) => {
                if (!value || !value.trim()) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />{ t('请输入策略名称') }</div>;
                  return Promise.reject();
                } else if (_.max < getStrLen(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />策略名称不能超过{_.max}个字符</div>;
                  return Promise.reject();
                } else if (_.min > getStrLen(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />策略名称不能少于{_.min}个字符</div>;
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
              extra={<div className="tag_tips"><RCIcon type="icon-promptfilled" className="mr-8" />4～60个字符（1个汉字算2个字符），不可与已有策略重名</div>}
            >
              <Input placeholder={t('请输入策略名称')} allowClear autoComplete="off" />
            </Form.Item>
            <Form.Item
              name="describe"
              label={<div className="add_style_text"><span className="mr-8">{ t('描述说明') }</span><span className="tag_tips">{ t('选填') }</span></div>}
              rules={[
            {
              required: false,
              max: 200,
              message: '',
              validator: async (_:any, value:string) => {
                if (value && _.max < getStrLen(value)) {
                  _.message = <div className="err_tips"><RCIcon type="icon-Mainwarningsfilled" className="mr-8" />描述说明不能超过{_.max}个字符</div>;
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
            >
              <Input.TextArea placeholder={t('请填写策略描述信息，限200字符')} allowClear autoComplete="off" autoSize={{ minRows: 4 }} />
            </Form.Item>
          </Form>
        </div>
        <Divider type="horizontal" className="divider" />
        <div className="create-config">
          <div className="create-tips">{t('策略是用于定义和描述一条或多条权限的集合，您可以根据权限管理需求，更精细化的定义资源的权限管理集合，支持细粒度的权限划分或组合')}</div>
          <Form
            layout="horizontal"
            colon
            onFinish={handleSubmit}
            form={form}
          >
            <Form.Item name="permissions">
              <PolicyComponent listenPermissionData={listenPermissionData} />
            </Form.Item>
            <Form.Item className="modal-btn-left">
              <Button type="default" style={{ marginRight: 15 }} onClick={() => { form.resetFields(); navigate(-1); }}>{ t('取消') }</Button>
              <RCButtonWithLoading type="primary" htmlType="submit" loading={loading}>{ t('确认') }</RCButtonWithLoading>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CreatePolicys;
