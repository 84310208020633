import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { useState } from 'react';
import { Modal, Radio, Form, Button, message, Row, Col, Tooltip } from 'antd';
import RCIcon from '@/common/Icon';
import request from '@/utils/request';
import RCSearchSelect from '@/components/RCSearchSelect';
import { getBLen } from '@/utils';
import './index.less';

const PolicyComponent = (props: any) => {
  const initResourcePageObj = {
    total: 0,
    current: 1,
    pageSize: 500,
  };
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState({} as any);
  const [permissionList, setPermissionList] = useState([] as any);
  // 初始换加载资源列表
  const [actionShow, setActionShow] = useState('false');
  const [loadingResource, setLoadingResource] = useState(false);
  const [searchResourceText, setSearchResourceText] = useState('');
  const [resourcePageObj, setResourcePageObj] = useState(initResourcePageObj);
  const [resourceList, setResourceList] = useState([]);
  const [hasMoreResource, setHasMoreResource] = useState(true);
  const [resourceCode, setResourceCode] = useState('');

  // 初始化加载动作列表
  const [loadingActions, setLoadingActions] = useState(false);
  const [searchActionsText, setSearchActionsText] = useState('');
  const [actionsList, setActionsList] = useState([]);

  const triggerChange = (changedValue: any[]) => {
    const { onChange } = props;
    if (onChange) {
      onChange([...changedValue]);
    }
  };

  // 关闭模态框
  const closeModal = () => {
    setVisible(false);
    setResourceCode('');
    setActionShow('false');
    setEditData({});
    form.resetFields();
  };

  // 添加策略
  const handleSubmit = async (values: any) => {
    const listenPermissionData = props.listenPermissionData;
    let oldValue: any[] = permissionList || [];
    let id: any = '';

    if (editData?.id) {
      oldValue = oldValue.filter(k => k.id !== editData?.id);
      id = editData?.id;
    } else {
      oldValue = permissionList || [];
      const time = new Date();
      id = time.getTime();
    }

    const params: any = {
      id,
      effect: values.effect,
      allSelect: JSON.parse(values.allSelect),
      name: values.resourceCode,
      resourceCode,
    };

    if (JSON.parse(params.allSelect)) {
      params.actions = values.actions;
    }

    const currentValue = [...oldValue, params];
    setPermissionList(currentValue);
    closeModal();
    listenPermissionData(currentValue);
    triggerChange(currentValue);
  };

  const actionChange = (e: any) => {
    setActionShow(JSON.parse(e.target.value));
    form.resetFields(['actions']);
  };

  // 加载资源列表
  const getResourceData = () => {
    const params: any = {
      page: resourcePageObj.current,
      limit: resourcePageObj.pageSize,
      sort: 'createdAt,desc',
      withTotal: true,
    };
    if (searchResourceText) {
      params.name = searchResourceText.trim();
    }
    setLoadingResource(true);
    request({
      url: '/base/user-identity-management/api/v1/resources',
      method: 'get',
      params,
    })
      .then((res: any) => {
        const { results, total } = res;
        const o = { ...resourcePageObj };
        o.total = total;
        setResourcePageObj(o);
        setResourceList(results);
        setLoadingResource(false);
      })
      .catch(() => {
        setResourceList([]);
        setResourcePageObj(initResourcePageObj);
        setLoadingResource(false);
      });
  };

  // 加载资源
  const handleClickResourceVisible = (isVisible: Boolean) => {
    form.resetFields(['actions']);
    if (isVisible) {
      setSearchResourceText('');
    }
  };

  const handleSearchResource = (value: any) => {
    setSearchResourceText(value);
    getResourceData();
  };

  const handleClearSearchResource = () => {
    setSearchResourceText('');
    getResourceData();
  };

  const handleInfiniteOnLoadResource = () => {
    if (resourceList.length >= resourcePageObj.total) {
      message.warning(t('资源已全部加载'));
      setLoadingResource(false);
      setHasMoreResource(false);
      return;
    }
    const o = { ...resourcePageObj };
    o.pageSize += 10;
    setResourcePageObj(o);
    getResourceData();
  };

  const selectResource = (item: any) => {
    setResourceCode(item.code);
  };

  // 加载当前资源下动作
  const getActionsData = () => {
    const params: any = {};
    if (searchActionsText) {
      params.name = searchActionsText.trim();
    }
    setLoadingActions(true);
    request({
      url: `/base/user-identity-management/api/v1/resources/${resourceCode}/actions`,
      method: 'get',
      params,
    })
      .then((res: any) => {
        setActionsList(res);
        setLoadingActions(false);
      })
      .catch(() => {
        setActionsList([]);
        setLoadingActions(false);
      });
  };

  // 加载资源
  const handleClickActionsVisible = (isVisible: Boolean) => {
    if (isVisible) {
      setSearchActionsText('');
      if (!resourceCode) {
        message.warning(t('请选择资源'));
      } else {
        getActionsData();
      }
    }
  };

  const handleSearchActions = (value: any) => {
    setSearchActionsText(value);
    getActionsData();
  };

  const handleClearSearchActions = () => {
    setSearchActionsText('');
    getActionsData();
  };

  // 编辑和删除
  const deleteItems = (id: any) => {
    const listenPermissionData = props.listenPermissionData;
    const oldValue = props.value || [];
    const value = oldValue.filter((k: { id: any }) => k.id !== id);
    setPermissionList(value);
    listenPermissionData(value);
    triggerChange(value);
  };

  const edit = (item: any) => {
    setVisible(true);
    setEditData(item);
    setResourceCode(item.resourceCode);
    setActionShow(item.allSelect);
    form.setFieldsValue({ resourceCode: item.name, actions: item.actions, allSelect: `${item.allSelect}` });
  };

  // 渲染dom
  const items = (props.value || permissionList).map((k: any) => (
    <Row
      className="policy_item"
      justify="space-between"
      align="middle"
      key={k.id}
    >
      <Col>
        <span>
          <Tooltip title={k.name}>
            <span>{getBLen(k.name, 20)}</span>
          </Tooltip>
        </span>
        {k.effect === 'DENY' && <span className="refuse_label">{ t('拒绝') }</span>}
      </Col>
      <Col className="item_action">
        <a
          onClick={() => {
            edit(k);
          }}
        >
          {t('编辑')}
        </a>
        <a
          className="delete"
          onClick={() => { deleteItems(k.id); }}
        >
          {t('删除')}
        </a>
      </Col>
    </Row>
  ));
  return (
    <div>
      {items}
      <a className="add" onClick={() => { setVisible(true); getResourceData(); }}>
        <RCIcon type="icon-plus" style={{ marginRight: 8 }} />
        {t('添加权限')}
      </a>
      <Modal
        visible={visible}
        maskClosable={false}
        width={640}
        title={t('权限配置')}
        closeIcon={<RCIcon type="icon-cancel" />}
        footer={null}
        style={{ textAlign: 'left' }}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
      >
        <Form
          layout="horizontal"
          colon
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            effect: 'ALLOW', allSelect: 'false',
          }}
        >
          <Form.Item
            name="effect"
            label={<div className="add_style_text">{ t('权限效力') }</div>}
            rules={[
              {
                required: true,
                message: (
                  <div className="err_tips">
                    <RCIcon type="icon-Mainwarningsfilled" className="mr-8" />
                    {t('请选择权限效力')}
                  </div>
                ),
              },
            ]}
          >
            <Radio.Group>
              <Radio value="ALLOW">{ t('允许') }</Radio>
              <Radio value="DENY">{ t('拒绝') }</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="resourceCode"
            label={<div className="add_style_text">{ t('选择资源') }</div>}
            rules={[
              {
                required: true,
                message: (
                  <div className="err_tips">
                    <RCIcon type="icon-Mainwarningsfilled" className="mr-8" />
                    {t('请选择资源')}
                  </div>
                ),
              },
            ]}
          >
            <RCSearchSelect
              checkMode={false}
              keyValue="name"
              placeholder={t('请输入资源名称')}
              dataSource={resourceList}
              displayQuantity={5}
              loading={loadingResource}
              hasMore={hasMoreResource}
              handleClickVisible={handleClickResourceVisible}
              handleSearchRole={handleSearchResource}
              handleClearSearch={handleClearSearchResource}
              handleInfiniteOnLoad={handleInfiniteOnLoadResource}
              onSelect={selectResource}
            />
          </Form.Item>
          <Form.Item
            name="allSelect"
            label={<div className="add_style_text">{ t('选择操作') }</div>}
            rules={[
              {
                required: true,
                message: (
                  <div className="err_tips">
                    <RCIcon type="icon-Mainwarningsfilled" className="mr-8" />
                    {t('请选择权限效力')}
                  </div>
                ),
              },
            ]}
          >
            <Radio.Group onChange={actionChange}>
              <Radio value="false">{ t('全部操作') }</Radio>
              <Radio value="true">{ t('特定操作') }</Radio>
            </Radio.Group>
          </Form.Item>
          {JSON.parse(actionShow) && (
            <Form.Item
              name="actions"
              rules={[
                {
                  required: true,
                  message: (
                    <div className="err_tips">
                      <RCIcon type="icon-Mainwarningsfilled" className="mr-8" />
                      {t('请选择操作')}
                    </div>
                  ),
                },
              ]}
            >
              <RCSearchSelect
                keyValue="name"
                dataId="code"
                placeholder={t('请输入操作名称')}
                dataSource={actionsList}
                displayQuantity={5}
                hasMore
                loading={loadingActions}
                handleClickVisible={handleClickActionsVisible}
                handleSearchRole={handleSearchActions}
                handleClearSearch={handleClearSearchActions}
              />
            </Form.Item>
          )}
          <Form.Item className="modal-btn-left">
            <Button
              type="default"
              style={{ marginRight: 15 }}
              onClick={() => {
                setVisible(false);
                form.resetFields();
                setActionShow('false');
              }}
            >
              {t('取消')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('确认')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PolicyComponent;
