export const MENUDATA = [
  {
    id: 'BACKPAGE',
    parent: '-1',
    name: '返回',
    content: {
      type: 'MENU',
      key: 'BACKPAGE',
      icon: '#icon-Home',
      sort: 1,
      path: ''
    }
  },
  {
    id: 'PERMISSION',
    parent: '-1',
    name: '权限管理',
    content: {
      type: 'MENU',
      key: 'PERMISSION',
      icon: '#icon-ConfigurationManagement',
      sort: 2,
      path: ''
    }
  },
  {
    id: 'MEMBER',
    parent: 'PERMISSION',
    name: '用户管理',
    content: {
      type: 'MENU',
      key: 'MEMBER',
      icon: '',
      sort: 1,
      path: '/layout/user/permission/member-management'
    }
  },
  {
    id: 'AUTHORITY',
    parent: 'PERMISSION',
    name: '角色管理',
    content: {
      type: 'MENU',
      key: 'AUTHORITY',
      icon: '',
      sort: 2,
      path: '/layout/user/permission/authority-management'
    }
  },
  {
    id: 'POLICY',
    parent: 'PERMISSION',
    name: '策略管理',
    content: {
      type: 'MENU',
      key: 'POLICY',
      icon: '',
      sort: 3,
      path: '/layout/user/permission/policy-management'
    }
  },
  {
    id: 'EVENTCENTER',
    parent: '-1',
    name: '事件中心',
    content: {
      type: 'MENU',
      key: 'EVENTCENTER',
      icon: '#icon-ConfigurationManagement',
      sort: 2,
      path: '/layout/event-center/list'
    }
  },
  {
    id: 'MESSAGECENTER',
    parent: '-1',
    name: '消息中心',
    content: {
      type: 'MENU',
      key: 'MESSAGECENTER',
      icon: '#icon-ConfigurationManagement',
      sort: 2,
      path: '/layout/message-center/list'
    }
  },
  {
    id: 'ORGSMANAGEMENT',
    parent: '-1',
    name: '机构管理',
    content: {
      type: 'MENU',
      key: 'ORGSMANAGEMENT',
      icon: '#icon-ConfigurationManagement',
      sort: 2,
      path: '/layout/orgs-management/list'
    }
  },
  {
    id: 'RESOURCEMANAGEMENT',
    parent: '-1',
    name: '资源管理',
    content: {
      type: 'MENU',
      key: 'RESOURCEMANAGEMENT',
      icon: '#icon-ConfigurationManagement',
      sort: 2,
      path: '/layout/resource-management/list'
    }
  },
  {
    id: 'MENUSMANAGEMENT',
    parent: '-1',
    name: '菜单管理',
    content: {
      type: 'MENU',
      key: 'MENUSMANAGEMENT',
      icon: '#icon-ConfigurationManagement',
      sort: 2,
      path: '/layout/menus-management/list'
    }
  },
];
