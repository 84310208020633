import { useEffect, useState } from 'react';
import Icon from '@ant-design/icons';
import { Menu, Dropdown, Layout, Divider } from 'antd';
import { useNavigate, Outlet } from 'react-router-dom';
import { clearLogin } from '@/utils';
import RCIcon from '@/common/Icon';
import './index.less';

const { Header, Sider, Content } = Layout;
const { SubMenu, Item } = Menu;

const LayoutComponent = (props) => {
  const navigate = useNavigate();
  const [seletedKeys, setSeletedKeys] = useState([]);
  const [isProd, setIsProd] = useState(false);
  // 路由匹配
  const getMenuTreeKeyPath = (
    currentPageUrl,
    menuItems,
    currentMenuTreeKeyPath = [],
    rank = 1,
  ) => {
    if (!Array.isArray(menuItems)) {
      return [];
    }
    for (let i = 0, l = menuItems.length; i < l; i += 1) {
      const menuItem = menuItems[i];
      const menuItemKey =
        typeof menuItem.key === 'string'
          ? menuItem.key
          : menuItem.key.toString();
      if (
        !menuItem.url &&
        menuItem.children &&
        Array.isArray(menuItem.children)
      ) {
        const resultFromInside = getMenuTreeKeyPath(
          currentPageUrl,
          menuItem.children,
          currentMenuTreeKeyPath.concat([menuItemKey]),
          rank + 1,
        );
        if (resultFromInside.length) {
          return resultFromInside;
        }
      } else {
        const url = menuItem.url;
        let currentUrl = currentPageUrl;
        if (currentUrl.indexOf('?') > -1) {
          currentUrl = currentPageUrl.split('?')[0];
        }
        if (url && url.includes(currentUrl.split('/')[rank])) {
          return currentMenuTreeKeyPath.concat([menuItemKey]);
        }
      }
    }
    return currentMenuTreeKeyPath;
  };

  // 根据路由自动选择菜单key
  useEffect(() => {
    const route = window.location.pathname;
    const keys = getMenuTreeKeyPath(route, props.menu);
    setSeletedKeys(keys);
  }, []);

  useEffect(() => {
    const prod = process.env.NODE_ENV !== 'development';
    setIsProd(prod);
  }, [window.location.href]);

  const renderTree = (menuitems) => {
    const result = menuitems.map(
      (obj) => {
        if (obj.children) {
          return (
            <SubMenu
              title={
                <span>
                  {obj.customIcon ? (<RCIcon type={obj.icon} />) : (<Icon component={obj.icon} />)}
                  <span>{obj.name}</span>
                </span>
              }
              key={obj.key}
            >
              {renderTree(obj.children)}
            </SubMenu>
          );
        }
        return (
          <Item key={obj.key} url={obj.url} className={obj.key === 'BACKPAGE' ? 'home-page' : ''}>
            {obj.customIcon ? (<RCIcon type={obj.icon} className="icon_css" />) : (<Icon type={obj.icon} className="icon_css" />)}
            <span>{obj.name}</span>
          </Item>
        );
      },
    );
    return result;
  };

  const handleLogout = () => {
    clearLogin();
    navigate('/login');
  };

  // 菜单跳转
  const handleMenuChange = (e) => {
    if (e.key === 'BACKPAGE') {
      window.parent.postMessage('message', '*');
      return;
    }
    setSeletedKeys(e.keyPath);
    navigate(e.item.props.url);
  };

  const AvatarMenu = (
    <Menu>
      <Menu.Item key="name" className="user-name">{props.userInfo?.user?.displayName}</Menu.Item>
      {/* <Menu.Item key="center">个人中心</Menu.Item> */}
      <Divider style={{ margin: '8px 0' }} />
      <Menu.Item key="logout" onClick={handleLogout}>
        退出登录
      </Menu.Item>
    </Menu>
  );
  const defaultProps = seletedKeys.length < 2 ? {} : { openKeys: seletedKeys };
  return (
    <Layout className="layout-body">
      {!isProd && (
      <Header className="layout-header">
        <div className="logo-wrap">
          <img className="logo-img" src="/static/logo.svg" alt="" />
        </div>
        <div className="user-wrap">
          <Dropdown overlay={AvatarMenu}>
            <div style={{ cursor: 'pointer' }}>
              <RCIcon type="icon-zuzhi" className="org-icon" />
              <span className="org-name">{props.userInfo?.org?.name}</span>
              <img src="/static/avatar.svg" alt="" className="org-logo" />
            </div>
          </Dropdown>
        </div>
      </Header>
        )}
      <Layout>
        <Sider className="layout-section">
          <Menu mode="inline" style={{ height: '100%', borderRight: 0 }} onClick={handleMenuChange} selectedKeys={seletedKeys} {...defaultProps}>
            {props.menu ? renderTree(props.menu) : null}
          </Menu>
        </Sider>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
