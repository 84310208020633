/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-21 11:13:31
 * @LastEditors: songone
 * @LastEditTime: 2022-12-26 10:23:05
 * @FilePath: \tide-user-center-web\src\api\permission.ts
 */
import request from '@/utils/request';

// 获取角色列表
export const getRoleData = (params: any) => request({
  url: '/base/user-identity-management/api/v1/roles',
  method: 'get',
  params
});

// 获取用户角色
export const getRoleByUser = (params: any) => request({
  url: '/base/user-identity-management/api/v1/user-roles',
  method: 'get',
  params
});

// 用户绑定角色
export const bindUserRole = (data: any) => request({
  url: '/base/user-identity-management/api/v1/user-roles/binding',
  method: 'patch',
  data
});
// 用户解绑角色
export const relieveUserRole = (data: any) => request({
  url: '/base/user-identity-management/api/v1/user-roles/relieve',
  method: 'patch',
  data
});
// 获取策略数据
export const getPolicyData = (params: any) => request({
  url: '/base/user-identity-management/api/v1/policies',
  method: 'get',
  params
});

