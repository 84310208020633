import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useEffect, useState } from 'react';
import { Dropdown, Menu, Checkbox, List, Skeleton, Input, message, Tooltip } from 'antd';
import RCIcon from '@/common/Icon';
import { getPolicyData } from '@/api/permission';
import { getBLen } from '@/utils';

import './index.less';

type PropsType = {
  onChange?: any;
  value?: string[];
  keyValue?: string;
  dataId?: string;
}
const PolicySelectWithSearch = (props: PropsType) => {
  const {
    onChange, value, keyValue = 'name', dataId = 'id'
  } = props;

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [listData, setListData] = useState({
    results: [],
    total: 0
  });
  const [searchForm, setSearchForm] = useState({
    page: 1,
    limit: 999,
    sort: 'createdAt,desc',
    name: ''
  });

  const getRoleList = async () => {
    setLoading(true);
    try {
      const result = await getPolicyData(searchForm);

      if (result && !result.code && !result.error) {
        setListData(result);
      } else {
        setListData({
          results: [],
          total: 0
        });
      }
    } catch (error) {
      message.error(t('当前网络不稳定，请稍后重试'));
    }
    setLoading(false);
  };
  const handleSearch = (val: string) => {
    setSearchForm({
      ...searchForm,
      name: val
    });
  };

  const handleChecked = async (e: any, item: any) => {
    const k = dataId || 'id';
    const list = Array.isArray(value) ? value : [];
    let valueArr = [];

    if (e.target.checked) {
      valueArr = [...list, item];
    } else {
      valueArr = [...list].filter((key: any) => key[k] !== item[k]);
    }
    if (onChange) {
      onChange(valueArr);
    }
  };

  // 用户自主切换显示下拉
  const handleChangeVisible = (show: boolean) => {
    setVisible(show);
  };

  useEffect(() => {
    getRoleList();
  }, [searchForm]);

  const menu = (
    <Menu>
      <div className="role_list">
        <Input.Search
          placeholder={t('请输入角色名称')}
          onSearch={handleSearch}
          loading={loading}
          autoComplete="off"
        />
      </div>
      <div className="role_list_select">
        <List
          size="small"
          header={<div />}
          dataSource={listData.results}
          renderItem={(item: any) => (
            <List.Item>
              <Skeleton
                loading={loading}
                active
                paragraph={{
                  rows: 1,
                }}
                title={false}
              >
                <Checkbox
                  key={item[dataId]}
                  checked={Array.isArray(value) && value?.some(
                        (k: any) => k[dataId] === item[dataId],
                      )}
                  onChange={(e: any) => { handleChecked(e, item); }}
                >
                  <Tooltip title={item[keyValue]}>
                    <span>{getBLen(item[keyValue], 50)}</span>
                  </Tooltip>
                </Checkbox>
              </Skeleton>
            </List.Item>
          )}
        />
      </div>
    </Menu>
  );
  return (
    <div className="role_select">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        visible={visible}
        placement="topLeft"
        onVisibleChange={handleChangeVisible}
        overlayClassName="role-overlay-box"
      >
        <div className="search_box">
          {
            value && value?.length > 0 ? (
              <div className="search_box_text">
                {value?.map((k: any, i: any) => {
                  if (i < 3) {
                    return (
                      <div className="search_box_item" key={k[keyValue]}>
                        {getBLen(k[keyValue], 12)}
                      </div>
                    );
                  } else if (i === 3) {
                    return (
                      <div className="search_box_item" key="surplus">
                        + {value?.length - 3} ...
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            ) : (
              <div className="search_box_text" style={{ color: '#C8CED9' }}>
                {t('请选择')}
              </div>
            )
          }
          <RCIcon
            type="icon-arrow_down_line"
            className={visible ? 'search_icon_up' : 'search_icon_down'}
          />
        </div>
      </Dropdown>
    </div>
  );
};
PolicySelectWithSearch.defaultProps = {
  onChange: () => {},
  value: undefined,
  keyValue: 'name',
  dataId: 'id'
};

export default PolicySelectWithSearch;

