import request from '@/utils/request';
import settings from '@/setting';

export const login = (data: any) => request({
  url: `/base/auth/system/${settings.systemId}`,
  method: 'post',
  data
});

export const getUserInfo = (data: any) => request({
  url: `/base/auth/system/${settings.systemId}`,
  method: 'get',
  data
});

export const tokenLogin = () => request({
  url: '/base/user-identity-management/auth/token-login',
  method: 'post',
  noToken: true
});
// 获取用户的租户列表
export const getUserTenants = () => request({
  url: '/base/user-identity-management/api/v1/tenants/list-by-login',
  method: 'get',
});

