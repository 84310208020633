/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-23 17:23:13
 * @LastEditors: songone
 * @LastEditTime: 2023-01-04 16:21:46
 * @FilePath: \tide-user-center-web\src\pages\menus-management\components\EditForm\index.tsx
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';
import { Drawer, DrawerProps, Form, Input, Button, message } from 'antd';
import { getStrLen } from '@/utils';
import { useEffect, useState } from 'react';
import { updateMenuInfo } from '@/api/menus';
import PolicySelectWithSearch from '../PolicySelectWithSearch';

type PropsType = DrawerProps & {
  onSuccess: any;
  onCancel: any;
  detail: any;
  menuId: string;
}
const EditForm = (props: PropsType) => {
  const {
    detail, onSuccess, menuId, ...drawerProps
  } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleFinish = async (values: any) => {
    setLoading(true);
    const params = {
      ...values,
      name: values.name.trim(),
      code: values.code,
      path: values.path,
      sort: values.sort,
      type: values.type === t('菜单') ? 'MENU' : 'BUTTON',
      parentId: detail.parentId,
      policies: (values.policies && values.policies.length > 0) ? values.policies.map((k: any) => k.id) : []
    };

    // if (values.policies && values.policies.length) {
    //   params.policies = values.policies.map(k => k.id);
    // }
    try {
      const result = await updateMenuInfo(menuId, params);

      if (result && result.success && !result.error) {
        message.success(t('修改成功'));
        if (onSuccess) {
          onSuccess();
        }
      } else if (result.message) {
        message.warning(result.message);
      } else {
        message.warning(t('创建失败'));
      }
    } catch (error) {
      message.error(t('当前网络不稳定，请稍后重试'));
    }
    setLoading(false);
  };
  useEffect(() => {
    form.setFieldsValue({
      ...detail,
      policies: detail?.policyList || undefined
    });
  }, [props.visible, detail]);
  return (
    <Drawer
      {...drawerProps}
      className="edit_drawer"
      width={478}
      title={
        <span className="title">{t('修改基本信息')}</span>
            }
      placement="right"
      closable
      maskClosable={false}
      getContainer={false}
      mask={false}
      style={{
              marginTop: 48,
            }}
    >
      <div className="drawer_body_test">
        <Form
          onFinish={handleFinish}
          className="from_set"
          form={form}
        >
          <Form.Item
            label={<div className="label">{t('菜单名称')}</div>}
            rules={[
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(new Error(t('请输入菜单名称')));
                  }
                  if (getStrLen(value) > 60) {
                    return Promise.reject(new Error(t('菜单名称不能超过60个字符')));
                  }
                  if (getStrLen(value) < 4) {
                    return Promise.reject(new Error(t('菜单名称不能少于4个字符')));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            name="name"
          >
            <Input
              placeholder={t('请输入菜单名称')}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            label={<div className="label">{t('资源编码')}</div>}
            rules={[
              { required: true, message: t('请输入资源编码') }
            ]}
            name="code"
          >
            <Input
              disabled
              placeholder={t('请输入资源编码')}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            label={<div className="label">{t('类型')}</div>}
            rules={[
              { required: true, message: t('请输入类型') }
            ]}
            name="type"
          >
            <Input
              disabled
              placeholder={t('请输入类型')}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            label={
              <div className="label">
                {t('图标')}
                <span
                  className="tag_tips"
                  style={{
                          marginLeft: 8,
                        }}
                >
                  {t('选填')}
                </span>
              </div>
                  }
            name="icon"
          >
            <Input placeholder={t('请输入图标')} autoComplete="off" />
          </Form.Item>
          <Form.Item
            label={
              <div className="label">
                {t('路由')}
                <span
                  className="tag_tips"
                  style={{ marginRight: 8 }}
                >
                  {t('选填')}
                </span>
              </div>
                  }
            name="path"
          >
            <Input placeholder={t('请输入路由')} autoComplete="off" />
          </Form.Item>
          <Form.Item
            label={<div className="label">{t('排序')}</div>}
            name="sort"
          >
            <Input
              disabled
              placeholder={t('请输入序号')}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            label={
              <div className="label">
                {t('策略')}
                <span
                  className="tag_tips"
                  style={{ marginRight: 8 }}
                >
                  {t('选填')}
                </span>
              </div>
                  }
            name="policies"
          >
            <PolicySelectWithSearch />
          </Form.Item>
          <Form.Item
            label={
              <div className="label">
                {t('描述')}
                <span
                  className="tag_tips"
                  style={{ marginRight: 8 }}
                >
                  {t('选填')}
                </span>
              </div>
                  }
            name="describe"
          >
            <Input.TextArea
              placeholder={t('请填写菜单描述信息，限200字符。')}
              autoComplete="off"
              style={{ height: 90 }}
              maxLength={200}
            />
          </Form.Item>

          <Form.Item className="button_box">
            <Button
              style={{ marginRight: 15 }}
              onClick={() => {
                if (props.onCancel) {
                  props.onCancel();
                }
              }}
            >
              {t('取消')}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {t('确认修改')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
};

export default EditForm;
