import { t } from '@rootcloud/rc-i18n-cli/hooks';
import React, { useState, useEffect } from 'react';
import { Input, message, Table, Skeleton, Modal, Tooltip, Button, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import RCNoDataDisplay from '@/components/RCNoDataDisplay';
import RCTransfer from '@/components/RCTransfer';
import RCSearchInput from '@/components/RCSearchInput';
import RCButtonWithLoading from '@/components/RCButtonWithLoading';
import RCIcon from '@/common/Icon';
import request from '@/utils/request';
import { getBLen } from '@/utils';
import './index.less';

const PermissionPolicy = (props: any) => {
  const navigate = useNavigate();
  const PAGEOBJ = { total: 0, current: 1, pageSize: 10 };
  const [loading, setLoading] = useState(false);
  const [pageObj, setPageObj] = useState(PAGEOBJ);
  const [linkModal, setLinkModal] = useState(false);
  const [inputText, setInputText] = useState('');
  const [tableList, setTableList] = useState([]);

  // 关联策略
  const LEFTPAGEOBJ = { total: 0, current: 1, pageSize: 10 };
  const [leftTableData, setLeftTableData] = useState([]);
  const [rightTableData, setRightTableData] = useState<any[]>([]);
  const [leftPageObj, setLeftPageObj] = useState(LEFTPAGEOBJ);
  const [leftLoading, setLeftLoading] = useState(false);
  const [rightLoading, setRightLoading] = useState(false);
  const [transferSearchText, setTransferSearchText] = useState('');
  const [selectKeys, setSelectKeys] = useState([]);
  const [, setSelectRows] = useState<any[]>([]);
  const [loadingComfirm, setLoadingComfirm] = useState(false);

  // 解除关联
  const [relieveModal, setRelieveModal] = useState(false);
  const [relieveRecord, setRelieveRecord] = useState({} as any);

  // 请求策略列表
  const getPolicyList = () => {
    const params: any = {
      limit: pageObj.pageSize,
      page: pageObj.current,
      roleId: props.roleId,
    };
    if (inputText) {
      params.name = inputText.trim();
    }
    const obj = {
      url: `/base/user-identity-management/api/v1/roles/${props.roleId}/policies`,
      method: 'get',
      params,
    };
    setLoading(true);
    request(obj)
      .then((res: any) => {
        const { results, total } = res;
        const o = { ...pageObj };
        o.total = total;
        setTableList(results);
        setPageObj(o);
        setLoading(false);
      })
      .catch(() => {
        setTableList([]);
        setPageObj(PAGEOBJ);
        setLoading(false);
      });
  };

  useEffect(() => {
    getPolicyList();
  }, [pageObj.current, pageObj.pageSize]);

  // 过滤
  const handleChangeInput = (e: any) => {
    setInputText(e.target.value);
  };

  // 搜索
  const handleSearch = () => {
    if (pageObj.current !== 1 || pageObj.pageSize !== 10) {
      setPageObj(PAGEOBJ);
    } else {
      getPolicyList();
    }
  };

  // 分页
  const changePageSize = (page: any, pageSize: any) => {
    const o = { ...pageObj };
    o.current = 1;
    o.pageSize = pageSize;
    setPageObj(o);
  };

  const changePageCurrent = (page: any, pageSize: any) => {
    const o = { ...pageObj };
    o.current = page;
    o.pageSize = pageSize;
    setPageObj(o);
  };

  // 获取左侧数据
  const getLeftData = () => {
    const params:any = {
      page: leftPageObj.current,
      limit: 8,
      sort: 'createdAt,desc'
    };
    if (transferSearchText) {
      params.name = transferSearchText;
    }
    setLeftLoading(true);
    request({
      url: '/base/user-identity-management/api/v1/policies',
      method: 'get',
      params,
    })
      .then((res: any) => {
        const { results, total } = res;
        const o = { ...leftPageObj };
        o.total = total;
        setLeftTableData(results);
        setLeftPageObj(o);
        setLeftLoading(false);
      })
      .catch(() => {
        setLeftTableData([]);
        setPageObj(LEFTPAGEOBJ);
        setLeftLoading(false);
      });
  };

  useEffect(() => {
    getLeftData();
  }, [leftPageObj.current, transferSearchText]);

  // 获取右侧数据
  const getRightData = () => {
    const params:any = {
      page: pageObj.current,
      limit: 9999,
      roleId: props.roleId
    };
    if (transferSearchText) {
      params.name = transferSearchText;
    }
    setRightLoading(true);
    request({
      url: `/base/user-identity-management/api/v1/roles/${props.roleId}/policies`,
      method: 'get',
      params,
    })
      .then((res: any) => {
        const { results } = res;
        setRightTableData(results);
        setRightLoading(false);
        setSelectRows(results);
        setSelectKeys(results.map((k:any) => k.id));
      })
      .catch(() => {
        setRightTableData([]);
        setRightLoading(false);
      });
  };

  const handleChangeSelect = (keys: React.SetStateAction<never[]>, rows:any) => {
    setSelectRows(rows);
    setSelectKeys(keys);
    setRightTableData(rows);
  };

  const changeLeftPageCurrent = (page: any) => {
    const o = { ...leftPageObj };
    o.current = page;
    setLeftPageObj(o);
  };

  // 关联的策略搜索
  const changeTransferSearchValue = (e:any) => {
    setTransferSearchText(e.target.value);
  };

  const changeTransferSerchText = () => {
    const o = { ...leftPageObj };
    o.current = 1;
    setLeftPageObj(o);
  };

  const handleTransferClearSearch = () => {
    const o = { ...leftPageObj };
    o.current = 1;
    setLeftPageObj(o);
    setTransferSearchText('');
  };

  // 取消关联
  const handleCancel = () => {
    setLinkModal(false);
    setSelectKeys([]);
    setLeftPageObj(LEFTPAGEOBJ);
  };

  // 确认关联
  const handleComfirm = () => {
    const permissions = rightTableData.map(k => k.id);
    const params = { permissions };
    setLoadingComfirm(true);
    request({
      url: `/base/user-identity-management/api/v1/roles/${props.roleId}/policies`,
      method: 'patch',
      body: params,
    })
      .then(() => {
        setLoadingComfirm(false);
        handleCancel();
        getPolicyList();
      })
      .catch(() => {
        setLoadingComfirm(false);
      });
  };

  // 解除关联
  const confirmRelieve = () => {
    setLoadingComfirm(true);
    request({
      url: `/base/user-identity-management/api/v1/roles/${props.roleId}/policies/relieve`,
      method: 'patch',
      body: { permissions: [relieveRecord.id] },
    })
      .then(() => {
        message.success(t('策略已解除'));
        setLoadingComfirm(false);
        setRelieveModal(false);
        getPolicyList();
      })
      .catch(() => {
        setLoadingComfirm(false);
      });
  };

  const columns = [
    {
      title: t('策略名称'),
      dataIndex: 'name',
      key: 'name',
      render: (
        text:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined,
      ) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {text && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('策略类型'),
      dataIndex: 'type',
      key: 'type',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { id: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <span>{text === '0' ? t('预设策略') : t('自定义策略')}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('描述'),
      dataIndex: 'describe',
      key: 'describe',
      render: (text: string, record: { id: any; }) => (
        <Skeleton active loading={loading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <Tooltip title={text}><span>{getBLen(text, 20)}</span></Tooltip>}
        </Skeleton>
      ),
    },
    {
      title: t('操作'),
      dataIndex: 'actions',
      key: 'actions',
      width: 160,
      render: (text: any, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record.id &&
            <span>
              <a onClick={() => { navigate(`/user/permission/policy-management/${record.id}`); }}>{ t('查看') }</a>
              {props.type !== 'SYSTEM' && <a className="relieve" onClick={() => { setRelieveModal(true); setRelieveRecord(record); }}>{ t('解除关联') }</a>}
            </span>
          }
        </Skeleton>
      ),
    },
  ];

  // 关联策略左边栏
  const leftColumns = [
    {
      title: t('策略名称'),
      dataIndex: 'name',
      key: 'name',
      width: '50%',
      render: (
        text:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined,
      ) => (
        <Skeleton
          active
          loading={leftLoading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {text && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('策略类型'),
      dataIndex: 'type',
      key: 'type',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { id: any; }) => (
        <Skeleton active loading={leftLoading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <span>{text === '0' ? t('预设策略') : t('自定义策略')}</span>}
        </Skeleton>
      ),
    },
  ];

  // 关联策略右边栏
  const rightColumns = [
    {
      title: t('策略名称'),
      dataIndex: 'name',
      key: 'name',
      width: '50%',
      render: (
        text:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined,
      ) => (
        <Skeleton
          active
          loading={rightLoading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {text && <span>{text}</span>}
        </Skeleton>
      ),
    },
    {
      title: t('策略类型'),
      dataIndex: 'type',
      key: 'type',
      render: (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { id: any; }) => (
        <Skeleton active loading={rightLoading} paragraph={{ rows: 1 }} title={false}>
          {record.id && <span>{text === '0' ? t('预设策略') : t('自定义策略')}</span>}
        </Skeleton>
      ),
    },
  ];

  const rowSelection = {
    onChange: handleChangeSelect,
    selectedRowKeys: selectKeys,
  };

  return (
    <div>
      <div className="policy-input">
        <Input
          placeholder={t('请输入策略名称')}
          allowClear
          onChange={handleChangeInput}
          suffix={
            <RCIcon
              type="icon-search"
              style={{ cursor: 'pointer' }}
              onClick={handleSearch}
            />
          }
          onPressEnter={handleSearch}
          style={{ width: 319 }}
        />
        <Button type="primary" onClick={() => { setLinkModal(true); getLeftData(); getRightData(); }}>{ t('关联策略') }</Button>
      </div>
      <Table
        rowKey={record => record.id}
        columns={columns}
        locale={{ emptyText: <RCNoDataDisplay /> }}
        dataSource={tableList}
        pagination={
          pageObj.total > 10
            ? {
                showQuickJumper: true,
                showSizeChanger: true,
                total: pageObj.total,
                current: pageObj.current,
                pageSize: pageObj.pageSize,
                onShowSizeChange: changePageSize,
                onChange: changePageCurrent,
              }
            : false
        }
      />
      <Modal
        visible={linkModal}
        maskClosable={false}
        width={1078}
        title={t('关联策略')}
        closeIcon={<RCIcon type="icon-cancel" />}
        footer={null}
        style={{ textAlign: 'left' }}
        onCancel={() => { setLinkModal(false); }}
      >
        <RCTransfer
          search={
            <RCSearchInput
              placeholder={t('请输入策略名称')}
              autoComplete="off"
              onChange={changeTransferSearchValue}
              onPressEnter={changeTransferSerchText}
              handleSearch={changeTransferSerchText}
              handleClearSearch={handleTransferClearSearch}
              noSearch={false}
              style={{ marginBottom: 12 }}
              value={transferSearchText}
            />
          }
          leftTableData={leftTableData}
          leftTableColumns={leftColumns}
          leftTotal={leftPageObj.total}
          leftPage={leftPageObj.pageSize}
          changeLeftPageCurrent={changeLeftPageCurrent}
          rightTableData={rightTableData}
          rightTableColumns={rightColumns}
          selectedRowKeys={selectKeys}
          rowSelection={rowSelection}
          loading={loadingComfirm}
          handleComfirm={handleComfirm}
          handleCancel={handleCancel}
        />
      </Modal>
      <Modal
        visible={relieveModal}
        maskClosable={false}
        width={428}
        closeIcon={<RCIcon type="icon-cancel" />}
        className="rc-modal"
        onCancel={() => { setRelieveModal(false); }}
        footer={null}
      >
        <Row>
          <Col span={24} className="rc-modal-icon"><RCIcon type="icon-Mainwarningsfilled" style={{ color: '#e60000' }} /></Col>
          <Col span={24} className="rc-modal-title">
            <span>{ t('您确定要解除“{{name}}”策略的关联吗？', { ...relieveRecord }) }</span>
          </Col>
          <Col span={24} className="rc-modal-tip"><span>{ t('解除后，当前角色将丢失该策略的全部权限') }</span></Col>
          <Col span={24} className="rc-modal-btn">
            <Button type="default" style={{ marginRight: 16 }} onClick={() => { setRelieveModal(false); }}>{ t('取消') }</Button>
            <RCButtonWithLoading danger type="default" onClick={confirmRelieve} loading={loadingComfirm}>{ t('解除') }</RCButtonWithLoading>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default PermissionPolicy;
