import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Layout from '@/layouts';
import { useEffect } from 'react';
// import { Result, Button } from 'antd';
import { setAppInfo } from '@/utils';

import Auth from '@/components/Auth/Auth';

import MemberManagement from '@/pages/permission/member-management';
import MemberDetails from '@/pages/permission/member-management/details';
import AuthorityManagement from '@/pages/permission/authority-management';
import AuthorityManagementDetails from '@/pages/permission/authority-management/details';
import PolicyManagement from '@/pages/permission/policy-management';
import PolicyManagementDetails from '@/pages/permission/policy-management/details';
import CreatePolicys from '@/pages/permission/policy-management/create';
import LoginPage from '@/pages/login';

import EventCenterPage from '@/pages/event-center';

import MessageCenterPage from './pages/message-center';

import OrgsManagementPage from './pages/orgs-management';
import OrgDetailPage from './pages/orgs-management/detail';

import ResourceManagementPage from './pages/resource-management';
import ResourceDetailPage from './pages/resource-management/details';

import MenusManagementPage from './pages/menus-management';
import MenusDetailsPage from './pages/menus-management/details';
import CreateMenuPage from './pages/menus-management/create';

import './assets/App.less';
import './assets/styles/layout.less';
import './assets/styles/form.less';
import './assets/styles/modal.less';

function App(props:any) {
  useEffect(() => {
    const onMessage = (event: any) => {
      if (event.origin !== window.location.origin) {
        // eslint-disable-next-line no-console
        console.log('🚀 ~ 接受到消息 ~ event', event);
        let data: {
          appType: string;
          tenantId: string;
        } = event.data;
        try {
          data = JSON.parse(event.data);
        } catch (error) {
          data = event.data;
        }
        if (typeof data !== 'string') {
          setAppInfo(data);
        }
      }
    };
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);
  // 路由一般情况下不要调整
  return (
    <div className="app-container">
      <BrowserRouter basename="/common-page">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<Auth />}>
            <Route path="layout" element={<Layout menu={props.menu} userInfo={props.userInfo} />}>
              <Route path="user" >
                <Route path="permission/member-management" element={<MemberManagement />} />
                <Route path="permission/member-management/:id" element={<MemberDetails />} />
                <Route path="permission/authority-management" element={<AuthorityManagement />} />
                <Route path="permission/authority-management/:id" element={<AuthorityManagementDetails />} />
                <Route path="permission/policy-management" element={<PolicyManagement />} />
                <Route path="permission/policy-management/create" element={<CreatePolicys />} />
                <Route path="permission/policy-management/:id" element={<PolicyManagementDetails />} />
              </Route>
              
              <Route path="event-center/list" element={<EventCenterPage />} />
              <Route path="message-center/list" element={<MessageCenterPage />} />
              <Route path="orgs-management">
                <Route path="list" element={<OrgsManagementPage />} />
                <Route path="detail/:orgId" element={<OrgDetailPage />} />
              </Route>
              <Route path="resource-management">
                <Route path="list" element={<ResourceManagementPage />} />
                <Route path="detail/:resourceId" element={<ResourceDetailPage />} />
              </Route>
              <Route path="menus-management" >
                <Route path="list" element={<MenusManagementPage />} />
                <Route path="detail/:menuId" element={<MenusDetailsPage />} />
                <Route path="create" element={<CreateMenuPage />} />
              </Route>
            </Route>
            <Route path="user" >
              <Route path="permission/member-management" element={<MemberManagement />} />
              <Route path="permission/member-management/:id" element={<MemberDetails />} />
              <Route path="permission/authority-management" element={<AuthorityManagement />} />
              <Route path="permission/authority-management/:id" element={<AuthorityManagementDetails />} />
              <Route path="permission/policy-management" element={<PolicyManagement />} />
              <Route path="permission/policy-management/create" element={<CreatePolicys />} />
              <Route path="permission/policy-management/:id" element={<PolicyManagementDetails />} />
            </Route>
            <Route path="event-center/list" element={<EventCenterPage />} />
            <Route path="message-center/list" element={<MessageCenterPage />} />
            <Route path="orgs-management">
              <Route path="list" element={<OrgsManagementPage />} />
              <Route path="detail/:orgId" element={<OrgDetailPage />} />
            </Route>
            <Route path="resource-management" >
              <Route path="list" element={<ResourceManagementPage />} />
              <Route path="detail/:resourceId" element={<ResourceDetailPage />} />
            </Route>
            <Route path="menus-management" >
              <Route path="list" element={<MenusManagementPage />} />
              <Route path="detail/:menuId" element={<MenusDetailsPage />} />
              <Route path="create" element={<CreateMenuPage />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
