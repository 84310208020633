/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-12-08 17:29:54
 * @LastEditors: songone
 * @LastEditTime: 2022-12-08 20:47:34
 * @FilePath: \tide-user-center-web\src\api\message.ts
 */
import request from '@/utils/request';
import React from 'react';

export const getMessageList = (params: {
  sort: string;
  withTotal: boolean;
  page: number;
  limit: number;
  content: string;
}) => request({
  url: '/base/message-center/api/v1/messages',
  method: 'get',
  params
});
export const getMessageDetail = (id: string) => request({
  url: `/base/message-center/api/v1/messages/${id}`,
  method: 'get',
});

export const updateMessageRead = (ids: React.Key[]) => request({
  url: '/base/message-center/api/v1/messages',
  method: 'put',
  data: { ids }
});

export const deleteMessage = (ids: React.Key[]) => request({
  url: '/base/message-center/api/v1/messages',
  method: 'delete',
  data: { ids }
});

