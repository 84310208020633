export const KEY_STORAGE_SESSION_DATA = 'SESSION_DATA';
export const AUTH_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';

export const PHOHE_RULE = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
export const EMAIL_RULE = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
export const PASSWORD_RULE = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,}$/;

// 接口不需要传Bearer白名单
export const WHITELIST = ['/login', '/public-key'];
