/*
 * @Descripttion:
 * @version:
 * @Author: songone
 * @Date: 2022-11-01 17:58:18
 * @LastEditors: songone
 * @LastEditTime: 2022-11-22 17:12:14
 * @FilePath: \tide-user-center-web\src\components\RCTimePicker\config.js
 */
import { t } from '@rootcloud/rc-i18n-cli/hooks';

const menuList = [
  {
    chinese: t('快捷选择'),
    key: 'quickly',
    children: [
      {
        chinese: t('过去5分钟'),
        key: 'l5m',
        english: 'Last 5 minutes'
      },
      {
        chinese: t('过去1小时'),
        key: 'l1h',
        english: 'Last 1 hour'
      },
      {
        chinese: t('过去24小时'),
        key: 'l24h',
        english: 'Last 24 hours'
      },
      {
        chinese: t('过去7天'),
        key: 'l7d',
        english: 'Last 7 days'
      },
      {
        chinese: t('过去30天'),
        key: 'l30d',
        english: 'Last 30 days'
      },
      {
        chinese: t('今日'),
        key: 'td',
        english: 'Today'
      },
      {
        chinese: t('本周'),
        key: 'tw',
        english: 'This week'
      },
      {
        chinese: t('本月'),
        key: 'tm',
        english: 'This month'
      }
    ]
  },
  {
    chinese: t('自定义动态时间'),
    key: 'dynamic',
    children: [
      {
        chinese: t('分钟'),
        english: 'min',
        key: 'min'
      },
      {
        chinese: t('小时'),
        english: 'hr',
        key: 'hour'
      },
      {
        chinese: t('天'),
        english: 'day',
        key: 'day'
      },
      {
        chinese: t('周'),
        english: 'week',
        key: 'week'
      },
      {
        chinese: t('月'),
        english: 'month',
        key: 'month'
      }
    ]
  },
  {
    chinese: t('自定义静态时间'),
    key: 'static',
    children: []
  }
];

export { menuList };
